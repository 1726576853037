import { Box, Typography, Button, Container, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  generateDefaultCreateDevicePayload,
  getDeviceModelFromString,
  getDeviceTypeIntFromSearch,
  getStationName
} from 'shared/utils/helperFunctions';
import { SearchDevice } from 'features/RemoteManagement/Types';
import { GridValidRowModel } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  useBatchUpdateDevicesMutation,
  useCreateDeviceMutation,
  useCreateUnitsWizardMutation,
  useGetDeviceListWithSitePublicIdQuery,
  useLazyGetUnitListWithSitePublicIdQuery
} from 'services/aiphoneCloud';
import useConfigureWizardCommUnits from 'features/RemoteManagement/Hooks/useConfigureWizardCommUnits';
import StationSearchDataGrid from './components/datagrids/StationSearchDataGrid';
import ExistingDevices from './ExistingDevices';
import 'styles/frontshine.css';
import Spinner from 'features/SimBilling/Components/UiParts/Spinner';
import { useTranslation } from 'react-i18next';
import AddDeviceManuallyDataGrid from 'features/RemoteManagement/NewSiteWizard/steps/AddDevices/components/datagrids/AddDeviceManuallyDataGrid';

interface IAddDevicesProps {
  isRegistered: boolean;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

interface ICreateDevicesResponse {
  macAddress: string;
  publicId: string;
}

export interface IDevicesToAdd {
  publicId?: string;
  deviceType: number;
  deviceModel: number | null;
  macAddress: any;
  stationNumber: string;
  stationName: string;
  firmwareVersion: any;
  advancedSettings: {
    networkSettings: {
      ipV4Address: string;
      subnetMask: string;
      ipV4DefaultGateway?: string;
    };
  };
  buildingPublicId?: string;
}

interface IBasicInfo {
  devicePublicId: string | null;
  stationNumber: string;
  stationName: string;
  firmwareVersion?: string;
  adminId?: string;
  adminPass?: string;
}

function AddDevices({ isRegistered, handlePreviousStep, handleNextStep }: IAddDevicesProps) {
  const [isAddingManually, setIsAddingManually] = useState(false);
  const [restrictedMacAddresses, setRestrictedMacAddresses] = useState<string[]>(['00:00:00:00:00:00']);
  const [searchSelectedDevices, setSearchSelectedDevices] = useState<SearchDevice[]>([]);
  const [manualSelectedDevices, setManualSelectedDevices] = useState<GridValidRowModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetchDevices, setShouldFetchDevices] = useState(false);
  const site = useSelector((state: RootState) => state.site);
  const devices = useSelector((state: RootState) => state.devices);
  const buildingPublicId = useSelector((state: RootState) => Object.keys(state.buildings.BuildingList)[0]);
  const { generateUnits } = useConfigureWizardCommUnits();
  const [createUnits] = useCreateUnitsWizardMutation();
  const [createDevice] = useCreateDeviceMutation();
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { refetch } = useGetDeviceListWithSitePublicIdQuery({
    qty: '-1',
    page: 0,
    sitePublicId: site.siteInfo.publicId
  });

  const { t } = useTranslation();

  useEffect(() => {
    // get a map of all devices already added to the site
    const deviceList = devices.DeviceList;
    // Remove duplicates from the list of devices
    // We need to fix this duplication though
    const restrictedMacAddressList = [
      ...new Set(Object.values(deviceList).map((device) => device.basicInfo.macAddress))
    ];
    setRestrictedMacAddresses(restrictedMacAddressList);
  }, [devices.DeviceList]);

  useEffect(() => {
    if (shouldFetchDevices) {
      refetch();
      // handleNextStep();
    }
  }, [shouldFetchDevices, refetch]);

  function updateManualDeviceFields(rowModel: GridValidRowModel) {
    switch (rowModel.model_number) {
      case 'IXG-DM7-HID(A)':
        rowModel.model_number = 'IXG-DM7-HID';
        break;
      case 'IX-DVM':
        rowModel.device_type = 'IX-DVM';
        break;
      case 'IX-EA':
        rowModel.device_type = 'IX-EA';
        break;
      case 'IX-SS-2G':
      case 'IX-NVP':
        rowModel.device_type = 'IX-SS-2G';
        break;
    }
  }

  const addDevicesToSite = async () => {
    const devicesToAdd: IDevicesToAdd[] = [...searchSelectedDevices, ...manualSelectedDevices]
      .map((device) => {
        updateManualDeviceFields(device);
        const deviceType = getDeviceTypeIntFromSearch(device.device_type);
        const deviceModel = getDeviceModelFromString(device.model_number);
        const deviceMacAddress = device.mac_addr;
        const deviceStationNumber = device.station_number || '';
        const deviceStationName = getStationName(device.device_type, device.station_name);
        const deviceFirmwareVersion = device.fw_ver || '';
        const deviceIPAddress = device.ip_addr || '';
        const deviceSubnetMask = device.ip_subnet || '';
        const deviceGateway = device.ip_gateway || '';

        if (!deviceType) {
          return null;
        }

        const devicePayload: IDevicesToAdd = {
          deviceType: deviceType,
          deviceModel: deviceModel,
          macAddress: deviceMacAddress,
          stationNumber: deviceStationNumber,
          stationName: deviceStationName,
          firmwareVersion: deviceFirmwareVersion,
          advancedSettings: {
            networkSettings: {
              ipV4Address: deviceIPAddress || '',
              subnetMask: deviceSubnetMask || '',
              ipV4DefaultGateway: deviceGateway !== deviceIPAddress ? deviceGateway : undefined
            }
          }
        };

        return devicePayload;
      })
      .filter((device): device is IDevicesToAdd => device !== null);

    const devicesPayload = generateDefaultCreateDevicePayload(devicesToAdd, buildingPublicId);

    const params = {
      sitePublicId: site.siteInfo.publicId,
      devices: devicesPayload
    };

    try {
      setIsLoading(true);

      // Create the newly added devices in the DB
      const response = (await createDevice(params)) as { data: ICreateDevicesResponse[] };

      //If the site is a commercial system then create the units for the devices that were created
      if (site.siteInfo.typeId === 1) {
        //Generate the units for the devices that were created
        const units = generateUnits(devicesToAdd, response?.data);
        await createUnits(units);

        //Update the station number and station name for the devices that were created
        const devicesToUpdate = units.newUnitsArray.flatMap((unit) =>
          unit.devicePublicIds.map((publicId, index) => {
            const basicInfo: IBasicInfo = {
              devicePublicId: publicId,
              stationNumber: `01${unit.unitNumber}${index + 1}`,
              stationName: `Station 01${unit.unitNumber}${index + 1}`
            };

            if (site.siteInfo.systemId) {
              basicInfo.adminId = site.siteInfo.systemId;
              basicInfo.adminPass = site.siteInfo.systemPassword;
            }

            return {
              sitePublicId: site.siteInfo.publicId,
              publicId,
              basicInfo
            };
          })
        );
        await batchUpdateDevices({ devices: devicesToUpdate });

        //Update Redux store with the newly created units by fetching
        await fetchUnits({
          sitePublicId: site.siteInfo.publicId,
          qty: '-1',
          page: 0
        });
      }

      //Get the updated device list from the database
      setShouldFetchDevices(true);

      setIsLoading(false);
    } catch (error) {
      setErrorMessage(`Error: ${error}`);
      setIsLoading(false);
    }
  };

  const renderAddDeviceEntry = () => {
    return (
      <Box sx={styles.centerContent}>
        <Typography variant="h4" sx={styles.title}>
          {!Object.entries(devices.DeviceList).some(([, device]) => {
            return device.basicInfo.deviceType !== 18;
          })
            ? isAddingManually || !isRegistered
              ? t('Manual_Device_Entry')
              : t('Gateway_Station_Search')
            : t('Devices')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {!Object.entries(devices.DeviceList).some(([, device]) => {
            return device.basicInfo.deviceType !== 18;
          })
            ? isAddingManually || !isRegistered
              ? t('Manual_Device_Entry_explanation')
              : t('Gateway_Station_Search_explanation')
            : t('Existing_Devices_explanation')}
        </Typography>
      </Box>
    );
  };

  const renderAddDeviceManuallyDataGrid = () => {
    return (
      <AddDeviceManuallyDataGrid
        manualSelectedDevices={manualSelectedDevices}
        setManualSelectedDevices={(devices: GridValidRowModel[]) => setManualSelectedDevices(devices)}
        restrictedMacAddresses={restrictedMacAddresses}
      />
    );
  };

  const renderStationSearchDataGrid = () => {
    return (
      <StationSearchDataGrid
        searchSelectedDevices={searchSelectedDevices}
        setSearchSelectedDevices={(devices: SearchDevice[]) => setSearchSelectedDevices(devices)}
        setIsAddingManually={setIsAddingManually}
        restrictedMacAddresses={restrictedMacAddresses}
        setRestrictedMacAddresses={setRestrictedMacAddresses}
        selectedUnitType={site.siteInfo.typeId}
      />
    );
  };

  const renderDualButtonContainer = () => {
    // If using the gateway search, confirm that the gateway is added to the site
    const isGatewayAddedToSite = Object.keys(devices.DeviceList).find(
      (deviceID) => deviceID === site.siteInfo.registeredGatewayPublicId
    );
    // check devices are added in addition to the gateway
    const deviceCount = Object.keys(devices.DeviceList).filter(
      (deviceID) => deviceID !== site.siteInfo.registeredGatewayPublicId
    );

    const enableAddDevicesButton =
      (Object.keys(devices.DeviceList).length === 0 || isGatewayAddedToSite) && deviceCount.length === 0;

    return enableAddDevicesButton ? (
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {t('Button_Back')}
        </Button>
        <Tooltip title={!isRegistered ? 'A registered IXGW-GW is required to use the station search feature' : null}>
          <span>
            <Button
              color="primary"
              onClick={() => {
                setIsAddingManually(!isAddingManually);
              }}
              disabled={!isRegistered}
            >
              {isAddingManually || !isRegistered ? t('Add_Devices_with_Station_Search') : t('Add_Devices_Manually')}
            </Button>
          </span>
        </Tooltip>

        {errorMessage && <p className={'mx-4 text-red text-center my-auto'}>{errorMessage}</p>}

        <Tooltip
          title={
            searchSelectedDevices.length === 0 && manualSelectedDevices.length === 0
              ? 'A device must be added to the system to continue'
              : ''
          }
        >
          <span>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={(searchSelectedDevices.length === 0 && manualSelectedDevices.length === 0) || isLoading}
              onClick={addDevicesToSite}
            >
              {t('Continue')}
            </Button>
          </span>
        </Tooltip>
      </Box>
    ) : (
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep}>
          {t('Button_Back')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextStep}>
          {t('Continue')}
        </Button>
      </Box>
    );
  };

  return !Object.entries(devices.DeviceList).some(([, device]) => {
    return device.basicInfo.deviceType !== 18;
  }) ? (
    <Container maxWidth="lg">
      <Box>
        {renderAddDeviceEntry()}
        {isAddingManually || !isRegistered ? renderAddDeviceManuallyDataGrid() : renderStationSearchDataGrid()}
        {renderDualButtonContainer()}
        {isLoading && <Spinner />}
      </Box>
    </Container>
  ) : (
    <Container maxWidth="lg">
      <Box>
        {renderAddDeviceEntry()}
        <ExistingDevices />
        {renderDualButtonContainer()}
      </Box>
    </Container>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginTop: '1rem'
  },
  backdrop: {
    color: '#fff',
    zIndex: 'theme.zIndex.drawer'
  },
  title: {
    mb: 3,
    color: 'primary.main',
    fontWeight: 'bold'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 2
  },
  w100: {
    width: '100%'
  }
};

export default AddDevices;
