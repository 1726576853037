import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

const usePortValidationSchema = (fieldName: string): Yup.NumberSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const fieldErrorMin = t('Field_Error_Min');
  const fieldErrorMax = t('Field_Error_Max');
  const fieldErrorRequired = t('Field_Error_Required');
  const formattedFieldRequired: string = StringUtils.format(fieldErrorRequired, fieldName);
  const portMin = 1;
  const portMax = 65535;

  return Yup.number()
    .required(formattedFieldRequired)
    .min(portMin, StringUtils.format(fieldErrorMin, fieldName, portMin))
    .max(portMax, StringUtils.format(fieldErrorMax, fieldName, portMax));
};

export default usePortValidationSchema;
