import { QuikspecCategory } from 'features/QuikSpec/Types/QuikSpecTypes';

export const MasterStation: QuikspecCategory = {
  id: 1,
  title: 'Master Stations',
  description:
    'Master stations are where incoming calls are received, door release can be activated, other master stations can be called, and pages can be initiated. In typical applications, at least one master station is used to communicate to door and sub stations, and multiple master stations can be used for internal communication, system partitioning, and call transfers.',
  items: [
    { deviceName: 'IX-MV7-B' },
    { deviceName: 'IX-MV7-W' },
    { deviceName: 'IX-MV7-HB' },
    { deviceName: 'IX-MV7-HB-L' },
    { deviceName: 'IX-MV7-HW-JP' }
  ],
  itemsPerPage: 5
};

export const SoftwareMasterStation: QuikspecCategory = {
  id: 2,
  title: 'Software Master Stations',
  description: '',
  items: [
    {
      deviceName: 'IX-SOFT',
      licenseDescription: '1 License'
    },
    {
      deviceName: 'IXW-MA-SOFT',
      licenseDescription: '1 License'
    },
    {
      deviceName: 'IXW-MA-SOFT-5',
      licenseDescription: '5 Licenses'
    },
    {
      deviceName: 'IXW-MA-SOFT-10',
      licenseDescription: '10 Licenses'
    }
  ]
};

export const SubMasterStation: QuikspecCategory = {
  id: 3,
  title: 'Sub Master Stations',
  description:
    'Sub master stations have similar basic features when compared to a master station, such as 2-way communication and the ability to activate door release, but is limited in other advanced features. When choosing the addition of a sub master station, ensure the station is appropriate for the application.',
  items: [{ deviceName: 'IX-RS-B' }, { deviceName: 'IX-RS-W' }]
};

export const ixMasterStations: QuikspecCategory[] = [MasterStation, SoftwareMasterStation, SubMasterStation];

export const ixDoorStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Audio/Video Door Stations',
    description:
      'Audio/Video Door Stations provide two-way audio communication, video, and door release relay outputs.',
    items: [
      {
        deviceName: 'IX-DV',
        accessories: [
          { accessoryName: 'Surface Mount' },
          { accessoryName: '30° Angled Mount', deviceName: 'SBX-IXDV30' },
          { accessoryName: 'Mullion Mount', deviceName: 'IX-MB' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-DVM',
        accessories: [{ accessoryName: 'Mullion Mount' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-EA',
        accessories: [{ accessoryName: 'Surface Mount' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-DVF',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVF' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-DVF-PR',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVFRA' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-DVF-10KP',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IXGDM7' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-DVF-4A',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IXGDM7' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-DVF-6',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IXGDM7' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-DVF-HW'
      },
      {
        deviceName: 'IX-DVF-L',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVFRA' }],
        countWithAccessories: true
      }
    ],
    itemsPerPage: 5
  },
  {
    id: 2,
    title: 'Audio Only Door Stations',
    description: 'Audio Only Door Stations provide two-way audio communication and door release relay outputs.',
    items: [
      {
        deviceName: 'IX-SS-2G',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-2G/A' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-SSA',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVF' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-NVP',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-NVP/A' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-NVP2',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-NVP/A' }],
        countWithAccessories: true
      }
    ]
  }
];

export const ixEmergencyStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Stand Alone Emergency Stations',
    description:
      'ADA compliant rescue assistance intercom stations featuring enhanced call indicators, large “Emergency” call buttons, Braille signage, and two Form C outputs for triggering external devices.',
    items: [
      {
        deviceName: 'IX-DVF-RA',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVFRA' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-DVF-2RA',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVFRA' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-SSA-RA',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVFRA' }],
        countWithAccessories: true
      },
      {
        deviceName: 'IX-SSA-2RA',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount', deviceName: 'SBX-IDVFRA' }],
        countWithAccessories: true
      }
    ]
  },
  {
    id: 2,
    title: 'Emergency Wall Boxes',
    description:
      'Each wall box has a cutout to install any IX Emergency Station. The blue light can be powered by a 24V DC Power Supply (PS-2420UL) or the PS-POE if station is connected to a PoE+ switch.',
    items: [
      {
        deviceName: 'WB-CE',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'WB-CA',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'WB-HE',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'WB-HA',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      }
    ]
  },
  {
    id: 3,
    title: 'Emergency Towers',
    description:
      'All-in-one towers offer two different options for lettering "Emergency" and "Assistance". These aluminum towers for housing the IX Series emergency stations are designed to offer a light durable tower used in areas such as campuses, parking decks, malls, or courtyards.',
    items: [
      {
        deviceName: 'TW-SE',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'TW-SA',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'TW-TE',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'TW-TA',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'TW-TE-ARM',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      },
      {
        deviceName: 'TW-TA-ARM',
        accessories: [
          { accessoryName: 'PS-2420UL for Strobe', deviceName: 'PS-2420UL' },
          { accessoryName: 'PS-POE for Strobe', deviceName: 'PS-POE' }
        ],
        countWithAccessories: true
      }
    ]
  }
];

export const ixAdaptors: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Network Adaptors',
    description:
      'All network adaptors listed below can be added to your system design without needing an IX Series Door or Sub Master Station.',
    items: [
      { deviceName: 'IXW-MA' },
      { deviceName: 'IXW-MAA' },
      { deviceName: 'IPW-1VC' },
      { deviceName: 'IPW-10VC' },
      { deviceName: 'IPW-POE8' },
      { deviceName: 'PS-POE' },
      { deviceName: 'IXW-PBXA' },
      { deviceName: 'IXW-HWCBP', discontinued: true }
    ]
  },
  {
    id: 2,
    title: 'Speakers and Amplifiers',
    description:
      'Aiphone offers amplifiers and speakers that can be used with IX series stations for paging capabilities.',
    items: [{ deviceName: 'MH-15W' }, { deviceName: 'MD35M' }, { deviceName: 'MD60M' }, { deviceName: 'MD120M' }]
  }
];

// Hidden products are designed not to be displayed.
export const ixHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      {
        deviceName: 'SBX-IXDV30'
      },
      {
        deviceName: 'IX-MB'
      },
      {
        deviceName: 'SBX-IDVF'
      },
      {
        deviceName: 'SBX-IDVFRA'
      },
      {
        deviceName: 'SBX-IXGDM7'
      },
      {
        deviceName: 'SBX-2G/A'
      },
      {
        deviceName: 'SBX-NVP/A'
      },
      {
        deviceName: 'TW-MKL'
      },
      {
        deviceName: 'PS-2420UL'
      }
    ]
  }
];
