import React, { FC } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDevice, IDeviceDeviceSettings, setDeviceDeviceSettings } from 'store/slices/devicesSlice';
import { Form, Formik } from 'formik';
import { Box } from '@mui/material';
import { getString } from 'shared/utils/LocalizationUtils';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';

import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';

import { Header } from './Components/volume/VolumeDeviceHeader';
import { VolumeSoundSettings } from './Components/volume/VolumeSoundSettings';
import { LoadingButton } from '@mui/lab';

export const VolumeLabel = () => {
  return <span>{getString('AdvancedSettings_Tab_Volume')}</span>;
};

interface CustomError {
  data: string;
}

interface IDeviceDeviceSettings {
  volumeIncomingHandset?: number;
  volumeExternalMicrophone?: number;
  volumeExternalSpeaker?: number;
  volumeIncomingSpeaker?: number;
  volumeNotification?: number;
  volumeRingtone?: number;
  volumeOperation?: boolean;
  volumeOutgoingHandset?: number;
  volumeOutgoingSpeaker?: number;
  volumeSpeaker?: number;
  volumeMonitoringMicrophone?: number;
  volumeIncomingPage?: number;
  stationNumber?: string; // Add this line
}
export interface IVolumeProps {
  defaultValue: number | boolean | undefined;
  isDevice2C7?: boolean;
}

const Volume: FC = () => {
  /*Services*/
  const { t } = useTranslation();
  const buttonReset = t('Button_Reset');
  const buttonSaveChanges = t('Button_SaveChanges');
  const site = useSelector((state: RootState) => state.site); // Assuming site is part of the Redux state
  const devices = useSelector((state: RootState) => state.devices); // Assuming devices is part of the Redux state

  const validationSchema = yup.object().shape({
    volumeIncomingHandset: yup.number(),
    volumeExternalMicrophone: yup.number(),
    volumeExternalSpeaker: yup.number(),
    volumeIncomingSpeaker: yup.number(),
    volumeNotification: yup.number(),
    volumeRingtone: yup.number(),
    volumeOperation: yup.boolean(),
    volumeOutgoingHandset: yup.number(),
    volumeOutgoingSpeaker: yup.number(),
    volumeSpeaker: yup.number(),
    volumeMonitoringMicrophone: yup.number(),
    volumeIncomingPage: yup.number()
  });

  /*Local State*/
  const dispatch = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);

  /*Global State*/
  const [updateDevice] = useUpdateDeviceMutation();
  const { publicId, unitPublicId, deviceSettings } = useSelector(getSelectedDevice);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const selectedDevice = deviceList[publicId];

  /*Variables*/
  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');

  if (!deviceSettings) return null;

  <VolumeSoundSettings device={selectedDevice} />;
  /* Helpers */
  const isCustomError = (error: unknown): error is CustomError => {
    return typeof error === 'object' && error !== null && 'data' in error;
  };

  /* Handlers */
  const onSubmit = async (values: IDeviceDeviceSettings) => {
    const params = {
      device: {
        deviceSettings: values,
        publicId,
        unitPublicId
      }
    };

    try {
      await updateDevice(params).unwrap();
      setSuccessMessage(successUpdateDevice);
      setIsSuccess(true);
      dispatch(setDeviceDeviceSettings(params));
    } catch (error: unknown) {
      if (isCustomError(error)) {
        try {
          const err = JSON.parse(error.data);

          if (err.errorDetails && err.errorDetails.includes('Unauthorized user Id')) {
            setErrorMessage(unauthorizedUser);
          } else {
            setErrorMessage(errorUpdateDevice);
          }
          setIsError(true);
        } catch (parseError) {
          setErrorMessage('Failed to parse error response.');
          setIsError(true);
        }
      } else {
        setErrorMessage('An unexpected error occurred.');
        setIsError(true);
      }
    }
  };

  return (
    <>
      <SnackbarAlert
        type="success"
        text={successMessage}
        time={3000}
        isOpen={isSuccess}
        onClose={() => setIsSuccess(false)}
      />
      <SnackbarAlert type="error" text={errorMessage} time={3000} isOpen={isError} onClose={() => setIsError(false)} />
      <Formik initialValues={deviceSettings} onSubmit={onSubmit} validationSchema={validationSchema} enableReinitialize>
        {({ dirty, isSubmitting }) => {
          return (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.controlPanelWrapper}>
                <LoadingButton variant="outlined" type="reset" disabled={!dirty || isSubmitting}>
                  {buttonReset}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="submit"
                  disabled={!dirty || isSubmitting}
                >
                  {buttonSaveChanges}
                </LoadingButton>
              </Box>

              <div style={containerStyle.gridContainer}>
                <Header
                  siteName={site.siteInfo.siteName ?? ''}
                  awsPropertyId={Number(site.siteInfo.awsPropertyId)}
                  selectedDevice={devices.SelectedDevice ?? ''}
                />
                <VolumeSoundSettings device={selectedDevice} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default Volume;
