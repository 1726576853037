/**
 * Auto-formatting email field
 */

import React from 'react';
import { FieldProps } from 'formik';
import EmailIcon from '@mui/icons-material/Email';
import { TextField, InputAdornment } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface EmailFieldProps extends FieldProps {
  label?: string;
}

const EmailField: React.FC<EmailFieldProps> = ({ field, form, label, ...props }) => {
  const { t } = useTranslation();
  // Pretty format the email
  const handleBlur = async () => {
    const trimmedValue = field.value.trim();
    const formattedValue = trimmedValue.toLowerCase();
    await form.setFieldValue(field.name, formattedValue);
  };

  return (
    <TextField
      {...field}
      {...props}
      type="email"
      onBlur={async (event) => {
        await handleBlur();
        field.onBlur(event);
      }}
      label={label && form.touched[field.name] ? label : t('Email')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailIcon />
          </InputAdornment>
        )
      }}
      error={Boolean(form.touched[field.name] && form.errors[field.name])}
      helperText={
        form.touched[field.name] && form.errors[field.name] // Conditional rendering of helperText
          ? String(form.errors[field.name]) // Make sure the error is passed as a string
          : undefined // Ensure no content if there's no error
      }
      value={field.value}
      fullWidth
    />
  );
};

export default EmailField;
