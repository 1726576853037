import React from 'react';
import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import AddTenantForm from './AddTenantForm';
import { useCreateTenantMutation, useLazyGetTenantListWithSiteIdQuery } from 'services/aiphoneCloud';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { NANP_PHONE_REGEX, VALID_STATION_NAME_REGEX } from 'shared/constants/regex';
import { convertPhoneNumberToE164Format } from 'shared/utils/helperFunctions';
import StringUtils from 'shared/utils/StringUtils';

interface IAddTenantDialogProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  unitPublicId?: string;
}

const AddTenantDialog = ({ isOpen, setIsOpen, unitPublicId }: IAddTenantDialogProps) => {
  const { t } = useTranslation();
  const unit = t('Unit');
  const unitNumber = t('Unit_Number');
  const sharedAddedSuccessfully = t('Shared_AddedSuccessfully');
  const fieldErrorRequired = t('Field_Error_Required');
  const unitNumberRequired = StringUtils.format(fieldErrorRequired, unitNumber);
  const unitAddedSuccessfully = StringUtils.format(sharedAddedSuccessfully, unit);

  const [successMessage, setSuccessMessage] = React.useState(unitAddedSuccessfully);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = React.useState(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState(false);
  const [createTenant] = useCreateTenantMutation();
  const [fetchTenants] = useLazyGetTenantListWithSiteIdQuery();
  const siteId = useParams().id;

  const tenantList = useSelector((state: RootState) => state.tenants.TenantList);

  // Get the initial value for the building
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const buildingOptions = Object.entries(buildingList)
    .map(([key, value]) => ({
      value: key,
      label: value.buildingNumber
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const initialValues = {
    buildingNumber: buildingOptions[0]?.value ?? '',
    unitNumber: unitPublicId ?? '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    primaryTenant: false
  };

  //TODO: Move to shared validation schema
  const validationSchema = React.useMemo(
    () =>
      yup.object().shape({
        buildingNumber: yup.string().required(t('Building_Number_Required')),
        unitNumber: yup.string().required(unitNumberRequired),
        firstName: yup
          .string()
          .max(16, t('First_Name_Too_Long'))
          .matches(VALID_STATION_NAME_REGEX, t('First_Name_Invalid'))
          .required(t('First_Name_Required')),
        lastName: yup
          .string()
          .max(16, t('Last_Name_Too_Long'))
          .matches(VALID_STATION_NAME_REGEX, t('Last_Name_Invalid'))
          .required(t('Last_Name_Required')),
        email: yup.string().email(t('Invalid_Email')),
        phoneNumber: yup
          .string()
          .min(10, t('Phone_number_must_be_10_digits'))
          .matches(NANP_PHONE_REGEX, t('NANP_Description'))
      }),
    [t]
  );

  const handleAddTenant = React.useCallback(
    async (values: any) => {
      const isOnlyTenant =
        Object.values(tenantList).filter((tenant) => tenant.unitPublicId === values.unitNumber).length === 0;

      const params = {
        tenantData: {
          firstName: values.firstName,
          firstNameOtherLanguage: null,
          lastName: values.lastName,
          lastNameOtherLanguage: null,
          email: values.email,
          phone: convertPhoneNumberToE164Format(values.phoneNumber),
          isPrimaryTenant: isOnlyTenant ? true : values.primaryTenant,
          languageId: 2,
          unitPublicId: values.unitNumber
        }
      };

      await createTenant(params)
        .unwrap()
        .then(() => {
          setSuccessMessage(t('Tenant_Added_Successfully'));
          setIsSuccessAlertOpen(true);
          setIsOpen(false);
          fetchTenants({ sitePublicId: siteId, qty: 500, page: 0 });
        })
        .catch((error) => {
          setErrorMessage(error.data.message);
          setIsErrorAlertOpen(true);
        });
    },
    [createTenant, fetchTenants, setIsOpen, siteId, tenantList, t]
  );

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle>{t('Add_Tenant')}</DialogTitle>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleAddTenant}>
          {({ ...props }) => <AddTenantForm formikProps={props} setIsOpen={setIsOpen} unitPublicId={unitPublicId} />}
        </Formik>
      </Dialog>
      <SnackbarAlert
        type="success"
        time={3000}
        text={successMessage}
        isOpen={isSuccessAlertOpen}
        onClose={() => setIsSuccessAlertOpen(false)}
      />
      <SnackbarAlert
        type="error"
        time={3000}
        text={errorMessage}
        isOpen={isErrorAlertOpen}
        onClose={() => setIsErrorAlertOpen(false)}
      />
    </>
  );
};

export default AddTenantDialog;
