import { useEffect, useState } from 'react';
import { RootState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Switch,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import CloseIcon from '@mui/icons-material/Close';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';
import { IUnit } from 'store/slices/unitsSlice';
import DirectoryDraggableList from './DirectoryDraggableList.tsx';
import UnitAddressBookList from '../unitAddressBook/UnitAddressBookList';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { updateSelectedDevice, getSelectedDevice } from 'store/slices/devicesSlice';
import { ICallDirectoryList } from './EntranceDisplay.tsx';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { UUID } from 'crypto';

interface IFormData {
  id: number;
  name: string;
  imageName: string;
  enabled: boolean;
  reduxKey: string | null;
  default: boolean;
}
interface IDisplaySettingsProps {
  needDisplaySave: boolean;
  callDirectoryList: ICallDirectoryList[];
  unitAddressBookIds: string[];
  formData: IFormData[];
  toggleEnable: (id: number) => void;
  handleSelectDefaultScreen: (id: number) => void;
  handleUpdateCallDirectoryList: (unitList: ICallDirectoryList[]) => void;
  highestCallDirectoryId: number;
}

const selectSite = (state: RootState) => state.site;
const selectUnit = (state: RootState) => state.units;

const DisplaySettings: React.FC<IDisplaySettingsProps> = ({
  needDisplaySave,
  callDirectoryList,
  unitAddressBookIds,
  formData,
  toggleEnable,
  handleSelectDefaultScreen,
  handleUpdateCallDirectoryList,
  highestCallDirectoryId
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const site = useSelector(selectSite);
  const sitePublicId = site?.siteInfo?.publicId;
  const [itemStates, setItemStates] = useState<Record<string, boolean>>(
    formData.reduce((acc, item) => {
      acc[item.id] = item.enabled;
      return acc;
    }, {} as Record<string, boolean>)
  );

  const unitList = useSelector(selectUnit).UnitList;
  const selectedDevice = useSelector(getSelectedDevice);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const [unitAddressBookList, setUnitAddressBookList] = useState<IUnit[]>([]);
  const [open, setOpen] = useState(false);
  const [needCallDestinationSave, setNeedCallDestinationSave] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  useEffect(() => {
    // filter unitList filter by unitAddressBookIds
    const filteredUnitList = unitAddressBookIds.map((id) => unitList[id]).filter((unit) => unit);
    setUnitAddressBookList(filteredUnitList);
  }, [unitAddressBookIds]);

  const handleDirectoryConfiguration = (event: React.MouseEvent) => {
    event?.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeyCode = (event: React.MouseEvent) => {
    event?.stopPropagation();
    navigate(`/site/${sitePublicId}/units`);
  };

  const handleSelectImage = (id: number) => {
    handleSelectDefaultScreen(id);
  };

  /** Saving call directory list to DB */
  /**
   * MVP - Save all the units in the main directory where the parentGroupId is null
   * new group Id is assign for changed units order
   * fetching the highest groupId from the backend and add 1 to it
   */
  const handleSave = () => {
    // TODO: Save the call directory list with groupId....check the groupId from the backend
    const formattedCallDirectoryList = callDirectoryList.map((unit, index) => ({
      unitPublicId: unit.unitPublicId,
      groupId: index + 1 + highestCallDirectoryId
    }));

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.entrancePanelSettings = {
      quickSelectionGroupList: formattedCallDirectoryList
    };

    const payload = {
      device: {
        publicId: selectedDevice.publicId,
        entrancePanelSettings: {
          ...newDevice.entrancePanelSettings
        }
      }
    };

    updateDevice(payload)
      .unwrap()
      .then(() => {
        dispatch(updateSelectedDevice({ device: newDevice }));
        setSuccessMessage('Call Directory settings saved successfully');
        setNeedCallDestinationSave(false);
        setOpen(false);
      })
      .catch((error) => {
        console.error('error', error);
      });
  };

  const handleUniqueCallDirectoryList = (callDirectoryList: ICallDirectoryList[]) => {
    const uniqueCallDirectoryList = callDirectoryList.reduce((acc: ICallDirectoryList[], current) => {
      const x = acc.find((item) => item.unitPublicId === current.unitPublicId);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    });
    handleUpdateCallDirectoryList(uniqueCallDirectoryList);
  };

  const onSelectUnit = (unitPublicId: string) => {
    setNeedCallDestinationSave(true);
    // extract all the unit ids from the callDirectoryList
    const callDirectoryIds: string[] = callDirectoryList.map((unit) => unit.unitPublicId);
    if (callDirectoryIds.includes(unitPublicId)) {
      return;
    }
    // add the unit to the callDirectoryList
    // get the highest id from the callDirectoryList
    const highestId = callDirectoryList.length > 0 ? Math.max(...callDirectoryList.map((unit) => unit.level)) + 1 : 1;
    const newUnit = {
      level: highestId,
      unitPublicId: unitPublicId as UUID
    };
    const updatedCallDirectoryList = [...callDirectoryList, newUnit];
    handleUpdateCallDirectoryList(updatedCallDirectoryList);
  };

  const toggleSelectAll = () => {
    setNeedCallDestinationSave(true);

    if (unitAddressBookList.length === callDirectoryList.length) {
      handleUpdateCallDirectoryList([]);
      return;
    }

    // extra check to make sure the callDirectoryList is unique
    if (callDirectoryList.length > unitAddressBookList.length) {
      handleUniqueCallDirectoryList(callDirectoryList);
      return;
    }

    // Todo: the limit is 500 units to add to the call directory list
    // check if the callDirectoryList is empty, if it is empty then add all the units to the callDirectoryList
    // check the highest id of the callDirectoryList
    if (callDirectoryList.length === 0) {
      const newCallDirectoryList = unitAddressBookList.map((unit, index) => {
        return {
          level: highestCallDirectoryId + index + 1,
          unitPublicId: unit.publicId
        };
      });
      handleUpdateCallDirectoryList(newCallDirectoryList);
    } else {
      const highestId = Math.max(...callDirectoryList.map((unit) => unit.level));
      const prevCallDirectoryListIds = callDirectoryList.map((unit) => unit.unitPublicId);
      const restOfAddressBookList = unitAddressBookList.filter(
        (unit) => !prevCallDirectoryListIds.includes(unit.publicId)
      );
      // fetch the units from the addressbook that are not in the callDirectoryList
      const newCallDirectoryList = restOfAddressBookList.map((unit, index) => {
        return {
          level: highestId + index + 1,
          unitPublicId: unit.publicId
        };
      });
      handleUpdateCallDirectoryList([...callDirectoryList, ...newCallDirectoryList]);
    }
  };

  const removeUnitFromCallDirectory = (id: UUID) => {
    setNeedCallDestinationSave(true);
    const updatedCallDirectoryList = callDirectoryList.filter((unit) => unit.unitPublicId !== id);
    handleUpdateCallDirectoryList(updatedCallDirectoryList);
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>, id: number) => {
    event.stopPropagation();
    setItemStates((prevState) => ({ ...prevState, [id]: event.target.checked }));
    toggleEnable(id);
  };

  return (
    <>
      <SnackbarAlert
        type="success"
        time={7000}
        text={`${successMessage}`}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />
      <SnackbarAlert
        type="error"
        time={7000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <Grid container spacing={2} sx={styles.mainSettingWrapper}>
        {formData.map((item) =>
          item ? (
            <Grid item xs={12} sm={6} md={3} lg={2} key={item.id}>
              <Box sx={item.default ? styles.defaultCardWrapper : null}>
                <Typography variant="h5">{item.default ? 'Default' : null}</Typography>
                <Tooltip
                  title="Please enable the screen before selecting the default option."
                  disableHoverListener={item.enabled}
                >
                  <Card sx={styles.mainCardWrapper}>
                    <Box onClick={() => item.enabled && handleSelectImage(item.id)}>
                      <Box sx={item.enabled ? styles.cardTitleWrapperEnable : styles.cardTitleWrapperDisable}>
                        <Typography variant="body2">{item.name}</Typography>
                      </Box>
                      <CardMedia
                        component="img"
                        sx={{ width: 200, paddingY: 5 }}
                        image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/${item.imageName}.png`}
                        alt={item.name}
                      />
                    </Box>

                    <CardContent sx={{ flex: 1 }}>
                      <Box sx={styles.toggleWrapper}>
                        <Typography variant="body2">Disabled</Typography>
                        <Switch
                          checked={itemStates[item.id]}
                          onChange={(event) => handleToggle(event, item.id)}
                          color="primary"
                        />
                        <Typography variant="body2">Enabled</Typography>
                      </Box>

                      {item.name === 'Call By Directory' && (
                        <Box sx={styles.buttonWrapper}>
                          <Button
                            variant="contained"
                            color="primary"
                            aria-hidden={false}
                            onClick={handleDirectoryConfiguration}
                          >
                            Configure Directory
                          </Button>
                        </Box>
                      )}

                      {((item.name === 'Door Release Keypad' && item.enabled) ||
                        (item.name === 'Door Release QR Code' && item.enabled)) && (
                        <Box sx={styles.buttonWrapper}>
                          <Tooltip
                            title={
                              needDisplaySave
                                ? "You have unsaved data. It's recommended to save before continuing."
                                : ''
                            }
                            disableHoverListener={!needDisplaySave}
                          >
                            <Button variant="contained" color="primary" onClick={handleKeyCode}>
                              Configure Access Codes
                            </Button>
                          </Tooltip>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Tooltip>
              </Box>
            </Grid>
          ) : (
            <Skeleton variant="rectangular" width={210} height={118} />
          )
        )}
      </Grid>

      <Dialog open={open} onClose={handleClose} PaperProps={{ sx: styles.dialogMainWrapper }}>
        <DialogTitle>
          Configure Directory
          <Tooltip
            title={
              needCallDestinationSave ? 'You have unsaved data. Closing now will result in loss of this data.' : ''
            }
            disableHoverListener={!needCallDestinationSave}
          >
            <IconButton onClick={handleClose} sx={styles.modalCloseButton}>
              <CloseIcon color="inherit" />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent
          sx={{
            flexGrow: 1
          }}
        >
          <Box sx={styles.dialogMainBoxWrapper}>
            <Box sx={styles.modalInnerBox}>
              <UnitAddressBookList
                unitAddressBookList={unitAddressBookList}
                callDirectoryList={callDirectoryList}
                removeUnitFromCallDirectory={removeUnitFromCallDirectory}
                onSelectUnit={onSelectUnit}
                toggleSelectAll={toggleSelectAll}
                isSelectAll={false}
              />
            </Box>
            <Box sx={styles.modalInnerBox}>
              <DirectoryDraggableList
                callDirectoryList={callDirectoryList}
                unitAddressBookList={unitAddressBookList}
                onUpdateItems={handleUpdateCallDirectoryList}
                setNeedCallDestinationSave={setNeedCallDestinationSave}
              />
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            disabled={!needCallDestinationSave || isUpdating}
            onClick={handleSave}
            color="primary"
            aria-hidden={false}
            fullWidth
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const styles = {
  mainSettingWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '97%',
    gap: 2,
    justifyContent: 'space-between'
  },
  mainCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 200,
    justifyContent: 'space-between',

    '&:hover': {
      border: '2px solid #003366'
    }
  },
  defaultCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 220,
    padding: '10px',
    paddingBottom: '40px',
    border: '2px solid #003366',
    background: 'rgba(0, 51, 102, 0.1)',
    borderRadius: '5px',
    color: '#003366'
  },
  cardTitleWrapperDisable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingY: '10px',
    width: '100%',
    backgroundColor: '#cfcfcf',
    color: '#6F6C6D'
  },
  cardTitleWrapperEnable: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingY: '10px',
    width: '100%',
    backgroundColor: '#003366',
    color: '#c3c3c3'
  },
  toggleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginY: '10px'
  },
  dialogMainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    maxWidth: '900px',
    height: '80%',
    maxHeight: '80vh'
  },
  dialogMainBoxWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%'
  },
  modalCloseButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme: { palette: { grey: any[] } }) => theme.palette.grey[500]
  },
  modalInnerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 2,
    padding: '20px',
    width: '50%',
    minWidth: '400px'
  }
};

export default DisplaySettings;
