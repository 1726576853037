import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, TextField, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUpdateUnitMutation } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { RootState } from 'store';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import StringUtils from 'shared/utils/StringUtils';

const ZERO_PAD = false;

const UnitInfoCard = () => {
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [updateUnit] = useUpdateUnitMutation();
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const unitPublicId = useParams().unitid ?? '';
  const selectedUnit = unitList[unitPublicId];
  const { t } = useTranslation();
  const unit = t('Unit');
  const unitName = t('Unit_Name');
  const unitNumber = t('Unit_Number');
  const unitDetails = t('Unit_Details');
  const loadingItem = t('Shared_Loading');
  const saveFailed = t('Error_FailedSave');
  const saveItem = t('Shared_Save');
  const savedSuccessfully = t('Shared_SaveSuccess');
  const fieldErrorRequired = t('Field_Error_Required');
  const fieldErrorMinLen = t('Field_Error_MinLen');
  const fieldErrorMaxLen = t('Field_Error_MaxLen');
  const fieldErrorMinDigits = t('Field_Error_MinDigits');
  const fieldErrorMaxDigits = t('Field_Error_MaxDigits');
  const fieldErrorNumbersOnly = t('Field_Error_NumbersOnly');
  const fieldErrorInvalid = t('Field_Error_Invalid');
  const unitNumberMinDigits = 3;
  const unitNumberMaxDigits = 10;
  const unitNameMinLen = 1;
  const unitNameMaxLen = 24;
  const unitNumberRequired = StringUtils.format(fieldErrorRequired, unitNumber);
  const unitNumberNumbersOnly = StringUtils.format(fieldErrorNumbersOnly, unitNumber);
  const unitNameRequired = StringUtils.format(fieldErrorRequired, unitName);
  const unitNameInvalid = StringUtils.format(fieldErrorInvalid, unitName);
  const unitNumberMinDigitsStr = StringUtils.format(fieldErrorMinDigits, unitNumber, unitNumberMinDigits);
  const unitNumberMaxDigitsStr = StringUtils.format(fieldErrorMaxDigits, unitNumber, unitNumberMaxDigits);
  const unitNameMinLenStr = StringUtils.format(fieldErrorMinLen, unitName, unitNameMinLen);
  const unitNameMaxLenStr = StringUtils.format(fieldErrorMaxLen, unitName, unitNameMaxLen);
  const unitDetailsInvalid = StringUtils.format(fieldErrorInvalid, unitDetails);
  const unitDetailsSaveFailed = StringUtils.format(saveFailed, unitDetails);
  const unitDetailsSave = StringUtils.format(saveItem, unitDetails);
  const unitDetailsSaveSuccess = StringUtils.format(savedSuccessfully, unitDetails);
  const unitLoading = StringUtils.format(loadingItem, unit);

  const validationSchema = React.useMemo(
    () =>
      yup.object({
        unitNumber: yup
          .string()
          .matches(/^\d+$/, unitNumberNumbersOnly)
          .min(unitNumberMinDigits, unitNumberMinDigitsStr)
          .max(unitNumberMaxDigits, unitNumberMaxDigitsStr)
          .required(unitNumberRequired),
        unitName: yup
          .string()
          .min(1, unitNameMinLenStr)
          .max(24, unitNameMaxLenStr)
          .matches(/^[a-zA-Z0-9\s"!$%&()\-./:;?@_+]*$/, unitNameInvalid)
          .required(unitNameRequired)
      }),
    [t]
  );

  const validateParams = (params: any) => {
    try {
      validationSchema.validateSync(params.unitData);
      return true;
    } catch (error) {
      return false;
    }
  };

  const saveUnitDetails = React.useCallback(
    (values: any, actions: any) => {
      const params = {
        unitData: {
          ...selectedUnit,
          unitNumber: values.unitNumber,
          unitName: values.unitName
        },
        unitPublicId: selectedUnit.publicId
      };

      if (!validateParams(params)) {
        setError(unitDetailsInvalid);
        actions.setSubmitting(false);
        return;
      }

      updateUnit(params)
        .unwrap()
        .then(() => {
          setSuccess(unitDetailsSaveSuccess);
          actions.setSubmitting(false);
        })
        .catch((_error) => {
          setError(unitDetailsSaveFailed);
          actions.setSubmitting(false);
        });
    },
    [t, selectedUnit, updateUnit]
  );

  if (!selectedUnit) {
    return (
      <Card sx={styles.unitCard}>
        <Typography variant="sectionHeader">{unitLoading}</Typography>
        <Box></Box>
      </Card>
    );
  }

  const initialValues = {
    unitNumber: selectedUnit?.unitNumber || '',
    unitName: selectedUnit?.unitName || ''
  };

  return (
    <>
      <Card sx={styles.unitCard}>
        <Typography variant="sectionHeader">
          {unit} {selectedUnit.unitNumber || ''}
        </Typography>
        <Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={saveUnitDetails}
          >
            {({ errors, touched, isSubmitting, isValid, setFieldValue }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Field name="unitNumber">
                      {({ field }: any) => (
                        <TextField
                          {...field}
                          label={unitNumber}
                          fullWidth
                          error={touched.unitNumber && Boolean(errors.unitNumber)}
                          helperText={touched.unitNumber && errors.unitNumber}
                          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 }}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            let unitNumber = event.target.value.replace(/\D/g, '');
                            if (unitNumber.length < 3 && ZERO_PAD) {
                              unitNumber = unitNumber.padStart(3, '0');
                            }
                            setFieldValue('unitNumber', unitNumber);
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field name="unitName">
                      {({ field }: any) => (
                        <TextField
                          {...field}
                          label={unitName}
                          fullWidth
                          inputProps={{ maxLength: 24 }}
                          error={touched.unitName && Boolean(errors.unitName)}
                          helperText={touched.unitName && errors.unitName}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      sx={styles.loadingButton}
                      loading={isSubmitting}
                      disabled={!isValid || isSubmitting}
                    >
                      {unitDetailsSave}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
      <SnackbarAlert
        type={error ? 'error' : 'success'}
        time={6000}
        text={error ?? success ?? ''}
        isOpen={!!error || !!success}
        onClose={() => {
          setError(null);
          setSuccess(null);
        }}
      />
    </>
  );
};

const styles = {
  unitCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '20px'
  },
  unitInputsWrapper: {
    display: 'flex',
    width: '100%',
    marginTop: '10px'
  },
  unitDetails: {
    marginTop: '20px'
  },
  loadingButton: {
    float: 'right'
  }
};

export default UnitInfoCard;
