import { getEnumFullList } from 'shared/api/Aws/awsApi';
import { ApiError, ErrorType } from '../api/ApiError';
import localEnumList from 'shared/utils/localEnumList';

export interface IDefaultValue {
  value: string;
}

export interface IConditionalDefaultValue extends IDefaultValue {
  supportedDeviceTypeList: number[];
}

export interface IDeviceModelValue extends IDefaultValue {
  modelNumber: number;
  deviceType: number;
}

export interface ICountryValue extends IDefaultValue {
  numericCode: string;
  alpha2Code: string;
  alpha3Code: string;
  defaultLanguageName: string;
  defaultLanguageId: number;
}

export interface IStateValue extends IDefaultValue {
  isoCode: string;
  countryId: number;
  fullPronunciationName: string;
  defaultLanguageName: string;
}

type EnumValue = IDefaultValue | IConditionalDefaultValue | IDeviceModelValue | ICountryValue | IStateValue;

export type EnumObject = Record<string, EnumValue>;

export type EnumList = Record<string, EnumObject>;

/**Fetch all enums*/
export const fetchEnumList = async (): Promise<EnumList> => {
  const storedEnumList: string = sessionStorage.getItem(`aiphoneCloud_enumList`) || '';

  let enumList: EnumList | null = null;
  if (!storedEnumList) {
    const response = await getEnumFullList();
    enumList = response?.data;
    sessionStorage.setItem(`aiphoneCloud_enumList`, JSON.stringify(enumList));
  } else {
    enumList = JSON.parse(storedEnumList);
  }

  if (!enumList) {
    throw new ApiError('Failed to fetch enum list', ErrorType.SERVER);
  }

  return enumList;
};

// TODO rename to getEnumList
export const fetchLocalEnumList = (): EnumList => {
  const storedEnumList: string | null = sessionStorage.getItem(`aiphoneCloud_enumList`);

  return storedEnumList ? JSON.parse(storedEnumList) : { ...localEnumList, isLocal: true };
};
