import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import { RegexIpV4 } from 'features/RemoteManagement/Types';

export const ipV4AddressBlacklist: string[] = ['0.0.0.0'];

const useIpV4AddressValidationSchema = (fieldName: string): Yup.StringSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldInvalid: string = StringUtils.format(t('Field_Error_Invalid'), fieldName);

  return Yup.string().notOneOf(ipV4AddressBlacklist, formattedFieldInvalid).matches(RegexIpV4, formattedFieldInvalid);
};

export default useIpV4AddressValidationSchema;
