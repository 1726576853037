import { Box, CircularProgress, Typography } from '@mui/material';
import { useHandleGatewayCommandMutation, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, updateSite as updateSiteInStore } from 'store';
import { updateSite } from 'shared/api/Aws/awsApi';
import { LoadingButton } from '@mui/lab';
import CachedIcon from '@mui/icons-material/Cached';
import { getSite } from 'store/slices/siteSlice';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useState } from 'react';
import StringUtils from 'shared/utils/StringUtils';
import { getString } from 'shared/utils/LocalizationUtils';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { getSerialNumber } from 'shared/utils/helperFunctions';
import { useTranslation } from 'react-i18next';
import 'styles/frontshine.css';

export const ToolsLabel = () => {
  return <span>Tools</span>;
};

const Tools = ({ hasEditPermission }: { hasEditPermission: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const site = useSelector(getSite);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  useSelector((state: RootState) => state.devices);
  const selectedDevice = useSelector(getSelectedDevice);
  const [isLoading, setIsLoading] = useState(false);
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const isGatewayFirstSync = gateway?.lastSyncedOn === null;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [updateDevice] = useUpdateDeviceMutation();
  const hostname = window.location.hostname;
  const isProductionAccount = !['preprod', 'beta', 'localhost'].some((substring) => hostname.includes(substring));

  const FIRMWARE_VERSION = '3.80';

  // communicating dialog, disable for now
  /*const [sendingSearch, setSendingSearch] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleClose = () => {
    setIsDialogOpen(false);
  };*/

  if (selectedDevice?.basicInfo?.deviceType !== 18) {
    return null;
  }
  const rebootDevice = async () => {
    setIsLoading(true);
    // initialize the device
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };
    const isDeviceFirstSync = selectedDevice?.lastSyncedOn === null;

    const deviceInfo = {
      deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
      deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
      deviceType: selectedDevice?.basicInfo?.deviceType,
      deviceId: isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
      devicePassword: isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminPass
    };

    try {
      // send command to gateway via ioT
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.POWER_CYCLE, gatewayInfo, deviceInfo, null);
      // TODO: Make 'Missing information' into a standard constant string.
      if (ioTPayload === 'Missing information') {
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload).unwrap();
      // create a timeout for 60 seconds
      await new Promise((resolve) => setTimeout(resolve, 60000));

      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.POWER_CYCLE, gatewayInfo, deviceInfo, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      setIsLoading(false);

      const statusCode = fetchResponse?.statusCode.slice(0, 3);
      if (statusCode === '200') {
        // show success message
        setShowAlert(true);
        return;
      } else {
        setErrorMessage('Error: Unable to reboot the device');
        return;
      }
    } catch (error) {
      setErrorMessage('Error: Unable to reboot the device');
      setIsLoading(false);
      return;
    }
  };

  const registerGateway = async () => {
    if (!site.siteInfo.awsPropertyId) {
      setErrorMessage(t('Error_Missing_Property_ID'));
      return;
    }
    // register the gateway
    setIsLoading(true);
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: selectedDevice?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : selectedDevice?.basicInfo.adminPass,
      gwIpAddress: selectedDevice?.networkSettings?.ipV4Address
    };
    // prepare payload for gateway registration
    const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.REGISTER, gatewayInfo, null, null);
    // this should never fail to reach our backend
    // if the command succeeds, the gateway will stop listening to the queue.
    await handleGatewayCommand(ioTPayload).unwrap();

    try {
      // wait 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 15000));
      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.REGISTER, gatewayInfo, null, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      const statusCode = fetchResponse?.statusCode.slice(0, 3);
      if (statusCode === '200') {
        // update the site with the registeredGatewayPublicId
        const updateSitePayload = {
          updatedSite: {
            publicId: site.siteInfo.publicId,
            registeredGatewayPublicId: selectedDevice.publicId
          }
        };
        await updateSite(updateSitePayload);
        dispatch(updateSiteInStore(updateSitePayload));

        // update the device with the fetchResponse data
        const serial = getSerialNumber(gatewayInfo.gwMacAddress);

        const updatedDeviceParams = {
          device: {
            publicId: selectedDevice?.publicId,
            sitePublicId: site.siteInfo.publicId,
            networkSettings: {
              ...selectedDevice?.networkSettings,
              ipV4Address: fetchResponse?.payload[0]?.ip_addr.toString(),
              subnetMask: fetchResponse?.payload[0]?.ip_subnet.toString(),
              ipV4DefaultGateway: fetchResponse?.payload[0]?.ip_gateway.toString(),
              ipV4PrimaryDns:
                fetchResponse?.payload[0]?.dns_primary?.toString() === '127.0.0.1'
                  ? '8.8.8.8'
                  : fetchResponse?.payload[0]?.dns_primary?.toString()
            },
            gatewaySettings: {
              gatewaySerialNumber: serial
            }
          }
        };
        updateDevice(updatedDeviceParams);

        // get the firmware version of the gateway from a search command
        updateGatewayWithSearchResult();
      } else if (statusCode === '420') {
        // If the gateway responded (was listening to the queue) but rejected the request, show an error.
        setIsLoading(false);
        //setIsDialogOpen(false); disable modal for now

        // instead of the error, let's try again with default admin admin
        throw { message: t('GatewayError_AuthError') };
        // TODO: trying again with default admin admin
      } else {
        setIsLoading(false);
        //setIsDialogOpen(false); disable modal for now
        throw { message: t('GatewayError_RegisterError') };
      }
    } catch (error) {
      setIsLoading(false);
      //setIsDialogOpen(false); disable modal for now
      setErrorMessage(t('GatewayError_RegisterError'));
    }
  };

  const updateGatewayWithSearchResult = async () => {
    setIsLoading(true);
    //setIsDialogOpen(true); disable modal for now
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: selectedDevice?.basicInfo?.macAddress,
      gwId: isGatewayFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
      gwPassword: isGatewayFirstSync ? 'admin' : selectedDevice?.basicInfo.adminPass,
      gwIpAddress: selectedDevice?.networkSettings?.ipV4Address
    };

    // Do a search and fetch the gateway details
    let searchPayload = fetchGatewayCommand('sendCommand', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
    await handleGatewayCommand(searchPayload).unwrap();

    try {
      // fetch response from dynamoDB
      searchPayload = fetchGatewayCommand('fetchResult', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
      const searchResponse = await handleGatewayCommand(searchPayload).unwrap();
      const statusCode = searchResponse?.statusCode?.slice(0, 3) || null;

      if (statusCode === '200') {
        // find gateway details from Search results
        const gatewayInfoFromResponse = searchResponse.payload.find(
          (device) => device.mac_addr === gatewayInfo.gwMacAddress
        );
        const gwFirmwareVersion = gatewayInfoFromResponse.fw_ver;

        const updatedDeviceParams = {
          device: {
            publicId: selectedDevice?.publicId,
            sitePublicId: site.siteInfo.publicId,
            basicInfo: {
              firmwareVersion: gwFirmwareVersion
            }
          }
        };
        updateDevice(updatedDeviceParams);
      } else if (statusCode === '402') {
        // likely the site thinks the gateway has been synced but shouldn't, so try the operation again with opposite credentials
        const gatewayInfo = {
          awsPropertyId: site?.siteInfo?.awsPropertyId,
          gwMacAddress: selectedDevice?.basicInfo?.macAddress,
          gwId: isGatewayFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
          gwPassword: isGatewayFirstSync ? 'admin' : selectedDevice?.basicInfo.adminPass,
          gwIpAddress: selectedDevice?.networkSettings?.ipV4Address
        };
        // Do a search and fetch the gateway details
        let searchPayload = fetchGatewayCommand('sendCommand', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
        await handleGatewayCommand(searchPayload).unwrap();

        // fetch response from dynamoDB
        searchPayload = fetchGatewayCommand('fetchResult', gwCommand.STATION_SEARCH, gatewayInfo, null, null);
        const searchResponse = await handleGatewayCommand(searchPayload).unwrap();

        if (searchResponse !== null && searchResponse !== undefined) {
          // find gateway details from Search results
          const gatewayInfoFromResponse = searchResponse.payload.find(
            (device) => device.mac_addr === gatewayInfo.gwMacAddress
          );
          const gwFirmwareVersion = gatewayInfoFromResponse.fw_ver;

          const updatedDeviceParams = {
            device: {
              publicId: selectedDevice?.publicId,
              sitePublicId: site.siteInfo.publicId,
              basicInfo: {
                firmwareVersion: gwFirmwareVersion
              }
            }
          };
          updateDevice(updatedDeviceParams);
        } else {
          // If the Search fails, we will ignore it and set the firmware version to the default
          const updatedDeviceParams = {
            device: {
              publicId: selectedDevice?.publicId,
              sitePublicId: site.siteInfo.publicId,
              basicInfo: {
                firmwareVersion: '3.80'
              }
            }
          };
          updateDevice(updatedDeviceParams);
        }
      } else {
        // If the gateway responded (was listening to the queue) but rejected the request, show an error.
        setIsLoading(false);
        //setIsDialogOpen(false); disable modal for now
        throw { message: t('GatewayError_AuthError') };
      }
    } catch (error) {
      // if the Search fails, we will ignore it and set the firmware version to the default
      const updatedDeviceParams = {
        device: {
          publicId: selectedDevice?.publicId,
          sitePublicId: site.siteInfo.publicId,
          basicInfo: {
            firmwareVersion: FIRMWARE_VERSION
          }
        }
      };
      updateDevice(updatedDeviceParams);
    } finally {
      setIsLoading(false);
      //setIsDialogOpen(false); disable modal for now
    }
  };

  const unRegisterGateway = async () => {
    // Unregister the gateway
    setIsLoading(true);
    if (!site?.siteInfo?.registeredGatewayPublicId) {
      setIsLoading(false);
      setErrorMessage(t('GatewayError_UnregisterError'));
      return;
    }
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminId,
      gwPassword: systemPassword ? systemPassword : isGatewayFirstSync ? 'admin' : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };
    try {
      // send command to gateway via ioT
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.UNREGISTER, gatewayInfo, null, null);
      if (ioTPayload === 'Missing information') {
        throw new Error('Missing information');
      }
      await handleGatewayCommand(ioTPayload).unwrap();
      // create a timeout for 60 seconds
      await new Promise((resolve) => setTimeout(resolve, 20000));

      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.UNREGISTER, gatewayInfo, null, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      if (fetchResponse?.error) {
        throw { message: t('GatewayError_UnregisterError') };
      }
      dispatch(updateSiteInStore({ registeredGatewayPublicId: null }));

      const updateSitePayload = {
        updatedSite: {
          publicId: site.siteInfo.publicId,
          registeredGatewayPublicId: null
        }
      };
      dispatch(updateSiteInStore(updateSitePayload));
      await updateSite(updateSitePayload);

      const statusCode = fetchResponse?.statusCode.slice(0, 3);
      setIsLoading(false);
      if (statusCode === '200') {
        // show success message
        setShowAlert(true);
      } else {
        setErrorMessage(t('GatewayError_UnregisterError'));
      }
    } catch (error) {
      setErrorMessage(t('GatewayError_UnregisterError'));
      setIsLoading(false);
      return;
    } finally {
      setIsLoading(false);

      // regardless of result, clear the registered gateway public id, in case the device has been reset or is no longer working
      const updateSitePayload = {
        updatedSite: {
          publicId: site.siteInfo.publicId,
          registeredGatewayPublicId: null
        }
      };
      dispatch(updateSiteInStore(updateSitePayload));
      await updateSite(updateSitePayload);
    }
  };

  return (
    <div className={'flex flex-col w-full'}>
      <Typography variant="h5" color="text.primary" sx={{ paddingBottom: '5px' }}>
        Toolbox
      </Typography>
      <Box sx={styles.gwContainer}>
        {selectedDevice?.basicInfo?.deviceType === 18 ? (
          <>
            {
              !isProductionAccount ? (
                <Box alignItems={'center'} flexDirection={'row'} sx={styles.buttonGWContainer}>
                  <LoadingButton
                    loading={isLoading}
                    disabled={isLoading || !hasEditPermission}
                    loadingIndicator={<CircularProgress size="20px" color="info" />}
                  >
                    <CloudOffIcon onClick={unRegisterGateway} />
                  </LoadingButton>
                  <Typography variant="h6">{t('Unregister_Gateway')}</Typography>
                </Box>
              ) : null /* Unregister is hidden in production */
            }
            <Box alignItems={'center'} flexDirection={'row'} sx={styles.buttonGWContainer}>
              <LoadingButton
                loading={isLoading}
                disabled={isLoading || !hasEditPermission}
                loadingIndicator={<CircularProgress size="20px" color="info" />}
              >
                <CloudSyncIcon onClick={registerGateway} />
              </LoadingButton>
              <Typography variant="h6">{t('Register_Gateway')}</Typography>
            </Box>
          </>
        ) : null}
        <Box sx={styles.buttonGWContainer}>
          <LoadingButton
            loading={isLoading}
            loadingIndicator={<CircularProgress size="20px" color="info" />}
            disabled={selectedDevice?.basicInfo?.deviceType !== 18 || isLoading || !hasEditPermission}
          >
            <CachedIcon onClick={rebootDevice} />
          </LoadingButton>
          <Typography variant="h6">{t('Reboot_device')}</Typography>
        </Box>
      </Box>

      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={StringUtils.format(getString('Update_Success'), getString('Site'))}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </div>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  paper: {
    padding: '20px',
    height: '100%'
  },
  title: {
    marginBottom: '10px',
    color: '#333',
    fontWeight: 'bold',
    textAlign: 'left'
  },
  subtitle: {
    display: 'flex',
    color: '#666',
    padding: '10px'
  },
  icon: {
    fontSize: '5rem'
  },
  syncContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginY: '2rem'
  },
  gwContainer: {
    display: 'left',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1rem'
  },
  gwFieldContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    margin: '1rem 0'
  },
  buttonContainer: {
    display: 'left',
    flexDirection: 'row',
    justifyContent: 'end'
  },
  buttonGWContainer: {
    display: 'flex',
    justifyContent: 'left',
    padding: '1rem',
    flexDirection: 'row',
    alignItems: 'center'
  },
  siteInfoContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1rem',
    margin: '1rem 0'
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px'
  },
  spinnerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default Tools;
