import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import AvailableDeviceList from './AvailableDeviceList';
import { IUnit } from 'store/slices/unitsSlice';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import SelectedDevicesGrid from './SelectedDevicesGrid';
import { useEffect, useState } from 'react';
import {
  useBatchUpdateDevicesMutation,
  useLazyGetUnitListWithSitePublicIdQuery,
  useUpdateDeviceMutation
} from 'services/aiphoneCloud';
import { UUID } from 'crypto';
import { LoadingButton } from '@mui/lab';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useParams } from 'react-router-dom';
import { EMPTY_UNIT } from 'features/Auth/constants';
import {
  answeringStationTypes,
  countDevicesByType,
  doorStationTypes,
  entranceStationTypes,
  getDeviceType,
  getUnitTypeString,
  unitTypeAnsweringStationLimits,
  unitTypeDoorStationLimits,
  unitTypeEntranceStationLimits
} from 'shared/utils/removeManagementHelperFunctions';

interface IAddDeviceToUnitDialogProps {
  selectedUnit: string | null;
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const AddDeviceToUnitDialog = ({ open, selectedUnit, setIsOpen }: IAddDeviceToUnitDialogProps) => {
  const [selectedDevices, setSelectedDevices] = useState<(UUID | string)[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const devicesInUnit = unitList[selectedUnit as keyof IUnit]?.devicePublicIds;
  const unitType = unitList[selectedUnit as keyof IUnit]?.unitType;
  const sitePublicId = useParams().id;
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();
  const [updateDevice] = useUpdateDeviceMutation();
  const [fetchUnits] = useLazyGetUnitListWithSitePublicIdQuery();
  const exceedLimit = selectedDevices.length > 19;

  useEffect(() => {
    setSelectedDevices(devicesInUnit ?? []);
  }, [devicesInUnit]);

  const selectedDoorStations = selectedDevices.filter((device) =>
    doorStationTypes.includes(deviceList[device as string]?.basicInfo.deviceType)
  );
  const selectedMasterStations = selectedDevices.filter((device) =>
    answeringStationTypes.includes(deviceList[device as string]?.basicInfo.deviceType)
  );

  const handleAddToSelectedDevices = (id: string) => {
    const targetDeviceType = getDeviceType(id, deviceList);
    const maxAnsweringStations = unitTypeAnsweringStationLimits[unitType as number];
    const maxDoorStations = unitTypeDoorStationLimits[unitType as number];
    const maxEntranceStations = unitTypeEntranceStationLimits[unitType as number];
    const selectedAnsweringStations = countDevicesByType(selectedDevices, deviceList, answeringStationTypes);
    const selectedDoorStations = countDevicesByType(selectedDevices, deviceList, doorStationTypes);
    const selectedEntranceStations = countDevicesByType(selectedDevices, deviceList, entranceStationTypes);

    if (answeringStationTypes.includes(targetDeviceType) && selectedAnsweringStations >= maxAnsweringStations) {
      setError(`Unit type ${getUnitTypeString(unitType)} can only have ${maxAnsweringStations} answering stations`);
      return;
    }

    if (doorStationTypes.includes(targetDeviceType) && selectedDoorStations >= maxDoorStations) {
      setError(`Unit type ${getUnitTypeString(unitType)} can only have ${maxDoorStations} door stations`);
      return;
    }

    if (entranceStationTypes.includes(targetDeviceType) && selectedEntranceStations >= maxEntranceStations) {
      setError(`Unit type ${getUnitTypeString(unitType)} can only have ${maxEntranceStations} entrance stations`);
      return;
    }

    setSelectedDevices([...selectedDevices, id]);
  };

  const handleRemoveFromSelectedDevices = async (id: string) => {
    setSelectedDevices(selectedDevices.filter((device) => device !== id));
    await updateDevice({
      device: {
        publicId: id,
        unitPublicId: EMPTY_UNIT
      }
    });
    await fetchUnits({
      sitePublicId: sitePublicId,
      qty: 500,
      page: 0
    })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setError('Failed to fetch units');
        }
      });
  };

  const handleClose = () => {
    setSelectedDevices([]);
    setIsOpen(false);
  };

  const addDevicesToUnit = async () => {
    setIsLoading(true);

    //Update the station number and station name for the devices that were created
    const devicesToUpdate = selectedDevices.flatMap((devicePublicId) => ({
      publicId: devicePublicId,
      unitPublicId: selectedUnit
    }));

    await batchUpdateDevices({ devices: devicesToUpdate })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setError('Failed to add devices to unit');
        } else {
          setSuccess('Selected devices have been added to unit');
        }
      });

    await fetchUnits({
      sitePublicId: sitePublicId,
      qty: 500,
      page: 0
    })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setError('Failed to fetch units');
        }
      });

    setIsLoading(false);
    handleClose();
  };

  return (
    <>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Add Device(s) to Unit</DialogTitle>
        <DialogContent>
          <Card sx={styles.addDeviceToUnitCard}>
            <Box sx={styles.availableDeviceListWrapper}>
              <Box
                sx={{
                  padding: '10px'
                }}
              >
                <Typography variant="h6" align="center">
                  Device(s) Available for Unit Type
                </Typography>
              </Box>
              <AvailableDeviceList
                unitType={unitType}
                selectedDevices={selectedDevices}
                handleAddToSelectedDevices={handleAddToSelectedDevices}
                handleRemoveFromSelectedDevices={handleRemoveFromSelectedDevices}
              />
            </Box>
            <Box sx={styles.addedDevicesWrapper}>
              <Box sx={styles.addedDevicesBannerWrapper}>
                <Box>
                  {exceedLimit && (
                    <Typography variant="h6" color="primary">
                      Limit reached
                    </Typography>
                  )}
                  {unitTypeAnsweringStationLimits[unitType] > 0 && (
                    <Box sx={styles.stringWrapper}>
                      <Typography variant="h6">
                        {selectedMasterStations.length} / {unitTypeAnsweringStationLimits[unitType]} answering
                        station(s) added
                      </Typography>
                    </Box>
                  )}
                  {unitTypeDoorStationLimits[unitType] > 0 && (
                    <Box sx={styles.stringWrapper}>
                      <Typography variant="h6">
                        {selectedDoorStations.length} / {unitTypeDoorStationLimits[unitType]} door station(s) added
                      </Typography>
                    </Box>
                  )}
                  {unitTypeEntranceStationLimits[unitType] > 0 && (
                    <Box sx={styles.stringWrapper}>
                      <Typography variant="h6">
                        {selectedDevices.length - selectedMasterStations.length - selectedDoorStations.length} /{' '}
                        {unitTypeEntranceStationLimits[unitType]} entrance station(s) added
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <SelectedDevicesGrid
                  selectedDevices={selectedDevices}
                  handleRemoveFromSelectedDevices={handleRemoveFromSelectedDevices}
                />
              </Box>
            </Box>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton variant="contained" color="primary" onClick={addDevicesToUnit} loading={isLoading}>
            Add Device(s) to Unit
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <SnackbarAlert type="error" time={3000} text={error ?? ''} isOpen={!!error} onClose={() => setError(null)} />
      <SnackbarAlert
        type="success"
        time={3000}
        text={success ?? ''}
        isOpen={!!success}
        onClose={() => setSuccess(null)}
      />
    </>
  );
};

const styles = {
  availableDeviceListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '300px',
    maxHeight: 'var(--window-height)',
    overflow: 'auto',
    backgroundColor: '#EFEFEF'
  },
  addDeviceToUnitCard: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '530px',
    overflow: 'auto'
  },
  addedDevicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'auto',
    width: '100%'
  },
  addedDevicesBannerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginY: '10px'
  },
  stringWrapper: {
    border: '1px solid #C0C0C0',
    borderRadius: '5px',
    padding: '5px',
    margin: '5px',
    color: 'grey'
  }
};

export default AddDeviceToUnitDialog;
