import { getDeviceModelNumberFromModelType } from 'shared/utils/helperFunctions';
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from '../../../shared/constantAwsApi';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import { Link } from 'react-router-dom';

interface IDeviceCard {
  devicePublicId: string;
}

const DeviceCard = ({ devicePublicId }: IDeviceCard) => {
  const { t } = useTranslation();
  const modelNumber = t('DeviceCard_ModelNumber');
  const stationName = t('DeviceCard_StationName');
  const stationNumber = t('DeviceCard_StationNumber');
  const sharedEdit = t('Shared_Edit');
  const advanceSettingsStr = t('AdvancedSettings');
  const editAdvancedSettings = StringUtils.format(sharedEdit, advanceSettingsStr);
  const device = useSelector((state: RootState) => state.devices.DeviceList[devicePublicId]);
  const imageUrl = `${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/icons/${getDeviceModelNumberFromModelType(
    device.basicInfo.deviceModel,
    device.basicInfo.deviceType
  )}.png`;
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography gutterBottom variant="h5" component="div" noWrap>
              {device.basicInfo.macAddress}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              <strong>{modelNumber}</strong>
              {getDeviceModelNumberFromModelType(device.basicInfo.deviceModel, device.basicInfo.deviceType)}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              <strong>{stationName}</strong>
              {device.basicInfo.stationName}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              <strong>{stationNumber}</strong>
              {device.basicInfo.stationNumber}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box component="img" src={imageUrl} />
          </Grid>
        </Grid>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Link to={`/site/${sitePublicId}/devices/${devicePublicId}/stationinfo`}>
            <Button size="small">{editAdvancedSettings}</Button>
          </Link>
        </CardActions>
      </CardContent>
    </Card>
  );
};

export default DeviceCard;
