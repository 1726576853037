import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

const useIPictureIntervalValidationSchema = (fieldName: string): Yup.NumberSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const fieldErrorMin = t('Field_Error_Min');
  const fieldErrorMax = t('Field_Error_Max');
  const fieldErrorRequired = t('Field_Error_Required');
  const formattedFieldRequired: string = StringUtils.format(fieldErrorRequired, fieldName);
  const iPictureIntervalMin = 1;
  const iPictureIntervalMax = 100;

  return Yup.number()
    .required(formattedFieldRequired)
    .min(iPictureIntervalMin, StringUtils.format(fieldErrorMin, fieldName, iPictureIntervalMin))
    .max(iPictureIntervalMax, StringUtils.format(fieldErrorMax, fieldName, iPictureIntervalMax));
};

export default useIPictureIntervalValidationSchema;
