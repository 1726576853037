import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { ZIP_CODE_REGEX, NANP_PHONE_REGEX, VALID_PASSWORD_REGEX } from 'shared/constants/regex';
import StringUtils from 'shared/utils/StringUtils';

const useAuthValidationSchemas = () => {
  const { t } = useTranslation(); // Initialize the translation hook

  const fieldErrorInvalid = t('Field_Error_Invalid');
  const postalCodeStr = t('PostalCode');
  const zipCodeStr = t('Organization_ZipCode');
  const postalCodeInvalidStr = StringUtils.format(fieldErrorInvalid, postalCodeStr);
  const zipCodeInvalidStr = StringUtils.format(fieldErrorInvalid, zipCodeStr);

  // Login Validation Schema
  const loginValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required')),
    password: yup.string().required(t('Validations.Password_Required'))
  });

  const mfaValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Registration Validation Schema
  const registerValidation = yup.object().shape({
    firstName: yup.string().required(t('Validations.Required')),
    lastName: yup.string().required(t('Validations.Required')),
    email: yup.string().email(t('Validations.Email_Not_Valid')).required(t('Validations.Required')),
    phoneNumber: yup.string().required(t('Phone_Number_Required')).matches(NANP_PHONE_REGEX, t('Invalid_phone_number')),
    companyName: yup.string().required(t('Validations.Required')),
    countryId: yup.number().required(t('Validations.Required')),
    address: yup.string().required(t('Validations.Required')),
    address2: yup.string().optional(),
    city: yup.string().required(t('Validations.Required')),
    stateId: yup.number().required(t('Validations.Required')),
    // This watches the value of 'countryId' and updates the validation for each different country
    zipCode: yup.string().when('countryId', {
      is: 2,
      then: (schema) =>
        schema
          .min(5, t('Dynamic_min', { min: 5 }))
          .max(5, t('Dynamic_max', { max: 5 }))
          .matches(ZIP_CODE_REGEX.unitedStates, zipCodeInvalidStr)
          .required(t('Validations.Required')),
      otherwise: (schema) =>
        schema
          .min(6, t('Dynamic_min', { min: 6 }))
          .max(7, t('Dynamic_max', { max: 7 }))
          .matches(ZIP_CODE_REGEX.canada, postalCodeInvalidStr)
          .required(t('Validations.Required'))
    }),
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Confirm Email Validation Schema
  const confirmEmailValidation = yup.object().shape({
    verificationCode: yup.string().required(t('Validations.Required'))
  });

  // Create Password Validation Schema
  const createPWDValidation = yup.object().shape({
    password: yup.string().required(t('Validations.Required')),
    passwordConfirm: yup
      .string()
      .required(t('Validations.Required'))
      .oneOf([yup.ref('password')], t('Validations.Password_Match'))
  });

  // Reset Password Validation Schema
  const resetPWDValidation = yup.object().shape({
    email: yup.string().email(t('Validations.Enter_Valid_Email')).required(t('Validations.Email_Required'))
  });

  return {
    loginValidation,
    registerValidation,
    confirmEmailValidation,
    createPWDValidation,
    resetPWDValidation,
    mfaValidation
  };
};

export default useAuthValidationSchemas;

/**
 * @function useYupValidators
 * Custom hook `useYupValidators` for defining and managing Yup validation schemas.
 *
 * This hook provides predefined Yup validators with localized error messages using translation functionality.
 * It is particularly designed for validating user input fields like password fields.
 *
 * @returns {Object} An object containing pre-defined Yup validation schemas:
 * - `validatePassword`: Validation schema for passwords. It checks for required passwords and validates against a regex pattern. Error messages are localized.
 */
export const useYupValidators = () => {
  const { t } = useTranslation();

  const validatePassword = yup
    .string()
    .required(t('Password_Required'))
    .matches(VALID_PASSWORD_REGEX, t('Invalid_Password'));

  return {
    validatePassword
  };
};
