import { Alert, AlertTitle, Box, Card, CardContent, LinearProgress, Tab } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Site } from './Types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SiteContext } from 'context/SiteContext';
import { useContext, useState } from 'react';
import { TabContext, TabList } from '@mui/lab';
import { addAwsPropertyId, updateSite } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { getString } from 'shared/utils/LocalizationUtils';
import { RootState } from 'store';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';

interface Props {
  isFetching: boolean;
  mySites: Site[] | [];
  myOrganizationSites: Site[] | [];
}

//TODO: Create tabs for each branch the user has access to and display the sites for each branch. Right now there is no API to get the sites for each branch.
const Sites = ({ isFetching, mySites, myOrganizationSites }: Props) => {
  const [tabIndex, setTabIndex] = useState('1');
  const { setSiteName, setPublicId } = useContext(SiteContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const branchPermissionsList = useSelector((state: RootState) => state.users.currentUser?.permissions?.branch ?? {});
  const primaryBranchPublicId = Object.keys(branchPermissionsList)[0];

  // Move the permission check logic here
  const { isAllowedTo } = usePermission();
  const canViewMyOrganizationSites = isAllowedTo('site:view', primaryBranchPublicId, PermissionsContextType.BRANCH);

  const mySitesError = false;
  const myOrganizationSitesError = false;
  const alertError = getString('Error');
  const errorDescription = getString('Sites_Error_Description');

  const handleTabChange = (event: any, newValue: string) => {
    setTabIndex(newValue);
  };

  const openSiteInfo = (params: any) => {
    if (tabIndex === '2' && !canViewMyOrganizationSites) return;

    dispatch(
      updateSite({
        publicId: params.row.id,
        siteName: params.row.siteName
      })
    );
    setSiteName(params.row.siteName);
    setPublicId(params.row.publicId);
    navigate(`/site/${params.id}/siteinfo`);
  };

  const convertToLocalTimezone = (utcTimestamp: any) => {
    return moment.utc(utcTimestamp).local().format('MM/DD/YYYY h:mm A');
  };

  const mySitesRecords = mySites
    ? Object.entries(mySites).map(([key, value]) => ({
        awsPropertyId: addAwsPropertyId(value.publicId),
        siteId: value.aclSiteId,
        siteAddress: value.siteAddress,
        id: key,
        siteName: value.siteName,
        registeredGateway: value.registeredGatewayPublicId ? 'Registered' : 'Not Registered',
        lastUpdated: value.lastUpdatedOn
      }))
    : [];

  const myOrganizationSitesRecords = myOrganizationSites
    ? Object.entries(myOrganizationSites).map(([key, value]) => ({
        awsPropertyId: addAwsPropertyId(value.publicId),
        siteId: value.aclSiteId,
        siteAddress: value.siteAddress,
        id: key,
        siteName: value.siteName,
        registeredGateway: value.registeredGatewayPublicId ? 'Registered' : 'Not Registered',
        lastUpdated: value.lastUpdatedOn
      }))
    : [];

  const renderDataGrid = (rows: any, error: boolean, disableClick: boolean) => (
    <Box>
      {error ? (
        <Alert severity="error" sx={styles.error}>
          <AlertTitle>{alertError}</AlertTitle>
          {errorDescription}
        </Alert>
      ) : (
        <DataGrid
          initialState={{
            sorting: {
              sortModel: [{ field: 'lastUpdated', sort: 'desc' }]
            }
          }}
          onRowClick={!disableClick ? openSiteInfo : undefined}
          columns={[
            { field: 'siteId', headerName: 'Site ID', width: 200 },
            { field: 'siteName', headerName: 'Site Name', width: 200 },
            { field: 'siteAddress', headerName: 'Site Address', width: 200 },
            { field: 'registeredGateway', headerName: 'Registered Gateway', width: 150, align: 'center' },
            {
              field: 'lastUpdated',
              headerName: 'Last Updated',
              width: 300,
              sortingOrder: ['asc', 'desc'],
              valueFormatter: (params) => convertToLocalTimezone(params.value)
            }
          ]}
          slots={{ loadingOverlay: LinearProgress }}
          rows={rows}
          loading={isFetching}
          autoHeight
        />
      )}
    </Box>
  );

  return (
    <Card>
      <CardContent>
        <TabContext value={tabIndex}>
          <Box>
            <TabList onChange={handleTabChange} aria-label="Device tab setting">
              <Tab label="My Sites" value="1" />
              <Tab label="My Organization Sites" value="2" />
            </TabList>
          </Box>
          {tabIndex === '1' && renderDataGrid(mySitesRecords, mySitesError, false)}
          {tabIndex === '2' &&
            renderDataGrid(myOrganizationSitesRecords, myOrganizationSitesError, !canViewMyOrganizationSites)}
        </TabContext>
      </CardContent>
    </Card>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  error: {
    mb: 2
  }
};

export default Sites;
