import { useState, useEffect } from 'react';
// import { getC2DataFromStorage } from 'features/Auth/utils/c2Operations';
import useReduxUsers from 'features/Dashboard/Hooks/useReduxUsers';

const useC2 = () => {
  const [c2Data, setC2Data] = useState<{
    c2ContactId: string | null;
    c2CompanyId: string | null;
    c2CompanyCountry: string | null;
  }>({
    c2ContactId: null,
    c2CompanyId: null,
    c2CompanyCountry: null
  });

  const [C2ApiFailed, setC2ApiFailed] = useState(false);

  const { currentUser } = useReduxUsers();

  useEffect(() => {
    const fetchC2DataFromStorage = () => {
      try {
        // Check if API error was previously marked
        const apiErrorOccurred = localStorage.getItem('C2ApiFailed') === 'true';
        setC2ApiFailed(apiErrorOccurred);

        // Get existing C2 data
        const c2ContactId = localStorage.getItem('C2ContactId');
        const c2CompanyId = localStorage.getItem('C2CompanyId');
        const c2CompanyCountry = localStorage.getItem('C2CompanyCountry');

        setC2Data({ c2ContactId, c2CompanyId, c2CompanyCountry });
      } catch (err) {
        setC2ApiFailed(true);
      }
    };

    fetchC2DataFromStorage();
  }, [currentUser?.email]);

  return { c2Data, C2ApiFailed };
};

export default useC2;
