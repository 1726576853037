import { Box, ListItemIcon, Typography, ListItem, Checkbox, IconButton } from '@mui/material';
import { getUnitsById } from 'features/RemoteManagement/selectors/unit.selectors';
import ListItemButton from '@mui/material/ListItemButton';
import AppSettingsAltOutlinedIcon from '@mui/icons-material/AppSettingsAltOutlined';
import theme from 'data/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import { t } from 'i18next';

interface IAddedAppUnitsItemProps {
  AppUnitId: string;
  isSelected: boolean;
  onSelectAppUnit: (id: string) => void;
  handleRemoveAppUnit: (id: string) => void;
}

const AddedAppUnitsItem = ({
  AppUnitId,
  isSelected,
  onSelectAppUnit,
  handleRemoveAppUnit
}: IAddedAppUnitsItemProps) => {
  const defaultValue = 'N/A';
  const unit = getUnitsById(AppUnitId);
  const { publicId, unitName, unitNumber } = unit || {};

  const handleSelectAppUnit = () => {
    if (isSelected) {
      if (publicId && handleRemoveAppUnit) {
        handleRemoveAppUnit(publicId);
      }
      return;
    }
    onSelectAppUnit(publicId);
  };

  return (
    <ListItem key={publicId} disablePadding>
      <ListItemButton sx={styles.menuItem} onClick={handleSelectAppUnit} selected={true}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              {isSelected ? (
                <Checkbox
                  edge="start"
                  checked={isSelected}
                  onChange={handleSelectAppUnit}
                  tabIndex={-1}
                  disableRipple
                />
              ) : (
                <AppSettingsAltOutlinedIcon />
              )}
            </ListItemIcon>
            <Box sx={styles.deviceDetailsWrapper}>
              <Typography variant="body2" sx={styles.deviceDetailsItem}>
                <Typography variant="h7" sx={styles.itemTitle}>
                  {t('Unit_Name')}
                </Typography>
                {unitName}
              </Typography>
              <Typography variant="body2" sx={styles.deviceDetailsItem}>
                <Typography variant="h7" sx={styles.itemTitle}>
                  {t('Unit_Number')}
                </Typography>
                {unitNumber || defaultValue}
              </Typography>
            </Box>
          </Box>
          {handleRemoveAppUnit && (
            <IconButton edge="end" aria-label="delete" onClick={() => publicId && handleRemoveAppUnit(publicId)}>
              <DeleteIcon sx={styles.deleteIcon} />
            </IconButton>
          )}
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

const styles = {
  menuItem: {
    backgroundColor: theme.palette.neutral.light
  },
  iconWrapper: {
    width: '15%',
    cursor: 'pointer'
  },
  deviceDetailsWrapper: {},
  deviceDetailsItem: {},
  itemTitle: {
    marginRight: '5px',
    fontWeight: 'bold'
  },
  deleteIcon: {
    marginRight: '14px'
  }
};

export default AddedAppUnitsItem;
