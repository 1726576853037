import { resetGatewayState, RootState } from 'store';
import CreateSite from './CreateNewSite/CreateSite';
import Sites from './Sites';
import { Alert, Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { useGetSitesQuery, useLazyGetBranchWithPublicIdQuery } from 'services/aiphoneCloud';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { usePermission } from 'context/PermissionContext';
import 'styles/frontshine.css';
import { PermissionsContextType } from 'permissions/utils';
import { Site } from './Types';
import { useTranslation } from 'react-i18next';
import { clearSiteData } from 'store/slices/siteSlice';
import { clearDevices } from 'store/slices/devicesSlice';

const RemoteManagement = () => {
  const { data: myOrganizationSites, isFetching } = useGetSitesQuery({});
  const branchData = useSelector((state: RootState) => state.branches);
  const branchPermissionsList = useSelector((state: RootState) => state.users.currentUser?.permissions?.branch ?? {});
  const sitePermissionsList = useSelector((state: RootState) => state.users.currentUser?.permissions?.site ?? {});
  const primaryBranchPublicId = Object.keys(branchPermissionsList)[0];
  const [getBranchData] = useLazyGetBranchWithPublicIdQuery();
  const dispatch = useDispatch();
  const { isAllowedTo } = usePermission();
  const { t } = useTranslation();

  // Function to check permissions for each site
  let canSiteView = false;
  const checkSitePermissions = () => {
    if (sitePermissionsList) {
      for (const sitePublicId in sitePermissionsList) {
        if (isAllowedTo('site:view', sitePublicId, PermissionsContextType.SITE)) {
          canSiteView = true;
        }
        if (canSiteView) {
          // If permission found, stop further checking
          break;
        }
      }
    }
  };

  if (sitePermissionsList && Object.keys(sitePermissionsList).length > 0) {
    checkSitePermissions();
  } else {
    canSiteView = isAllowedTo('site:view', primaryBranchPublicId, PermissionsContextType.BRANCH);
  }

  const canSiteCreate = isAllowedTo('site:create', primaryBranchPublicId, PermissionsContextType.BRANCH);
  const canViewMyOrganizationSites = isAllowedTo('site:view', primaryBranchPublicId, PermissionsContextType.BRANCH);

  const filteredMySites = useMemo(() => {
    if (typeof myOrganizationSites !== 'object') return {};

    return Object.keys(myOrganizationSites).reduce((acc: Site[] | any, siteId) => {
      if (sitePermissionsList?.[siteId] !== undefined) {
        acc[siteId] = myOrganizationSites[siteId];
      }
      return acc;
    }, {});
  }, [myOrganizationSites, sitePermissionsList]);

  useEffect(() => {
    if (branchData.currentBranch === null && Object.keys(branchData.Branches).length > 0) {
      getBranchData(primaryBranchPublicId);
    }
    // Reset redux data to ensure we don't show the old site's state information
    dispatch(clearSiteData());
    dispatch(resetGatewayState());
    dispatch(clearDevices());
  }, [branchData.currentBranch, branchData.Branches, getBranchData, primaryBranchPublicId]);

  return (
    <Box>
      {!canSiteView && (
        <Card>
          <Alert severity="warning">{t('Remote_Management_Missing_View_Site_Permissions_Title')}</Alert>
          <CardContent>
            <Typography variant="body2">{t('Remote_Management_Missing_View_Site_Permissions_Body1')}</Typography>
            <Typography variant="body2">{t('Remote_Management_Missing_View_Site_Permissions_Body2')}</Typography>
            <Typography variant="body2">{t('Remote_Management_Missing_View_Site_Permissions_Body3')}</Typography>
          </CardContent>
        </Card>
      )}

      <Grid container spacing={4}>
        {canSiteCreate && (
          <Grid item xs={6}>
            <CreateSite />
          </Grid>
        )}
        {canSiteView && (
          <Grid item xs={12}>
            <Sites
              isFetching={isFetching}
              mySites={filteredMySites}
              myOrganizationSites={canViewMyOrganizationSites ? myOrganizationSites : []}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default RemoteManagement;
