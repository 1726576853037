/**
 * Call Setting _ have 7 Master Station tabs.
 *   (Address Book, Call Destination, Call Origination, Incoming Call, Guard Button, Speed Dial, Monitoring).
 * user can navigate through each tab setting for each device (Audio, video Station)
 */
import TabsContainer from 'shared/components/containers/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { useSelector } from 'react-redux';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { Box } from '@mui/material';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';
import { getString } from 'shared/utils/LocalizationUtils';

const CallSettings = () => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  const loading = getString('Error_Loading_Device');
  if (!selectedDevice) return <Box>{loading}</Box>;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);

  // Tabs available on all Pages.
  let callSettingsTabs: DeviceTabType[] = [];

  switch (deviceCategory) {
    case DeviceCategory.MasterStation:
    case DeviceCategory.GuardStation:
      callSettingsTabs = [DeviceTabType.CallSettings_OutboundCallSetting];
      break;
    case DeviceCategory.TenantStation:
      callSettingsTabs = [DeviceTabType.CallSettings_OutboundCallSetting];
      break;
    case DeviceCategory.EntranceStation:
      callSettingsTabs = [DeviceTabType.CallSettings_OutboundCallSetting];
      break;
    case DeviceCategory.VideoDoorStation:
    case DeviceCategory.AudioDoorStation:
    case DeviceCategory.EmergencyStation:
    case DeviceCategory.SubMasterStation:
      callSettingsTabs = [DeviceTabType.CallSettings_CallDestination, DeviceTabType.CallSettings_OutboundCallSetting];
      break;
  }

  return <TabsContainer tabTypes={callSettingsTabs} />;
};

export default CallSettings;
