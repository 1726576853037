import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import EditUserDialog from './Dialogs/EditUserDialog';
import { CloudUser } from 'store/slices/usersSlice';
import { useGetCompaniesQuery } from 'services/aiphoneCloud';
import { Add } from '@mui/icons-material';
import InviteUserDialog from './Dialogs/InviteUserDialog';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import EditIcon from '@mui/icons-material/Edit';
import LockResetIcon from '@mui/icons-material/LockReset';
import ResetPasswordDialog from './Dialogs/ResetPasswordDialog';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useGetUsers from 'hooks/useGetUsers';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { UserType } from 'shared/utils/UserUtils';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteAccountDialog from 'shared/components/dialogs/DeleteAccountDialog';

interface IGetUsersResponse {
  userList: { [key: string]: CloudUser };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalUsers: number;
}

const UsersDataGrid = () => {
  const defaultPageSize = 50;
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<CloudUser>();
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [usersList, setUsersList] = useState<{ [key: string]: CloudUser }>({});
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [firstPageLoaded, setFirstPageLoaded] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const isBranchLoading = useSelector((state: RootState) => state.branches.Loading);
  const { isAllowedTo } = usePermission();
  const currentUser = useSelector((state: RootState) => state.users.currentUser);

  // Safeguard against undefined or null values
  const branchPermissions = currentUser?.permissions?.branch || {};
  const firstBranchKey = Object.keys(branchPermissions)[0] || '';
  const firstBranchPublicId = branchPermissions[firstBranchKey]?.roleList?.[0]?.publicId || '';

  const canViewUsersGlobal = isAllowedTo('user:view', null, PermissionsContextType.GLOBAL);

  //const canViewUsers = canViewUsersGlobal || isAllowedTo('user:view', currentBranch?.publicId || firstBranchPublicId, PermissionsContextType.BRANCH);

  const canCreateUsers =
    isAllowedTo('user:create', null, PermissionsContextType.GLOBAL) ||
    isAllowedTo('user:create', currentBranch?.publicId || firstBranchPublicId, PermissionsContextType.BRANCH);

  const canDeleteUsers =
    isAllowedTo('user:delete', null, PermissionsContextType.GLOBAL) ||
    isAllowedTo('user:delete', currentBranch?.publicId || firstBranchPublicId, PermissionsContextType.BRANCH);

  const canUpdateUsers =
    isAllowedTo('user:update', null, PermissionsContextType.GLOBAL) ||
    isAllowedTo('user:update', currentBranch?.publicId || firstBranchPublicId, PermissionsContextType.BRANCH);

  const { t } = useTranslation();

  const branchData = useSelector((state: RootState) => state.branches);
  let branchName = '';
  let companyName = '';

  if (branchData.currentBranch) {
    branchName = branchData.currentBranch?.branchName || '';
    companyName = branchData.currentBranch?.company?.name || '';
  }

  /* Fetch companies */
  const { data: companies } = useGetCompaniesQuery(
    {
      qty: '-1',
      page: '0'
    },
    { skip: !canViewUsersGlobal }
  );
  const companyList = companies ? companies.companyList : {};

  // Fetch users
  const { data, error, refetch } = useGetUsers(
    currentPage,
    pageSize,
    canViewUsersGlobal ? UserType.AIPHONE_ROLE : UserType.NO_AIPHONE_ROLE,
    branchData.currentBranch?.publicId
  );
  const usersData: IGetUsersResponse = data as IGetUsersResponse;

  /* HOOK Components */
  useEffect(() => {
    if ((usersData || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (usersData?.userList) {
      setUsersList({ ...usersList, ...usersData.userList });
      if (currentPage === 0) {
        setFirstPageLoaded(true);
      }
    }
    if (usersData) {
      const nextPageAvailable: boolean = usersData.pageNumber < usersData.totalPages;
      if (nextPageAvailable) {
        setCurrentPage(currentPage + 1);
      }
    }
  }, [usersData]);

  useEffect(() => {
    setRows(computeRows(usersList));
  }, [usersList]);

  useEffect(() => {
    if (error) {
      try {
        const errorData = JSON.parse((error as { data: string }).data);
        const errorCode = errorData.errorCode;
        if (errorCode === 'A0001') {
          setErrorMessage(t('Error_Unauthorized_To_Fetch_User'));
        } else {
          setErrorMessage(t('Error_Failed_To_Fetch_User'));
        }
      } catch (e) {
        setErrorMessage(t('Error_Unknown'));
      }
      setIsError(true);
    }
  }, [error]);

  useEffect(() => {
    setCurrentPage(0);
    refetch();
    setUsersList(usersData?.userList || {});
  }, [currentBranch]);

  /* Prep content for DataGrid */
  const computeRows = (users: { [key: string]: CloudUser }): GridRowsProp => {
    if (Object.keys(users).length === 0) {
      return [];
    }
    return Object.entries(users).map(([key, value]: [string, CloudUser]) => {
      const branchPermissions = value.permissions?.branch || {};
      const firstBranchKey = Object.keys(branchPermissions)[0] || '';
      const roleList = branchPermissions[firstBranchKey]?.roleList;

      return {
        id: key,
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email,
        role: roleList && roleList.length > 0 ? roleList[0].roleName : 'No Role Assigned',
        companyName: branchPermissions[firstBranchKey]?.companyName || 'N/A',
        branchName: branchPermissions[firstBranchKey]?.branchName || 'N/A'
      };
    });
  };

  const computeColumns = (): GridColDef[] => {
    return [
      { field: 'companyName', type: 'string', headerName: 'Company Name', flex: 4 },
      { field: 'branchName', type: 'string', headerName: 'Branch Name', flex: 4 },
      { field: 'firstName', type: 'string', headerName: 'First Name', flex: 2 },
      { field: 'lastName', type: 'string', headerName: 'Last Name', flex: 2 },
      { field: 'role', type: 'string', headerName: 'Role', flex: 2 },
      { field: 'email', type: 'string', headerName: 'Email', flex: 4 }
    ];
  };

  const UsersToolBar = () => {
    return (
      <GridToolbarContainer>
        {(canCreateUsers || canUpdateUsers) && (
          <Box sx={styles.w100}>
            <Button onClick={() => setIsInviteUserDialogOpen(true)} disabled={!firstPageLoaded} startIcon={<Add />}>
              {t('Invite_User')}
            </Button>
            {/* TODO: Implement hard delete user
            {canDeleteUsers && (
              <Button
                onClick={() => setIsDeleteUserDialogOpen(true)}
                disabled={isFetching || selectionModel.length !== 1}
                startIcon={<DeleteForeverIcon />}
              >
                Delete User
              </Button>
            )} */}
          </Box>
        )}
      </GridToolbarContainer>
    );
  };

  const handleEditUser = (userId: string) => {
    // Prevent the current user from editing themselves
    if (currentUser?.publicId !== userId) {
      const user = usersList[userId];
      if (user) {
        setSelectedUser(user);
        setIsEditUserDialogOpen(true);
      } else {
        setErrorMessage(t('Error_User_Not_Found'));
        setIsError(true);
      }
    }
  };

  const columns: GridColDef[] = computeColumns();
  if (canUpdateUsers || canDeleteUsers) {
    columns.push({
      field: 'actions',
      type: 'actions',
      flex: 1,
      getActions: (params) => {
        // Prevent showing actions for the current user
        if (currentUser?.publicId === params.row.id) return [];
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit User"
            showInMenu
            onClick={() => handleEditUser(params.row.id)}
          />,
          <GridActionsCellItem
            icon={<LockResetIcon />}
            label="Reset Password"
            showInMenu
            onClick={() => {
              setIsResetPasswordDialogOpen(true);
              setSelectedUser(usersList[params.row.id]);
            }}
          />
        ];
      }
    });
  }

  return (
    <>
      <Box sx={styles.w100}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={!firstPageLoaded || isBranchLoading}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          slots={{ toolbar: UsersToolBar, moreActionsIcon: SettingsIcon }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          pagination
          autoHeight
        />
      </Box>
      <InviteUserDialog
        isOpen={isInviteUserDialogOpen}
        setIsOpen={setIsInviteUserDialogOpen}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        companies={companyList}
        adminBranchName={branchName}
        adminBranchId={branchData.currentBranch?.publicId}
        adminCompanyName={companyName}
      />
      <DeleteAccountDialog
        isOpen={isDeleteUserDialogOpen}
        onClose={() => setIsDeleteUserDialogOpen(false)}
        selectionModel={selectionModel}
      />
      <EditUserDialog
        isOpen={isEditUserDialogOpen}
        setIsOpen={setIsEditUserDialogOpen}
        selectedUser={selectedUser}
        setErrorMessage={setErrorMessage}
        setSuccessMessage={setSuccessMessage}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <ResetPasswordDialog
        isOpen={isResetPasswordDialogOpen}
        setIsOpen={setIsResetPasswordDialogOpen}
        selectedUser={selectedUser?.publicId}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
        resetUserType="Branch"
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default UsersDataGrid;
