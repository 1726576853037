import React from 'react';
import { GridCellModes, GridCellModesModel, GridCellParams, GridRowParams, MuiEvent } from '@mui/x-data-grid';

import { IDeviceContactOutput } from 'store/slices/devicesSlice';
import { AlertType } from 'shared/components/alerts/SnackbarAlert';

/**
 * Represents the type of time selector, which can be in milliseconds ('ms') or seconds ('s').
 *
 * - 'ms': Represents the time in milliseconds.
 * - 's': Represents the time in seconds.
 */
export type TimeSelectorType = 'ms' | 's';

/**
 * Handles changes to the cell modes model in a grid.
 *
 * @param {GridCellModesModel} newModel - The new model representing the cell modes.
 * @param {function} setCellModesModel - The callback function to update the cell modes model.
 * @returns {void}
 */
export const handleCellModesModelChange = (
  newModel: GridCellModesModel,
  setCellModesModel: (newModel: GridCellModesModel) => void
): void => {
  setCellModesModel(newModel);
};

/**
 * Forces the edit cell mode to view after the action. This should prevent the user from having to click "SAVE CHANGES" twice.
 */
export const handleCellClickToView = (
  params: GridCellParams,
  event: React.MouseEvent,
  setCellModesModel: React.Dispatch<React.SetStateAction<GridCellModesModel>>
) => {
  // Ignore portal
  if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
    return;
  }

  // Prevent click event on Stations cell
  if (params.field === 'Stations') {
    // Stop the selection of the cell
    event.stopPropagation();

    return;
  }

  // Set the cell edit mode to View for all of these cells.
  setCellModesModel((prevModel) => {
    return {
      // Revert the mode of the other cells from other rows
      ...Object.keys(prevModel).reduce(
        (acc, id) => ({
          ...acc,
          [id]: Object.keys(prevModel[id]).reduce(
            (acc2, field) => ({
              ...acc2,
              [field]: { mode: GridCellModes.View }
            }),
            {}
          )
        }),
        {}
      ),
      [params.id]: {
        // Revert the mode of other cells in the same row
        ...Object.keys(prevModel[params.id] || {}).reduce(
          (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
          {}
        ),
        // Here, we set the mode of the clicked cell to view
        [params.field]: { mode: GridCellModes.View }
      }
    };
  });
};

/**
 * Handles the click event for a row in a grid component.
 *
 * @param {GridRowParams} params - The parameters for the grid row that was clicked.
 * @param {MuiEvent<MouseEvent>} event - The mouse event triggered by the row click.
 *
 * Stops the propagation of the event if the row is disabled.
 */
export const handleRowClick = (params: GridRowParams, event: MuiEvent<MouseEvent>) => {
  if (params.row.disabled) {
    event.stopPropagation();
  }
};

/**
 * Represents the default configuration for a relay device.
 *
 * @typedef {Object} IDeviceContactOutput
 * @property {number} timer - The timer value for the relay in seconds.
 * @property {string} publicId - A unique identifier for the relay.
 * @property {boolean} optionRelay - Indicates whether the option relay setting is enabled or disabled.
 * @property {Object} callSchedule - The schedule for relay activation,
 *  organized by days of the week and individual schedules.
 * @property {Array} callSchedule.friday - Activation schedule for Friday.
 * @property {Array} callSchedule.monday - Activation schedule for Monday.
 * @property {Array} callSchedule.sunday - Activation schedule for Sunday.
 * @property {Array} callSchedule.tuesday - Activation schedule for Tuesday.
 * @property {Array} callSchedule.saturday - Activation schedule for Saturday.
 * @property {Array} callSchedule.thursday - Activation schedule for Thursday.
 * @property {Array} callSchedule.wednesday - Activation schedule for Wednesday.
 * @property {Array} callSchedule.individual - Activation schedule for individual, non-day-specific timings.
 * @property {number} outputNumber - The output number corresponding to this relay.
 * @property {string} devicePublicId - A unique identifier for the parent device associated with this relay.
 * @property {number} outputFunction - A numeric value representing the function or mode of the relay output.
 *
 * @type {IDeviceContactOutput}
 */
export const defaultRelay: IDeviceContactOutput = {
  timer: 5000, // Updating the default to 5 seconds
  publicId: '',
  optionRelay: false,
  callSchedule: {
    friday: [],
    monday: [],
    sunday: [],
    tuesday: [],
    saturday: [],
    thursday: [],
    wednesday: [],
    individual: []
  },
  outputNumber: 0,
  devicePublicId: '',
  outputFunction: 0
};

export const DEFAULT_RELAYS: IDeviceContactOutput[] = Array(20).fill(defaultRelay);

/**
 * Represents an alert to be displayed for a parent.
 *
 * This interface defines the structure of a parent alert, including
 * its type, duration, content, and its current state (open or closed).
 *
 * Properties:
 * - `type`: The type/category of the alert (e.g., informational, warning, error).
 * - `duration`: The duration, in milliseconds, for which the alert should be displayed.
 * - `text`: The content or message to be displayed within the alert.
 * - `isOpen`: A boolean indicating whether the alert is currently active and displayed.
 */
export interface ParentAlert {
  type: AlertType;
  duration: number;
  text: string;
  isOpen: boolean;
}

export const defaultContactOutputState = {
  contactOutput1Enabled: false,
  contactOutput2Enabled: false,
  contactOutput3Enabled: false,
  contactOutput4Enabled: false,
  contactOutput5Enabled: false,
  contactOutput6Enabled: false,
  contactOutput7Enabled: false,
  contactOutput8Enabled: false,
  contactOutput9Enabled: false,
  contactOutput10Enabled: false,
  contactOutput11Enabled: false,
  contactOutput12Enabled: false,
  contactOutput13Enabled: false,
  contactOutput14Enabled: false,
  contactOutput15Enabled: false,
  contactOutput16Enabled: false,
  contactOutput17Enabled: false,
  contactOutput18Enabled: false,
  contactOutput19Enabled: false,
  contactOutput20Enabled: false
};

export const basicContactOutputs = [
  'contactOutput1Enabled',
  'contactOutput2Enabled',
  'contactOutput3Enabled',
  'contactOutput4Enabled',
  'contactOutput5Enabled',
  'contactOutput6Enabled',
  'contactOutput7Enabled',
  'contactOutput8Enabled',
  'contactOutput9Enabled',
  'contactOutput10Enabled',
  'contactOutput11Enabled',
  'contactOutput12Enabled',
  'contactOutput13Enabled',
  'contactOutput14Enabled',
  'contactOutput15Enabled',
  'contactOutput16Enabled',
  'contactOutput17Enabled',
  'contactOutput18Enabled',
  'contactOutput19Enabled',
  'contactOutput20Enabled'
];
