/**
 * This file generate the payload that sent to gateway via MQTT for the following commands:
 * 1. Register Gateway
 * 2. Unregister Gateway
 * 3. Search Stations
 * 4. Associate DHCP
 * 5. Associate Static
 * 6. Sync Device
 *
 * @param payload The payload containing the command to be sent.
 * @returns A Promise that resolves with the response from the IXG-GW.
 */

import getDeviceType from 'shared/getDeviceType';
import { gwCommand } from 'shared/rmGateway/gwCommand';

interface Payload {
  command_id: string;
  site_id: string;
  transaction_id: string;
  mac_addr: string;
  id_enc: string;
  pw_enc: string;
  payload:
    | AssociatePayload
    | syncPayload
    | genericPayload
    | null
    | FirmwareUpdatePayload
    | deviceStatusPayload
    | soundUploadPayload;
  customSoundList?: Array<{
    id: string;
    s3Location: string;
    soundFileName: string;
  }>;
}
interface genericPayload {
  [key: string]: string;
}
interface AssociatePayload {
  [key: string]: {
    ip_ver: string;
    ip_addr: string;
    ip_subnet: string;
    ip_gateway: string;
    station_name: string;
    timeout_sec: string;
  };
}
interface syncPayload {
  [key: string]: {
    mac_addr: string;
    station_number: string;
    id_enc: string;
    pw_enc: string;
    config_link: string;
    station_type: string;
    config_file_name: string;
  };
}
interface FirmwareLinkPayload {
  [key: string]: {
    firmware_link: string;
    firmware_file_name: string;
  };
}

interface FirmwarePayload {
  [key: string]: {
    mac_addr: string;
    station_type: string;
    firmware_file_name: string;
    id_enc: string;
    pw_enc: string;
  };
}

interface FirmwareUpdatePayload {
  [key: string]: FirmwarePayload | FirmwareLinkPayload;
}

interface InitializePayload {
  [key: string]: {
    mac_addr: string;
    id_enc: string;
    pw_enc: string;
    station_type: string;
    classification: string;
  };
}

interface deviceStatusPayload {
  [key: string]: object;
}

interface soundUploadFormat {
  [key: string]: {
    sound_link: string;
    sound_file_name: string;
  };
}

interface soundUploadDevicePayload {
  [key: string]: {
    mac_addr: string;
    station_type: string;
    config_link: string;
    config_file_name: string;
    id_enc: string;
    pw_enc: string;
  };
}

interface soundUploadPayload {
  [key: string]: soundUploadDevicePayload | soundUploadFormat;
}

export interface IDeviceInfoPayload {
  deviceMacAddress: string;
  deviceIpAddress: string;
  deviceStationNumber: string;
  deviceType: string;
  deviceFileName: string;
  deviceConfigFileUrl: string;
  deviceFirmwareFileName?: string;
  deviceFirmwareLink?: string;
  deviceS3Url?: string;
  deviceId: string;
  devicePassword: string;
  deviceName?: string;
  deviceIpV4Address?: string;
  deviceSubnetMask?: string;
  deviceIpV4DefaultGateway?: string;
  deviceSoundId?: string;
  soundLink?: string;
  soundFileName?: string;
  customSoundList?: Array<{
    id: string;
    s3Location: string;
    soundFileName: string;
  }>;
}

export interface IGatewayInfoPayload {
  awsPropertyId: string;
  gwMacAddress: string;
  gwId: string;
  gwPassword: string;
  gwIpAddress?: string;
}

// Check if the payload for null value before sending to gateway
const isEmptyPayload = (payload: Payload): boolean => {
  const checkBasicPayload = (payload: Payload) => {
    if (!payload) return true;
    return (
      isEmpty(payload.command_id) ||
      isEmpty(payload.site_id) ||
      isEmpty(payload.transaction_id) ||
      isEmpty(payload.mac_addr) ||
      isEmpty(payload.id_enc) ||
      isEmpty(payload.pw_enc)
    );
  };

  const checkAssociatePayload = (devicePayload: AssociatePayload) => {
    const macAddress = Object.keys(devicePayload)[0];
    if (!macAddress) {
      return true;
    }
    return (
      isEmpty(devicePayload[macAddress].ip_addr) ||
      isEmpty(devicePayload[macAddress].ip_subnet) ||
      isEmpty(devicePayload[macAddress].ip_ver) ||
      isEmpty(devicePayload[macAddress].station_name)
    );
  };

  const checkSyncPayload = (devicePayload: syncPayload) => {
    // check if the object keys are not empty
    const ipAddress = Object.keys(devicePayload)[0];
    if (!ipAddress || ipAddress === '0.0.0.0') {
      return true;
    }
    if (Object.keys(devicePayload).length > 0) {
      return (
        isEmpty(devicePayload[ipAddress].mac_addr) ||
        isEmpty(devicePayload[ipAddress].station_number) ||
        isEmpty(devicePayload[ipAddress].id_enc) ||
        isEmpty(devicePayload[ipAddress].pw_enc) ||
        isEmpty(devicePayload[ipAddress].config_link) ||
        isEmpty(devicePayload[ipAddress].station_type) ||
        isEmpty(devicePayload[ipAddress].config_file_name)
      );
    }
    return false;
  };

  const checkFirmwareUpdatePayload = (devicePayload: FirmwareUpdatePayload) => {
    const ipAddress = Object.keys(devicePayload)[0];
    const deviceType = Object.keys(devicePayload)[1];
    if (!ipAddress || !deviceType || ipAddress === '0.0.0.0') {
      return true;
    }

    return (
      isEmpty(devicePayload[ipAddress].mac_addr) ||
      isEmpty(devicePayload[ipAddress].station_type) ||
      isEmpty(devicePayload[ipAddress].firmware_file_name) ||
      isEmpty(devicePayload[ipAddress].id_enc) ||
      isEmpty(devicePayload[ipAddress].pw_enc) ||
      isEmpty(devicePayload[deviceType].firmware_link) ||
      isEmpty(devicePayload[deviceType].firmware_file_name)
    );
  };

  const checkInitializePayload = (devicePayload: InitializePayload) => {
    const ipAddress = Object.keys(devicePayload)[0];
    if (!ipAddress || ipAddress === '0.0.0.0') {
      return true;
    }

    return (
      isEmpty(devicePayload[ipAddress].mac_addr) ||
      isEmpty(devicePayload[ipAddress].id_enc) ||
      isEmpty(devicePayload[ipAddress].pw_enc) ||
      isEmpty(devicePayload[ipAddress].station_type) ||
      isEmpty(devicePayload[ipAddress].classification)
    );
  };

  const checkDeviceStatusPayload = (devicePayload: deviceStatusPayload) => {
    const ipAddress = Object.keys(devicePayload)[0];
    if (!ipAddress) {
      return true;
    }
    return false;
  };

  const isEmpty = (value: string) => {
    return value === '' || value === null || value === undefined || value.length === 0;
  };

  switch (payload.command_id) {
    case gwCommand.REGISTER:
    case gwCommand.UNREGISTER:
      return checkBasicPayload(payload);
    case gwCommand.ASSOCIATE: {
      const emptyBasicPayloadCheck = checkBasicPayload(payload);
      const emptyDevicePayloadCheck = checkAssociatePayload(payload.payload as AssociatePayload);
      if (emptyBasicPayloadCheck || emptyDevicePayloadCheck) {
        return true;
      }
      return false;
    }
    case gwCommand.SYNC: {
      const emptyBasicPayloadCheck = checkBasicPayload(payload);
      const emptyDevicePayloadCheck = checkSyncPayload(payload.payload as syncPayload);
      if (emptyBasicPayloadCheck || emptyDevicePayloadCheck) {
        return true;
      }
      return false;
    }
    case gwCommand.FIRMWARE_UPDATE: {
      const emptyBasicPayloadCheck = checkBasicPayload(payload);
      const emptyDevicePayloadCheck = checkFirmwareUpdatePayload(payload.payload as FirmwareUpdatePayload);
      if (emptyBasicPayloadCheck || emptyDevicePayloadCheck) {
        return true;
      }
      return false;
    }
    case gwCommand.DEVICE_CHECK: {
      const emptyBasicPayloadCheck = checkBasicPayload(payload);
      const emptyDevicePayloadCheck = checkDeviceStatusPayload(payload.payload as deviceStatusPayload);
      if (emptyBasicPayloadCheck || emptyDevicePayloadCheck) {
        return true;
      }
      return false;
    }
    case gwCommand.INITIALIZE: {
      const emptyBasicPayloadCheck = checkBasicPayload(payload);
      const emptyDevicePayloadCheck = checkInitializePayload(payload.payload as InitializePayload);
      if (emptyBasicPayloadCheck || emptyDevicePayloadCheck) {
        return true;
      }
      return false;
    }
    case gwCommand.PING: {
      const emptyBasicPayloadCheck = checkBasicPayload(payload);
      const emptyDevicePayloadCheck = checkDeviceStatusPayload(payload.payload as deviceStatusPayload);
      if (emptyBasicPayloadCheck || emptyDevicePayloadCheck) {
        return true;
      }
      return false;
    }
    case gwCommand.POWER_CYCLE: {
      return false;
    }
    case gwCommand.SOUND_UPLOAD: {
      const emptyBasicPayloadCheck = checkBasicPayload(payload);
      const emptyDevicePayloadCheck = checkDeviceStatusPayload(payload.payload as deviceStatusPayload);
      if (emptyBasicPayloadCheck || emptyDevicePayloadCheck) {
        return true;
      }
      return false;
    }
    default:
      return false;
  }
};

// generate the payload format for the batch DHCP payload
// https://github.com/AiphoneCorporation/ixgw_gw_remote_management/blob/main/doc/99_MQTT_COMMUNICATION_EXAMPLES.md#cid_associate
// DHCP automatically assigns IP addresses to devices on the network
const processDhcpPayload = (devices: Payload) => {
  return Object.entries(devices).reduce((acc, [_, value]) => {
    const macAddress = value.basicInfo.macAddress;
    const stationName = value.basicInfo.stationName;
    if (value.basicInfo.deviceType === 18) {
      return acc;
    }
    acc[macAddress] = {
      ip_ver: '2',
      ip_addr: '192.168.1.160',
      ip_subnet: '255.255.255.0',
      ip_gateway: '192.168.1.1',
      station_name: stationName,
      timeout_sec: '20'
    };
    return acc;
  }, {} as AssociatePayload);
};

// generate the payload format for the ONE  payload
const processStaticPayload = (device: Payload) => {
  return {
    [device.deviceMacAddress]: {
      ip_ver: '0',
      ip_addr: device.deviceIpV4Address,
      ip_subnet: device.deviceSubnetMask,
      ip_gateway: device.deviceIpV4DefaultGateway,
      station_name: device.deviceName,
      timeout_sec: '5'
    }
  };
};

const processGatewayCommandPayload = (
  gatewayCommand: string,
  gatewayInfo: IGatewayInfoPayload,
  devicesInfo: IDeviceInfoPayload,
  associateType: string | null
) => {
  let gwPayload;

  const awsPropertyId = gatewayInfo?.awsPropertyId;
  const gwMacAddress = gatewayInfo?.gwMacAddress;
  const gwId = gatewayInfo?.gwId;
  const gwPassword = gatewayInfo?.gwPassword;

  switch (gatewayCommand) {
    case gwCommand.REGISTER: {
      const payload: Payload = {
        command_id: gwCommand.REGISTER,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-register-result-${gwMacAddress}-${gwCommand.REGISTER_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {}
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }
    case gwCommand.UNREGISTER: {
      const payload: Payload = {
        command_id: gwCommand.UNREGISTER,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-unregister-result-${gwMacAddress}-${gwCommand.UNREGISTER_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {}
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }
    case gwCommand.STATION_SEARCH: {
      const payload: Payload = {
        command_id: gwCommand.STATION_SEARCH,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-search-result-${gwMacAddress}-${gwCommand.STATION_SEARCH_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {
          timeout_sec: '5'
        }
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }
    case gwCommand.ASSOCIATE: {
      let formattedPayload, transactionId;

      if (associateType === 'dhcp') {
        formattedPayload = processDhcpPayload(devicesInfo);
        transactionId = `${awsPropertyId}-associate-dhcp-result-${gwMacAddress}-${gwCommand.ASSOCIATE_RESP}`;
      } else {
        // NOTE: use device's MAC ADDRESS in transaction ID
        formattedPayload = processStaticPayload(devicesInfo);
        transactionId = `${awsPropertyId}-associate-static-result-${devicesInfo.deviceMacAddress}-${gwCommand.ASSOCIATE_RESP}`;
      }

      const payload: Payload = {
        command_id: gwCommand.ASSOCIATE,
        site_id: awsPropertyId,
        transaction_id: transactionId,
        mac_addr: gwMacAddress ?? '',
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: formattedPayload
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }
    // If the device is new, then sync the device with the gateway
    // NOTE: use device's MAC ADDRESS in transaction ID
    case gwCommand.SYNC: {
      const configLink = devicesInfo.deviceConfigFileUrl;
      const deviceIpV4Address = devicesInfo.deviceIpAddress;
      const deviceMacAddress = devicesInfo.deviceMacAddress;
      // const configUrl = devicesInfo.deviceS3Url;

      const payload: Payload = {
        command_id: gwCommand.SYNC,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-sync-result-${deviceMacAddress}-${gwCommand.SYNC_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {
          [deviceIpV4Address]: {
            mac_addr: deviceMacAddress,
            station_number: devicesInfo.deviceStationNumber,
            id_enc: btoa(devicesInfo.deviceId || 'admin'),
            pw_enc: btoa(devicesInfo.devicePassword || 'admin'),
            config_link: configLink,
            station_type: devicesInfo.deviceType,
            config_file_name: devicesInfo.deviceFileName
          }
        }
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }
    case gwCommand.FIRMWARE_UPDATE: {
      const deviceIpV4Address = devicesInfo.deviceIpAddress;
      const deviceType = devicesInfo.deviceType;
      const deviceMacAddress = devicesInfo.deviceMacAddress;

      // convert deviceType to string to match the key in the payload
      let deviceModel = getDeviceType(deviceType);
      if (deviceModel === 'IX-DV*') {
        deviceModel = 'IX-DV';
      } else if (deviceModel === 'IX-RS-*') {
        deviceModel = 'IX-RS';
      } else if (deviceModel === 'IX-MV7-*') {
        deviceModel = 'IX-MV7';
      }

      const payload: Payload = {
        command_id: gwCommand.FIRMWARE_UPDATE,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-firmware-update-result-${deviceMacAddress}-${gwCommand.FIRMWARE_UPDATE_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {
          [deviceIpV4Address]: {
            mac_addr: devicesInfo.deviceMacAddress,
            station_type: deviceModel,
            firmware_file_name: devicesInfo.deviceFirmwareFileName,
            id_enc: btoa(devicesInfo.deviceId || 'admin'),
            pw_enc: btoa(devicesInfo.devicePassword || 'admin')
          },
          [deviceModel]: {
            firmware_link: devicesInfo.deviceFirmwareLink,
            firmware_file_name: devicesInfo.deviceFirmwareFileName
          }
        }
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }
    // Currently, the following command is only used for gateway credential validation
    case gwCommand.DEVICE_CHECK: {
      const payload: Payload = {
        command_id: gwCommand.DEVICE_CHECK,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-device-status-result-${devicesInfo.deviceMacAddress}-${gwCommand.DEVICE_CHECK_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {
          [devicesInfo.deviceIpAddress]: {}
        }
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }

    case gwCommand.INITIALIZE: {
      const deviceIpV4Address = devicesInfo.deviceIpAddress;
      const deviceMacAddress = devicesInfo.deviceMacAddress;
      const payload: Payload = {
        command_id: gwCommand.INITIALIZE,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-initialize-result-${deviceMacAddress}-${gwCommand.INITIALIZE_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {
          [deviceIpV4Address]: {
            mac_addr: deviceMacAddress,
            id_enc: btoa(devicesInfo.deviceId || 'admin'),
            pw_enc: btoa(devicesInfo.devicePassword || 'admin'),
            station_type: devicesInfo.deviceType,
            classification: '0'
          }
        }
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }

    case gwCommand.POWER_CYCLE: {
      const deviceMacAddress = devicesInfo.deviceMacAddress;
      const payload: Payload = {
        command_id: gwCommand.POWER_CYCLE,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-power-cycle-result-${deviceMacAddress}-${gwCommand.POWER_CYCLE_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {}
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }

    case gwCommand.PING: {
      const payload: Payload = {
        command_id: gwCommand.PING,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-ping-result-${gwMacAddress}-${gwCommand.PING_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {
          [devicesInfo.deviceIpAddress]: {}
        }
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }

    case gwCommand.SOUND_UPLOAD: {
      const soundsArray = devicesInfo.customSoundList;
      let soundUploadPayload = {};
      if (soundsArray.length > 0) {
        soundUploadPayload = soundsArray.reduce((acc, devicesInfo) => {
          const key = (devicesInfo.id ?? '').toString().padStart(3, '0');
          acc[key] = {
            sound_link: devicesInfo.s3Location,
            sound_file_name: devicesInfo.soundFileName
          };
          return acc;
        }, {});
      }
      const payload: Payload = {
        command_id: gwCommand.SOUND_UPLOAD,
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-sound-upload-result-${devicesInfo.deviceMacAddress}-${gwCommand.SOUND_UPLOAD_RESP}`,
        mac_addr: gwMacAddress,
        id_enc: btoa(gwId || 'admin'),
        pw_enc: btoa(gwPassword || 'admin'),
        payload: {
          [devicesInfo.deviceIpAddress]: {
            mac_addr: devicesInfo.deviceMacAddress,
            station_type: devicesInfo.deviceType,
            config_file_name: devicesInfo.deviceFileName,
            config_link: devicesInfo.deviceConfigFileUrl,
            id_enc: btoa(devicesInfo.deviceId || 'admin'),
            pw_enc: btoa(devicesInfo.devicePassword || 'admin')
          },
          ...soundUploadPayload
        }
      };
      isEmptyPayload(payload) ? (gwPayload = 'Missing information') : (gwPayload = payload);
      break;
    }

    default:
      return 'no command found';
  }
  return gwPayload;
};

const processFetchResultPayload = (gatewayCommand: string, gatewayInfo, devicesInfo, associateType: string | null) => {
  const awsPropertyId = gatewayInfo?.awsPropertyId;
  const gwMacAddress = gatewayInfo?.gwMacAddress;
  let fetchPayload;

  switch (gatewayCommand) {
    case gwCommand.REGISTER: {
      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-register-result-${gwMacAddress}-${gwCommand.REGISTER_RESP}`
      };
      break;
    }
    case gwCommand.UNREGISTER: {
      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-unregister-result-${gwMacAddress}-${gwCommand.UNREGISTER_RESP}`
      };
      break;
    }
    case gwCommand.STATION_SEARCH: {
      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-search-result-${gwMacAddress}-${gwCommand.STATION_SEARCH_RESP}`
      };
      break;
    }
    case gwCommand.ASSOCIATE: {
      let transactionId;

      if (associateType === 'dhcp') {
        transactionId = `${awsPropertyId}-associate-dhcp-result-${gwMacAddress}-${gwCommand.ASSOCIATE_RESP}`;
      } else {
        // NOTE: use device's MAC ADDRESS in transaction ID
        transactionId = `${awsPropertyId}-associate-static-result-${devicesInfo.deviceMacAddress}-${gwCommand.ASSOCIATE_RESP}`;
      }

      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: transactionId
      };
      break;
    }
    // If the device is new, then sync the device with the gateway
    case gwCommand.SYNC: {
      const transactionId = `${awsPropertyId}-sync-result-${devicesInfo.deviceMacAddress}-${gwCommand.SYNC_RESP}`;

      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: transactionId
      };
      break;
    }
    case gwCommand.FIRMWARE_UPDATE: {
      const transactionId = `${awsPropertyId}-firmware-update-result-${devicesInfo.deviceMacAddress}-${gwCommand.FIRMWARE_UPDATE_RESP}`;

      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: transactionId
      };
      break;
    }

    // Currently, the following command is only used for gateway credential validation
    // Device check will show a list of devices with ip address and status
    case gwCommand.DEVICE_CHECK: {
      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-device-status-result-${devicesInfo.deviceMacAddress}-${gwCommand.DEVICE_CHECK_RESP}`
      };
      break;
    }

    case gwCommand.INITIALIZE: {
      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-initialize-result-${devicesInfo.deviceMacAddress}-${gwCommand.INITIALIZE_RESP}`
      };
      break;
    }

    case gwCommand.PING: {
      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-ping-result-${gwMacAddress}-${gwCommand.PING_RESP}`
      };
      break;
    }

    case gwCommand.POWER_CYCLE: {
      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: `${awsPropertyId}-power-cycle-result-${devicesInfo.deviceMacAddress}-${gwCommand.PING_RESP}`
      };
      break;
    }

    case gwCommand.SOUND_UPLOAD: {
      const transactionId = `${awsPropertyId}-sound-upload-result-${devicesInfo.deviceMacAddress}-${gwCommand.SOUND_UPLOAD_RESP}`;
      fetchPayload = {
        site_id: awsPropertyId,
        transaction_id: transactionId
      };
      break;
    }

    default:
      return 'no command found';
  }
  return fetchPayload;
};

/******
 * Generate the payload for the gateway command
 *****/

export const fetchGatewayCommand = (
  action: string,
  gatewayCommand: string,
  gatewayInfo: IGatewayInfoPayload,
  devicesInfo?: IDeviceInfoPayload,
  associateType?: string | null
) => {
  let gwPayload;
  if (action === 'sendCommand') {
    const sendPayload = processGatewayCommandPayload(gatewayCommand, gatewayInfo, devicesInfo, associateType);
    gwPayload = {
      action: 'sendCommand',
      payload: sendPayload
    };
  }
  if (action === 'fetchResult') {
    const fetchPayload = processFetchResultPayload(gatewayCommand, gatewayInfo, devicesInfo, associateType);
    gwPayload = {
      action: 'fetchResult',
      payload: fetchPayload
    };
  }
  return gwPayload;
};
