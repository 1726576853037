import React, { useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { IUnit } from 'store/slices/unitsSlice';
import AddedAppUnitsItem from './AddedAppUnitsItem';
import { useTranslation } from 'react-i18next';

interface IAppUnitsProps {
  mobileUnits: IUnit[];
  callDestinationAppUnit: { publicId: string }[];
  onSelectAppUnit: (id: string) => void;
  handleRemoveAppUnit?: (id: string) => Promise<void>;
}

const AddedAppUnits = ({
  mobileUnits,
  callDestinationAppUnit,
  onSelectAppUnit,
  handleRemoveAppUnit
}: IAppUnitsProps) => {
  const callDestinationAppUnitId = callDestinationAppUnit[0]?.publicId || '';
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [unitToDelete, setUnitToDelete] = useState<string | null>(null);

  const handleClickOpen = (unitId: string) => {
    setUnitToDelete(unitId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUnitToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (unitToDelete) {
      try {
        if (handleRemoveAppUnit) {
          await handleRemoveAppUnit(unitToDelete);
        }

        setUnitToDelete(null);
        setOpen(false);
      } catch (error) {
        console.error('Error deleting unit', error);
      }
    }
  };

  return (
    <Box sx={styles.unitsWrapper}>
      <Box sx={styles.title}>{t('Mobile_Group')}</Box>
      <Box sx={{ display: 'column', justifyContent: 'space-between' }}>
        {mobileUnits &&
          mobileUnits.length > 0 &&
          mobileUnits.map((unit) => (
            <AddedAppUnitsItem
              key={unit?.publicId}
              AppUnitId={unit?.publicId}
              isSelected={callDestinationAppUnitId === unit?.publicId}
              onSelectAppUnit={onSelectAppUnit}
              handleRemoveAppUnit={handleRemoveAppUnit ? handleClickOpen : undefined}
            />
          ))}
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Delete_Mobile_App_Group')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('Button_Cancel')}
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const styles = {
  unitsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%'
  },
  title: {
    fontSize: '18px',
    padding: '10px',
    marginLeft: '15px',
    backgroundColor: '#F0F0F0'
  }
};

export default AddedAppUnits;
