/**
 * Control panel save the screen display selection to the database
 * config file parameters
 *
 * Default Display screen: selection from 1 -5
 * **** SG_SELECT_DISPLAY_PRIORITY: display_screen_selection
 * redux key: displayScreenSelection
 * (0, 'Numeric key unlock'), - 'Door Release Keypad'
 * (1, 'Numeric key selection'), 'Call by unit Number'
 * (2, 'Full key selection'),' Call by List' ****NOT included in UI
 * (3, 'Name search selection'), - Screen available 'search By Name'
 * (4, 'Quick selection'), - Screen available 'Call By Directory'
 * (5, 'QR code authentication unlock screen'); - displayQrCodeScan, Screen available 'Door Release QR Code

 *
 * Enable/Disable screen selection:
 * **** SG_SELECT_DISPLAY_TENKEY_UNLOCK: Door Release Keypad
 * **** SG_SELECT_DISPLAY_TENKEY: Call By Unit Number
 * **** SG_SELECT_DISPLAY_NAMESEARCH: Search By Name
 * **** SG_SELECT_DISPLAY_QR_CODE_SCAN: Door Release QR Code
 *
 * Call Directory:
 * SG_QUICK_SELECTION_GROUP="14&&&0&&&1&&&01&&&004&&&&&&&&&"
 * SG_QUICK_SELECTION_GROUP="<id-unique>&&&<parent-id>&&&<0: group(folder), 1: unit>&&&<building #>&&&<unit #>&&&<group name (only for group)>&&&<group name other language>&&&<thumbnail file name>"
 */

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { IEntrancePanelSettings, updateSelectedDevice, getSelectedDevice } from 'store/slices/devicesSlice';
import { IFormData } from './EntranceDisplay';
import { useTranslation } from 'react-i18next';
interface IControlPanelProps {
  needDisplaySave: boolean;
  setNeedDisplaySave: (value: boolean) => void;
  formData: IFormData[];
  entrancePanelSettings: IEntrancePanelSettings;
}

interface IDeviceUpdatePayload {
  publicId: string;
  entrancePanelSettings?: IEntrancePanelSettings;
}

const strings = {
  title: 'Entrance Display',
  description: 'Enable or Disable features for guests or tenants to use on the Entrance Station touchscreen display.'
};

const ControlPanel = ({ needDisplaySave, setNeedDisplaySave, formData }: IControlPanelProps) => {
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const devicePublicId = useParams().deviceid ?? '';
  const selectedDevice = useSelector(getSelectedDevice);

  const { t } = useTranslation();

  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');

  const dispatch = useDispatch();

  const fetchDefaultScreenId = () => {
    const defaultScreen = formData.find((item) => item.default);
    return defaultScreen ? defaultScreen.id : null;
  };

  const getEnabledValueByReduxKey = (key: string) => {
    const item = formData.find((item) => item.reduxKey === key);
    return item ? item.enabled : false;
  };

  // TODO: Call Directory needs to be updated with the correct ID
  // currently there is no Redux value for call directory
  const updatedEntrancePanelSettings: IEntrancePanelSettings = {
    displayScreenSelection: fetchDefaultScreenId() ?? 0,
    displayTenkeyUnlock: getEnabledValueByReduxKey('displayTenkeyUnlock'),
    displayKeypad: getEnabledValueByReduxKey('displayKeypad'),
    displayNameSearch: getEnabledValueByReduxKey('displayNameSearch'),
    displayQrCodeScan: getEnabledValueByReduxKey('displayQrCodeScan'),
    displayQuickSelection: getEnabledValueByReduxKey('displayQuickSelection')
  };

  const buildPayload = () => {
    const payload: { device: IDeviceUpdatePayload } = {
      device: {
        publicId: devicePublicId,
        entrancePanelSettings: {
          ...updatedEntrancePanelSettings
        }
      }
    };
    return payload;
  };

  const handleSave = () => {
    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.entrancePanelSettings = {
      ...newDevice.entrancePanelSettings,
      ...updatedEntrancePanelSettings
    };

    const params = buildPayload();
    updateDevice(params)
      .unwrap()
      .then(() => {
        setNeedDisplaySave(false);
        dispatch(updateSelectedDevice({ device: newDevice }));
        setSuccessMessage(successUpdateDevice);
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
      });
  };

  return (
    <>
      <SnackbarAlert
        type="success"
        time={7000}
        text={`${successMessage}`}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />
      <SnackbarAlert
        type="error"
        time={7000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <Box style={styles.controlPanelWrapper}>
        <Box sx={styles.descriptionWrapper}>
          <Box sx={styles.title}>{strings.title}</Box>
          <Box sx={styles.description}> {strings.description}</Box>
        </Box>
        <Box style={styles.buttonsWrapper}>
          <LoadingButton
            variant="outlined"
            disabled={!needDisplaySave || isUpdating}
            onClick={handleSave}
            loading={isUpdating}
          >
            Save changes
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  controlPanelWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  descriptionWrapper: {
    width: '88%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'flex-end'
  }
};

export default ControlPanel;
