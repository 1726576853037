import axios, { AxiosResponse, AxiosError } from 'axios';
import { refreshToken } from '../api/Aws/authApi';
import { classifyAndThrowError } from './ApiError';
import { LoginUserParams } from './Aws/apiParamsTypes';

const axiosApiInstance = axios.create({ timeout: 50000 });

let isRefreshing = false;
let failedQueue: Array<() => void> = [];

const processQueue = (error: unknown) => {
  failedQueue.forEach(() => {
    classifyAndThrowError(error);
  });
  failedQueue = [];
};

/**
 * Interceptor to add the jwt to the authorization header for each request
 */
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = JSON.stringify({ token: localStorage.getItem('token') });
    config.headers.Authorization = accessToken;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: any) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise<string>((resolve) => {
          failedQueue.push(() => {
            originalRequest.headers['Authorization'] = `${localStorage.getItem('token')}`;
            resolve(axiosApiInstance(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;
      const refreshTokenValue = localStorage.getItem('refreshToken');

      if (!refreshTokenValue) {
        processQueue(new Error('Refresh token not found in session'));
        return Promise.reject('Refresh token not found in session');
      }
      const refreshTokenPayload: LoginUserParams = {
        userData: {
          refreshToken: refreshTokenValue
        }
      };

      return refreshToken(refreshTokenPayload)
        .then((response) => {
          if (!response) {
            // TODO: Handle this better, this is a temporary fix.
            throw new Error('Refresh token failed');
          }
          const { accessToken } = response.data;
          localStorage.setItem('token', accessToken);

          isRefreshing = false;
          originalRequest.headers['Authorization'] = `${accessToken}`;
          processQueue(null);
          return axiosApiInstance(originalRequest);
        })
        .catch((err) => {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          isRefreshing = false;
          processQueue(err);
          return Promise.reject(err);
        });
    }

    if (error instanceof AxiosError) {
      return Promise.reject(error);
    }
  }
);

export default axiosApiInstance;
