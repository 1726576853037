import { Box, Button, Tooltip } from '@mui/material';
import { Status } from 'shared/utils/Status';
import { IDevice } from 'store/slices/devicesSlice';
import UpdateIcon from '@mui/icons-material/Update';
import SyncIcon from '@mui/icons-material/Sync';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { getSite } from 'store/slices/siteSlice';
import {
  useCreateConfigFileMutation,
  useHandleGatewayCommandMutation,
  useUpdateDeviceMutation
} from 'services/aiphoneCloud';
import { useCallback } from 'react';
import { RowElem } from '../Grid/SiteGrid';
import { firmwareUrl } from '../Utils/SiteUtil';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { getGWErrorCode } from 'shared/rmGateway/gwErrorHandler';
import { useSiteContext } from '../SiteContext/SiteContext';

const NERO_COLOR = '#4a4a4a';

/**
 * Interface for the props of ToolbarComponent
 * @interface ToolbarComponentProps
 * @property {IDevice} gateway - The gateway device
 * @property {function} handleConfigFileUpload - Function to handle the config file upload
 */
export interface ToolbarComponentProps {
  gateway: IDevice;
  handleConfigFileUpload: (device: RowElem) => Promise<Status.Synced | Status.Error | undefined>;
}

/**
 * Toolbar component for the site info page
 * @param {ToolbarComponentProps} props - The props of the component
 * @returns {JSX.Element}
 */
const ToolbarComponent = ({ gateway, handleConfigFileUpload }: ToolbarComponentProps) => {
  const {
    rows,
    setRows,
    gwOnlineStatus,
    setGwOnlineStatus,
    hasEditPermission,
    isGWRegistered,
    syncStatus,
    setSyncStatus,
    latestFirmwareList,
    setErrorMessage,
    setSuccessMessage,
    setShowAlert,
    setSyncDialogTitle,
    setSyncDialogContent,
    setIsSyncDialogOpen,
    getGatewayOnlineStatus
  } = useSiteContext();

  const { t } = useTranslation();
  const { DeviceList: devices } = useSelector((state: RootState) => state.devices);
  const site = useSelector(getSite);
  const [createConfigFile] = useCreateConfigFileMutation();
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const [updateDevice] = useUpdateDeviceMutation();

  // Fetch list of config files
  // set the gateway's row as busy
  const handleSyncStationsButton = async () => {
    const dialogTitle = t('Gateway_DeviceSync_Title');
    const dialogContent = t('Gateway_DeviceSync_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Busy);
    setSyncStatus(Status.Waiting);
    try {
      const newRows = JSON.parse(JSON.stringify(rows));
      for (const row of newRows) {
        // Skip gateways
        if (devices[row.id].basicInfo.deviceType != 18) {
          const response = await createConfigFile({
            sitePublicId: site.siteInfo.publicId,
            devicePublicId: row.id
          });

          if (response?.data) {
            row.ConfigFileUrl = response.data;
            row.Status = Status.Syncing;
            setRows(newRows);
            const gatewaySyncResponse = await handleConfigFileUpload(row);
            if (gatewaySyncResponse !== void 0) {
              row.Status = gatewaySyncResponse;
              setRows(newRows);
            }
          } else {
            // TODO: Parameterize this string and localize it.
            throw new Error('Failed to create config files');
          }
        }
      }
      setSyncStatus(Status.Synced);
    } catch (error) {
      setSyncStatus(Status.Error);
    }
    setIsSyncDialogOpen(false);
    setGwOnlineStatus(Status.Online);
  };

  // Update all devices' firmware
  const handleSyncFirmware = useCallback(async () => {
    setRows(rows.map((row) => ({ ...row, Status: 'Checking' })));
    const dialogTitle = t('Gateway_DeviceFirmwareUpdate_Title');
    const dialogContent = t('Gateway_DeviceFirmwareUpdate_Content');
    setSyncDialogTitle(dialogTitle);
    setSyncDialogContent(dialogContent);
    setIsSyncDialogOpen(true);
    setGwOnlineStatus(Status.Checking);

    const isGatewayFirstSync = gateway?.lastSyncedOn === null;
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: isGatewayFirstSync ? 'admin' : gateway?.basicInfo?.macAddress,
      gwId: isGatewayFirstSync ? 'admin' : gateway?.basicInfo?.adminId,
      gwPassword: gateway?.basicInfo.adminPass
    };

    for (const device of rows) {
      const deviceType = device?.ModelNumber;
      if (deviceType === 'IXGW-GW' || deviceType === null) {
        continue;
      }
      // find selected device for firmware
      const deviceFirmwareVersion = devices[device.id]?.basicInfo?.firmwareVersion;
      const latestFirmwareName = latestFirmwareList[deviceType]?.standard?.name;

      // TODO: check to see if the device's firmware is enhanced, and check that version instead
      if (
        deviceType in latestFirmwareList &&
        Object.keys(latestFirmwareList[deviceType]).includes('standard') &&
        /\d\.\d\d/.test(deviceFirmwareVersion) &&
        latestFirmwareList[deviceType].standard.version > parseInt(deviceFirmwareVersion.replace('.', ''))
      ) {
        const selectedDevice = devices[device.id];
        const deviceFirmwareUrl = firmwareUrl + latestFirmwareList[deviceType].standard.name;
        const downloadResponse = await fetch(deviceFirmwareUrl);
        const isDeviceFirstSync = selectedDevice?.lastSyncedOn === null;

        const url = await downloadResponse.text();

        const deviceInfo = {
          deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
          deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
          deviceType: selectedDevice?.basicInfo.deviceType,
          deviceId: isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminId,
          devicePassword: isDeviceFirstSync ? 'admin' : selectedDevice?.basicInfo.adminPass,
          deviceFirmwareFileName: latestFirmwareName,
          deviceFirmwareLink: url
        };
        try {
          const ioTPayload = fetchGatewayCommand(
            'sendCommand',
            gwCommand.FIRMWARE_UPDATE,
            gatewayInfo,
            deviceInfo,
            null
          );
          device.Status = Status.Updating;
          setRows(rows);
          await handleGatewayCommand(ioTPayload).unwrap();

          // wait 5 minutes
          // TODO: improve this to check the status of the firmware update, especially if there is an early failure response
          await new Promise((resolve) => setTimeout(resolve, 300000));

          const fetchPayload = fetchGatewayCommand(
            'fetchResult',
            gwCommand.FIRMWARE_UPDATE,
            gatewayInfo,
            deviceInfo,
            null
          );
          let fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

          if (fetchResponse?.statusCode.includes('206')) {
            fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();
          }

          // Check the payload for the ip address to see if firmware update was successful
          if (fetchResponse?.payload[0].statusCode.includes('200')) {
            setShowAlert(true);
            setSuccessMessage(t('Success'));
            // update the device firmware version in redux and the database
            const fwVersion = Number(latestFirmwareList[deviceType].standard.version / 100).toFixed(2);
            const updateDevicePayload = {
              ...selectedDevice,
              basicInfo: {
                ...selectedDevice.basicInfo,
                firmwareVersion: fwVersion
              }
            };
            await updateDevice({ device: updateDevicePayload });
            device.Status = Status.Success;
            setRows(rows);
          } else {
            device.Status = Status.Error;
            setRows(rows);
            setErrorMessage(t(getGWErrorCode({ message: 'Firmware_Update_Error' })));
          }
        } catch (error) {
          setErrorMessage(t(getGWErrorCode({ message: 'Firmware_Update_Error' })));
        }
      } else {
        device.Status = Status.UpToDate;
        setRows(rows);
      }
    }
    setIsSyncDialogOpen(false);
  }, [devices, rows, site, gateway, latestFirmwareList, t]);

  if (hasEditPermission) {
    // Display toolbar only if they have permission
    return (
      <Box sx={styles.buttonContainer}>
        <Tooltip title={t('Check_the_status_of_the_devices')}>
          <span>
            <Button
              sx={{ mr: 2, color: NERO_COLOR, borderColor: NERO_COLOR }}
              variant="outlined"
              startIcon={<SyncIcon />}
              disabled={
                !hasEditPermission ||
                !isGWRegistered ||
                gwOnlineStatus !== Status.Online ||
                gateway?.basicInfo?.firmwareVersion < 3.81
              }
              onClick={getGatewayOnlineStatus}
            >
              {syncStatus === Status.Syncing ? t('Searching') : t('Refresh_Station_Status')}
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={t('Upload_configuration_files_tooltip')}>
          <span>
            <Button
              sx={{ mr: 2, color: NERO_COLOR, borderColor: NERO_COLOR }}
              variant="outlined"
              startIcon={<SyncIcon />}
              disabled={
                !hasEditPermission ||
                !isGWRegistered ||
                gwOnlineStatus !== Status.Online ||
                gateway?.basicInfo?.firmwareVersion < 3.81
              }
              onClick={handleSyncStationsButton}
            >
              {syncStatus === Status.Syncing ? t('Syncing') : t('Sync_Station_Configuration')}
            </Button>
          </span>
        </Tooltip>
        <Tooltip title={t('Update_all_devices_tooltip')}>
          <span>
            <Button
              sx={{ mr: 2, color: NERO_COLOR, borderColor: NERO_COLOR }}
              variant="outlined"
              startIcon={<UpdateIcon />}
              onClick={handleSyncFirmware}
              disabled={!hasEditPermission || !isGWRegistered || gwOnlineStatus !== Status.Online}
            >
              {t('Update_Station_Firmware')}
            </Button>
          </span>
        </Tooltip>
      </Box>
    );
  }
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '5px',
    backgroundColor: '#f5f5f5'
  }
};

export default ToolbarComponent;
