import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@mui/material';

const NameField: React.FC<FieldProps> = ({ field, form, ...props }) => {
  return (
    <TextField
      {...field}
      {...props}
      type="text"
      error={Boolean(form.touched[field.name] && form.errors[field.name])}
      helperText={
        form.touched[field.name] && form.errors[field.name] // Conditional rendering of helperText
          ? String(form.errors[field.name]) // Make sure the error is passed as a string
          : undefined // Ensure no content if there's no error
      }
      fullWidth
    />
  );
};

export default NameField;
