import {
  Box,
  Typography,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Paper
} from '@mui/material';
import { Container } from '@mui/system';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useUpdateSiteMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';
import { setSiteLoading, setIsWizardComplete } from 'store/slices/siteSlice';
import { useTranslation } from 'react-i18next';
interface Props {
  isChecked1: boolean;
  isChecked2: boolean;
  isChecked3: boolean;
  setIsChecked1: (isChecked: boolean) => void;
  setIsChecked2: (isChecked: boolean) => void;
  setIsChecked3: (isChecked: boolean) => void;
  handleNextStep: () => void;
}

function Introduction({
  isChecked1,
  isChecked2,
  isChecked3,
  setIsChecked1,
  setIsChecked2,
  setIsChecked3,
  handleNextStep
}: Readonly<Props>) {
  const [isSkipDialogOpen, setIsSkipDialogOpen] = useState(false);
  const site = useSelector((state: RootState) => state.site);
  const [updateSite] = useUpdateSiteMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleCheckboxChange = (checkboxNumber: number) => {
    switch (checkboxNumber) {
      case 1:
        setIsChecked1(!isChecked1);
        break;
      case 2:
        setIsChecked2(!isChecked2);
        break;
      case 3:
        setIsChecked3(!isChecked3);
        break;
      default:
        break;
    }
  };

  const handleWizardSkip = async () => {
    const updatedSite = {
      publicId: site.siteInfo.publicId,
      isWizardCompleted: true
    };
    dispatch(setSiteLoading(true));
    try {
      await updateSite({ updatedSite }).then((result) => {
        if ('error' in result) {
          throw new Error('Error updating site');
        } else {
          dispatch(setSiteLoading(false));
          dispatch(setIsWizardComplete(true));
          navigate(`/site/${site.siteInfo.publicId}/siteinfo`);
        }
      });
    } catch (error) {
      console.error('Error updating site data:', error);
    }
  };

  const isButtonEnabled = isChecked1 && isChecked2 && isChecked3;

  return (
    <Container maxWidth="md">
      <Box>
        <Box sx={styles.centerContent}>
          <Typography variant="h4" sx={{ mb: 3, color: 'primary.main', fontWeight: 'bold' }}>
            {t('NSW')}
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 20 }}>
            {t('Welcome_Site_Wizard')}
          </Typography>
        </Box>
        <Box sx={styles.centerContent}>
          <Box>
            <Paper sx={styles.checkboxContainer}>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={isChecked1} onChange={() => handleCheckboxChange(1)} />}
                  label={t('Site_Wizard_Check_Step_1')}
                  style={{ marginBottom: 10 }}
                />
                <FormControlLabel
                  control={<Checkbox checked={isChecked2} onChange={() => handleCheckboxChange(2)} />}
                  label={t('Site_Wizard_Check_Step_2')}
                  style={{ marginBottom: 10 }}
                />
                <FormControlLabel
                  control={<Checkbox checked={isChecked3} onChange={() => handleCheckboxChange(3)} />}
                  label={t('Site_Wizard_Check_Step_3')}
                  style={{ marginBottom: 10 }}
                />
              </FormGroup>
            </Paper>
          </Box>
          <Button disabled={!isButtonEnabled} variant="contained" onClick={handleNextStep}>
            {t('Continue_to_Site_Wizard')}
          </Button>
          <Button onClick={() => setIsSkipDialogOpen(true)}>
            <Typography variant="caption">{t('Skip_Wizard')}</Typography>
          </Button>
        </Box>
      </Box>
      <Dialog
        open={isSkipDialogOpen}
        onClose={() => setIsSkipDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t('Skip_Dialog_Header')}</DialogTitle>
        <DialogContent>
          {site.Loading ? (
            <Box sx={styles.centerContent}>
              <DialogContentText>{t('Saving_Site_Data')}</DialogContentText>
              <CircularProgress />
            </Box>
          ) : (
            <DialogContentText>{t('Skip_Dialog_Body')}</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSkipDialogOpen(false)}>{t('Button_Cancel')}</Button>
          <Button onClick={handleWizardSkip}>{t('Skip_Wizard')}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dialogContainer: {
    width: '100%',
    padding: '4rem'
  },
  marginBottom: {
    marginBottom: 10
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10
  },
  checkboxContainer: {
    p: 3,
    px: 5,
    mb: 4,
    width: '100%'
  }
};

export default Introduction;
