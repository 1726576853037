import { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridActionsCellItem, GridRow } from '@mui/x-data-grid';
import InviteUserDialog from '../Dialogs/InviteSiteUserDialog';
import InviteAiphoneUserDialog from '../Dialogs/InviteAiphoneUserDialog';
import EditSiteUserDialog from '../Dialogs/EditSiteUserDialog';
import { useGetUsersBySitePublicIdQuery } from 'services/aiphoneCloud';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import EditIcon from '@mui/icons-material/Edit';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { CloudUser } from 'store/slices/usersSlice';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

const SiteUsersTab = () => {
  const { t } = useTranslation();
  const defaultPageSize = 50;
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const currentUser = useSelector((state: RootState) => state.users.currentUser);
  const { isAllowedTo } = usePermission();
  const canViewUsers = isAllowedTo('user:view', sitePublicId, PermissionsContextType.SITE);
  const canUpdateUsers = isAllowedTo('user:update', sitePublicId, PermissionsContextType.SITE);
  const canCreateUsers = isAllowedTo('user:create', sitePublicId, PermissionsContextType.SITE);

  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false);
  const [isInviteAiphoneUserDialogOpen, setIsInviteAiphoneUserDialogOpen] = useState(false);
  const [isEditUserDialogOpen, setIsEditUserDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<CloudUser>();
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: defaultPageSize });
  const [rowCount, setRowCount] = useState(0);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [userEmailList, setUserEmailList] = useState<string[]>([]);

  const {
    data: usersData,
    isLoading,
    isError: loadError,
    refetch
  } = useGetUsersBySitePublicIdQuery({
    qty: paginationModel.pageSize.toString(),
    page: paginationModel.page.toString(),
    publicId: sitePublicId
  });

  useEffect(() => {
    if (usersData) {
      //pick the user email from the data
      const userEmails = Object.values(usersData.userList).map((user) => (user as CloudUser).email);
      setUserEmailList(userEmails);
      setRowCount(usersData.totalUsers);
    }
  }, [usersData]);

  useEffect(() => {
    if (loadError) {
      setErrorMessage(t('Failed_to_load_users_Please_try_again_later'));
      setIsError(true);
    }
  }, [loadError]);

  const openInviteUserDialog = () => {
    setIsInviteUserDialogOpen(true);
  };

  const openInviteAiphoneUserDialog = () => {
    setIsInviteAiphoneUserDialogOpen(true);
  };

  const handleUserActionSuccess = () => {
    setIsSuccess(true);
    setSuccessMessage(t('Action_completed_successfully'));
    refetch();
  };

  const handleAiphoneUserActionSuccess = () => {
    setIsSuccess(true);
    setSuccessMessage(t('Action_completed_successfully'));
    refetch();
  };

  const computeRows = () => {
    return Object.entries(usersData?.userList || {}).map(([key, value]) => {
      const user = value as CloudUser; // Cast value as CloudUser
      const role = user.permissions?.site?.[sitePublicId]?.roleList?.[0]?.roleName || 'No Role';
      return {
        id: key,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: role,
        userId: key,
        fullUser: user
      };
    });
  };

  const columns: GridColDef[] = [
    { field: 'firstName', headerName: t('First_Name'), width: 150, sortable: true, resizable: true },
    { field: 'lastName', headerName: t('Last_Name'), width: 150, sortable: true, resizable: true },
    { field: 'email', headerName: t('Email_Label'), width: 250, sortable: true, resizable: true },
    { field: 'role', headerName: t('Site_Role'), width: 150, sortable: true, resizable: true },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: (params) => {
        // Check if the current user matches the user in the row
        const isCurrentUser = currentUser?.publicId === params.row.id;

        // If the user is the current user, do not show the edit action
        if (!canUpdateUsers || isCurrentUser) return [];

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label={t('Edit')}
            onClick={() => {
              setSelectedUser(params.row.fullUser);
              setIsEditUserDialogOpen(true);
            }}
            showInMenu
          />
        ];
      }
    }
  ];

  const rows = !isLoading ? computeRows() : [];

  return (
    <Box sx={styles.mainSection}>
      <Tooltip title={!canCreateUsers ? t('Invite_Valid_For_Admin_Manager') : ''} placement="top-end">
        <Box sx={styles.buttonContainer}>
          <Button onClick={openInviteUserDialog} variant="contained" disabled={!canCreateUsers}>
            <AddIcon /> {t('Invite_Administrator')}
          </Button>
          <Button onClick={openInviteAiphoneUserDialog} variant="contained" disabled={!canCreateUsers}>
            <AddIcon />
            {t('Invite_Aiphone_User')}
          </Button>
        </Box>
      </Tooltip>

      <Box sx={styles.gridContainer}>
        {isLoading ? (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : isError ? (
          <Box sx={styles.errorContainer}>
            <Typography variant="h6" color="error">
              {t('Failed_to_load_users_Please_try_again_later')}
            </Typography>
          </Box>
        ) : canViewUsers ? (
          <DataGrid
            columns={columns}
            rows={rows}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            autoHeight
            disableRowSelectionOnClick
            sx={styles.dataGrid}
            rowCount={rowCount}
            pagination
            paginationMode="server"
            pageSizeOptions={[10, 25, 50]}
            // apply the background highlight to the row for the current user
            slots={{
              row: (props) => {
                const isCurrentUser = currentUser?.publicId === props.row.id;
                return (
                  <GridRow
                    {...props}
                    style={{
                      ...props.style,
                      ...(isCurrentUser ? styles : {})
                    }}
                  />
                );
              }
            }}
          />
        ) : (
          <Typography variant="h6" color="error">
            {t('You_do_not_have_permission_to_view_users')}
          </Typography>
        )}
      </Box>
      <InviteUserDialog
        userEmailList={userEmailList}
        isOpen={isInviteUserDialogOpen}
        setIsOpen={setIsInviteUserDialogOpen}
        onInviteSuccess={handleUserActionSuccess}
      />
      <InviteAiphoneUserDialog
        userEmailList={userEmailList}
        isOpen={isInviteAiphoneUserDialogOpen}
        setIsOpen={setIsInviteAiphoneUserDialogOpen}
        onInviteSuccess={handleAiphoneUserActionSuccess}
      />
      <EditSiteUserDialog
        isOpen={isEditUserDialogOpen}
        setIsOpen={setIsEditUserDialogOpen}
        selectedUser={selectedUser}
        onSuccess={handleUserActionSuccess}
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </Box>
  );
};

const styles = {
  background: 'rgba(63, 81, 181, 0.1)',
  buttonContainer: {
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '1rem',
    gap: '1rem'
  },
  gridContainer: {
    height: 'auto',
    width: '100%',
    overflowX: 'auto'
  },
  mainSection: {
    padding: '2rem',
    width: '100%',
    height: '100%',
    overflow: 'auto'
  },
  dataGrid: {
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: '#f5f5f5'
    }
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
};

export default SiteUsersTab;
