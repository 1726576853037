import * as Yup from 'yup';
import usePortValidationSchema from 'shared/utils/ValidationSchema/AdvancedSettings/Shared/Port';
import useBitrateValidationSchema from 'shared/utils/ValidationSchema/AdvancedSettings/Shared/Bitrate';
import useFramerateValidationSchema from 'shared/utils/ValidationSchema/AdvancedSettings/Shared/Framerate';
import useIPictureIntervalValidationSchema from 'shared/utils/ValidationSchema/AdvancedSettings/Shared/IPictureInterval';
import useTosValueValidationSchema from 'shared/utils/ValidationSchema/AdvancedSettings/Shared/TosValue';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';

export interface IVideoFields {
  [key: string]: any;
  networkSettings: {
    [key: string]: any;
    videoTosValue: string | undefined;
    videoEncoder1Framerate: number | undefined;
    videoEncoder1IPictureInterval: number | undefined;
    videoEncoder1Bitrate: number | undefined;
    videoDualStream: boolean | undefined;
    videoEncoder2Method: number | undefined;
    videoEncoder2Resolution: number | undefined;
    videoEncoder2Framerate: number | undefined;
    videoEncoder2H264Quality: number | undefined;
    videoEncoder2IPictureInterval: number | undefined;
    videoEncoder2Bitrate: number | undefined;
    videoEncoder2JpegQuality: number | undefined;
    videoEncoder2RtpStartPort: number | undefined;
    videoEncoder2RtpEndPort: number | undefined;
    masterStationVideoFramerate: number | undefined;
    masterStationVideoIPictureInterval: number | undefined;
    masterStationVideoBitrate: number | undefined;
  };
}

const useVideoValidationSchema = () => {
  const { t } = useTranslation();
  const videoTosValueTitle = t('AdvancedSettings_VideoTosValue_Title');
  const videoEncoder1IPictureIntervalTitle = t('AdvancedSettings_VideoEncoder1IPictureInterval_Title');
  const videoEncoder1FramerateTitle = t('AdvancedSettings_VideoEncoder1Framerate_Title');
  const videoEncoder1BitrateTitle = t('AdvancedSettings_VideoEncoder1Bitrate_Title');
  const videoEncoder2FramerateTitle = t('AdvancedSettings_VideoEncoder2Framerate_Title');
  const videoEncoder2IPictureIntervalTitle = t('AdvancedSettings_VideoEncoder2IPictureInterval_Title');
  const videoEncoder2BitrateTitle = t('AdvancedSettings_VideoEncoder2Bitrate_Title');
  const videoEncoder2RtpStartPortTitle = t('AdvancedSettings_VideoEncoder2RtpStartPort_Title');
  const videoEncoder2RtpEndPortTitle = t('AdvancedSettings_VideoEncoder2RtpEndPort_Title');
  const masterStationVideoFramerateTitle = t('AdvancedSettings_MasterStationVideoFramerate_Title');
  const masterStationVideoIPictureIntervalTitle = t('AdvancedSettings_MasterStationVideoIPictureInterval_Title');
  const masterStationVideoBitrateTitle = t('AdvancedSettings_MasterStationVideoBitrate_Title');
  const enumList: EnumList = fetchLocalEnumList();
  const fieldErrorRequired = t('Field_Error_Required');
  const fieldErrorInvalid = t('Field_Error_Invalid');

  // Titles for non-shared validations
  const videoDualStreamTitle = t('AdvancedSettings_VideoDualStream_Title');
  const videoEncoder2MethodTitle = t('AdvancedSettings_VideoEncoder2Method_Title');
  const videoEncoder2ResolutionTitle = t('AdvancedSettings_VideoEncoder2Resolution_Title');
  const videoEncoder2H264QualityTitle = t('AdvancedSettings_VideoEncoder2H264Quality_Title');
  const videoEncoder2JpegQualityTitle = t('AdvancedSettings_VideoEncoder2JpegQuality_Title');

  // Validations that are shared between multiple fields
  const rtpStartPortValidation = usePortValidationSchema(videoEncoder2RtpStartPortTitle);
  const rtpEndPortValidation = usePortValidationSchema(videoEncoder2RtpEndPortTitle);
  const videoEncoder1IPictureIntervalValidation = useIPictureIntervalValidationSchema(
    videoEncoder1IPictureIntervalTitle
  );
  const videoEncoder2IPictureIntervalValidation = useIPictureIntervalValidationSchema(
    videoEncoder2IPictureIntervalTitle
  );
  const masterStationVideoIPictureIntervalValidation = useIPictureIntervalValidationSchema(
    masterStationVideoIPictureIntervalTitle
  );
  const tosValueValidation = useTosValueValidationSchema(videoTosValueTitle);
  const videoEncoder1FramerateValidation = useFramerateValidationSchema(videoEncoder1FramerateTitle);
  const videoEncoder2FramerateValidation = useFramerateValidationSchema(videoEncoder2FramerateTitle);
  const masterStationVideoFramerateValidation = useFramerateValidationSchema(masterStationVideoFramerateTitle);
  const videoEncoder1BitrateValidation = useBitrateValidationSchema(videoEncoder1BitrateTitle);
  const videoEncoder2BitrateValidation = useBitrateValidationSchema(videoEncoder2BitrateTitle);
  const masterStationVideoBitrateValidation = useBitrateValidationSchema(masterStationVideoBitrateTitle);

  const getValidationSchema = (formDevice: IVideoFields, currValues: IVideoFields) => {
    const networkSchema: any = {};
    let formattedFieldRequired: string;
    let formattedFieldInvalid: string;

    if (formDevice.networkSettings.videoTosValue !== undefined) {
      networkSchema.videoTosValue = tosValueValidation;
    }

    if (formDevice.networkSettings.videoEncoder1Framerate !== undefined) {
      networkSchema.videoEncoder1Framerate = videoEncoder1FramerateValidation;
    }

    if (formDevice.networkSettings.videoEncoder1IPictureInterval !== undefined) {
      networkSchema.videoEncoder1IPictureInterval = videoEncoder1IPictureIntervalValidation;
    }

    if (formDevice.networkSettings.videoEncoder1Bitrate !== undefined) {
      networkSchema.videoEncoder1Bitrate = videoEncoder1BitrateValidation;
    }

    if (formDevice.networkSettings.videoDualStream !== undefined) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoDualStreamTitle);
      networkSchema.videoDualStream = Yup.boolean().required(formattedFieldRequired);
    }

    if (currValues.networkSettings.videoDualStream) {
      if (formDevice.networkSettings.videoEncoder2Method !== undefined) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2MethodTitle);
        formattedFieldInvalid = StringUtils.format(fieldErrorInvalid, videoEncoder2MethodTitle);
        networkSchema.videoEncoder2Method = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidMethod', formattedFieldInvalid, (value: string) => {
            return Object.keys(enumList.videoEncoderMethod).includes(value);
          });
      }

      if (formDevice.networkSettings.videoEncoder2Resolution !== undefined) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2ResolutionTitle);
        formattedFieldInvalid = StringUtils.format(fieldErrorInvalid, videoEncoder2ResolutionTitle);
        networkSchema.videoEncoder2Resolution = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidResolution', formattedFieldInvalid, (value: string) => {
            return Object.keys(enumList.videoEncoderResolution).includes(value);
          });
      }

      if (formDevice.networkSettings.videoEncoder2Framerate !== undefined) {
        networkSchema.videoEncoder2Framerate = videoEncoder2FramerateValidation;
      }

      if (formDevice.networkSettings.videoEncoder2H264Quality !== undefined) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2H264QualityTitle);
        formattedFieldInvalid = StringUtils.format(fieldErrorInvalid, videoEncoder2H264QualityTitle);
        networkSchema.videoEncoder2H264Quality = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidQuality', formattedFieldInvalid, (value: string) => {
            return Object.keys(enumList.videoEncoderQuality).includes(value);
          });
      }

      if (formDevice.networkSettings.videoEncoder2IPictureInterval !== undefined) {
        networkSchema.videoEncoder2IPictureInterval = videoEncoder2IPictureIntervalValidation;
      }

      if (formDevice.networkSettings.videoEncoder2Bitrate !== undefined) {
        networkSchema.videoEncoder2Bitrate = videoEncoder2BitrateValidation;
      }

      if (formDevice.networkSettings.videoEncoder2JpegQuality !== undefined) {
        formattedFieldRequired = StringUtils.format(fieldErrorRequired, videoEncoder2JpegQualityTitle);
        formattedFieldInvalid = StringUtils.format(fieldErrorInvalid, videoEncoder2JpegQualityTitle);
        networkSchema.videoEncoder2JpegQuality = Yup.string()
          .required(formattedFieldRequired)
          .test('isValidQuality', formattedFieldInvalid, (value: string) => {
            return Object.keys(enumList.videoEncoderQuality).includes(value);
          });
      }

      if (formDevice.networkSettings.videoEncoder2RtpStartPort !== undefined) {
        networkSchema.videoEncoder2RtpStartPort = rtpStartPortValidation;
      }

      if (formDevice.networkSettings.videoEncoder2RtpEndPort !== undefined) {
        networkSchema.videoEncoder2RtpEndPort = rtpEndPortValidation;
      }
    }

    if (formDevice.networkSettings.masterStationVideoFramerate !== undefined) {
      networkSchema.masterStationVideoFramerate = masterStationVideoFramerateValidation;
    }

    if (formDevice.networkSettings.masterStationVideoIPictureInterval !== undefined) {
      networkSchema.masterStationVideoIPictureInterval = masterStationVideoIPictureIntervalValidation;
    }

    if (formDevice.networkSettings.masterStationVideoBitrate !== undefined) {
      networkSchema.masterStationVideoBitrate = masterStationVideoBitrateValidation;
    }

    return Yup.object({
      networkSettings: Yup.object().shape(networkSchema)
    });
  };

  return getValidationSchema;
};

export default useVideoValidationSchema;
