import { useDispatch, useSelector } from 'react-redux';
import { IDevice, getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useUpdateDeviceMutation } from 'services/aiphoneCloud';
import { RootState } from 'store';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import StringUtils from 'shared/utils/StringUtils';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useEffect, useState } from 'react';
import { EnumList, fetchEnumList } from 'shared/utils/EnumUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const StationIdentificationLabel = () => {
  const { t } = useTranslation();
  return <span>{t('AdvancedSettings_Tab_StationIdentification')}</span>;
};

const StationIdentification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const selectedDevice = useSelector(getSelectedDevice);
  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const buildingList = useSelector((state: RootState) => state.buildings.BuildingList);
  const sitePublicId = useParams().id ?? '';

  const selectedDeviceUnit = selectedDevice ? unitList[selectedDevice?.unitPublicId ?? ''] : undefined;
  const selectedDeviceBuilding = selectedDeviceUnit
    ? buildingList[selectedDeviceUnit?.buildingPublicId ?? '']
    : buildingList[selectedDevice?.buildingPublicId ?? ''];

  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);

  const titles = {
    stationName: t('AdvancedSettings_StationName_Title'),
    stationNumber: t('AdvancedSettings_StationNumber_Title'),
    timezone: t('Timezone_Title'),
    daylightSavings: t('AdvancedSettings_DaylightSavings_Title')
  };

  const descriptions = {
    stationName: t('AdvancedSettings_StationName_Desc'),
    stationNumber: t('AdvancedSettings_StationNumber_Desc'),
    timezone: t('Timezone_Desc'),
    daylightSavings: t('AdvancedSettings_DaylightSavings_Desc')
  };

  const fieldErrors = {
    maxLen: t('Field_Error_MaxLen'),
    minLen: t('Field_Error_MinLen'),
    required: t('Field_Error_Required'),
    unique: t('Field_Error_Unique')
  };

  const messages = {
    errorUpdateDevice: t('AdvancedSettings_Error_UpdateDevice'),
    unauthorizedUser: t('AdvancedSettings_Unauthorized_User'),
    successUpdateDevice: t('AdvancedSettings_Success_UpdateDevice')
  };

  const buttonLabels = {
    stationNumberTitle: '',
    reset: t('Button_Reset'),
    saveChanges: t('Button_SaveChanges')
  };

  const formDevice = {
    stationName: selectedDevice?.basicInfo.stationName,
    stationNumber: selectedDevice?.basicInfo.stationNumber,
    timezone: selectedDevice?.basicInfo.timezone,
    timezoneVer2: selectedDevice?.basicInfo.timezoneVer2,
    daylightSavings: selectedDevice?.basicInfo.daylightSavings,
    deviceType: selectedDevice?.basicInfo.deviceType
  };

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  const getValidationSchema = () => {
    const basicInfoSchema: any = {};

    if (formDevice.stationNumber !== undefined) {
      const stationNumberMaxLen = 24;
      const stationNumberMinLen = 3;

      // Quick fix for [RM-324], preventing station number from being 2 characters or fewer.

      basicInfoSchema.stationNumber = Yup.string()
        .required(StringUtils.format(fieldErrors.required, titles.stationNumber))
        .max(stationNumberMaxLen, StringUtils.format(fieldErrors.maxLen, titles.stationNumber, stationNumberMaxLen))
        .min(stationNumberMinLen, StringUtils.format(fieldErrors.minLen, titles.stationNumber, stationNumberMinLen))
        .test('isUnique', StringUtils.format(fieldErrors.unique, titles.stationNumber), (value: string) => {
          return !Object.values(deviceList).some(
            (device: IDevice) => device.publicId != selectedDevice.publicId && device.basicInfo?.stationNumber === value
          );
        })
        .test('isNumber', t('Station_Number_must_be_a_number'), (value: string) => {
          const regex = /^\d+$/;
          return regex.test(value);
        });
    }

    if (formDevice.stationName !== undefined) {
      const stationNameMaxLen = 24;

      basicInfoSchema.stationName = Yup.string()
        .max(stationNameMaxLen, StringUtils.format(fieldErrors.maxLen, titles.stationName, stationNameMaxLen))
        .required(StringUtils.format(fieldErrors.required, titles.stationName))
        // Fixing an EsLint capitalization issue
        .test('isAlphanumeric', t('Station_Name_must_be_alphanumeric'), (value: string) => {
          const regex = /^[a-z\d\s+\-_?./()%$@!]+$/i; // only allow a to z (case-insensitive), 0-9 and empty spaces.
          return regex.test(value);
        });
    }

    return Yup.object(basicInfoSchema);
  };

  const onSubmit = async (values: any, actions: any) => {
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        basicInfo: values
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));

    newDevice.basicInfo = {
      ...newDevice.basicInfo,
      ...values
    };

    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        }

        dispatch(updateSelectedDevice({ device: newDevice }));
        setShowAlert(true);
        actions.resetForm({
          values: values
        });
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(messages.unauthorizedUser);
        } else {
          setErrorMessage(messages.errorUpdateDevice);
        }
      });
  };

  const handleGoToUnit = () => {
    navigate(`/site/${sitePublicId}/units/${selectedDeviceUnit?.publicId}/unitinformation`);
  };

  return (
    <Box sx={containerStyle.mainWrapper}>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={messages.successUpdateDevice}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      {fetchingEnums ? (
        <CircularProgress />
      ) : (
        <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={getValidationSchema()}>
          {({ values, dirty, touched, errors, isSubmitting }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.controlPanelWrapper}>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="reset"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {buttonLabels.reset}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="submit"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {buttonLabels.saveChanges}
                </LoadingButton>
              </Box>
              <Card sx={containerStyle.settingsWrapper}>
                <Box sx={containerStyle.gridContainer}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    style={containerStyle.itemContainer}
                    spacing={2}
                  >
                    <Grid item xs={5} lg={7}>
                      <Box sx={containerStyle.itemTitle}>{t('Building_Number')}</Box>
                      <Box>{t('Building_Number_Desc')}</Box>
                    </Grid>
                    <Grid item xs={6} lg={4} justifyContent="flex-end">
                      <Box sx={containerStyle.fieldContainer}>
                        <Box>{selectedDeviceBuilding?.buildingNumber}</Box>
                      </Box>
                    </Grid>
                    {selectedDeviceUnit && formDevice.deviceType !== 18 && formDevice.deviceType !== 6 ? (
                      <>
                        <Grid item xs={5} lg={7}>
                          <Box sx={containerStyle.itemTitle}>{t('Unit_Number')}</Box>
                          <Box>{t('Unit_Number_Desc')}</Box>
                        </Grid>
                        <Grid item xs={6} lg={4} justifyContent="flex-end">
                          <Box sx={containerStyle.fieldContainer}>
                            <Box>
                              <Button variant="contained" onClick={handleGoToUnit}>
                                {t('View_Unit')} {selectedDeviceUnit?.unitNumber}
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Box>
              </Card>
              <Card sx={containerStyle.settingsWrapper}>
                <Box sx={containerStyle.gridContainer}>
                  {formDevice.stationNumber !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{titles.stationNumber}</Box>
                        <Box>{descriptions.stationNumber}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            label={titles.stationNumber}
                            name="stationNumber"
                            size="small"
                            style={containerStyle.textField}
                            helperText={touched.stationNumber && errors.stationNumber}
                            error={touched.stationNumber && errors.stationNumber}
                            placeholder="01001"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.stationName !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{titles.stationName}</Box>
                        <Box>{descriptions.stationName}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            label={titles.stationName}
                            name="stationName"
                            size="small"
                            style={containerStyle.textField}
                            helperText={touched.stationName && errors.stationName}
                            error={touched.stationName && errors.stationName}
                            placeholder={t('Station_01001')}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.timezoneVer2 !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{titles.timezone}</Box>
                        <Box>{descriptions.timezone}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <FormControl
                            sx={containerStyle.selectField}
                            size="small"
                            error={touched.timezoneVer2 && Boolean(errors.timezoneVer2)}
                          >
                            <InputLabel id="timezone-ver2-label">{titles.timezone}</InputLabel>
                            <Field
                              as={Select}
                              labelId="timezone-ver2-label"
                              label="Timezone Ver2"
                              name="timezoneVer2"
                              autoWidth={false}
                            >
                              {Object.keys(enumList.timezone).map((key) => {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {enumList.timezone[key].value}
                                  </MenuItem>
                                );
                              })}
                            </Field>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.daylightSavings !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{titles.daylightSavings}</Box>
                        <Box>{descriptions.daylightSavings}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={Checkbox}
                            label={titles.daylightSavings}
                            name="daylightSavings"
                            style={containerStyle.toggleField}
                            checked={values.daylightSavings}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default StationIdentification;
