import { useDispatch, useSelector } from 'react-redux';
import { getString } from 'shared/utils/LocalizationUtils';
import StringUtils from 'shared/utils/StringUtils';
import { getSelectedDevice, updateSelectedDevice } from 'store/slices/devicesSlice';
import containerStyle from 'shared/styles/advancedSettingContainerStyle';
import { useUpdateDeviceMutation, useLazyGetDeviceListWithSitePublicIdQuery } from 'services/aiphoneCloud';
import * as Yup from 'yup';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useState } from 'react';
import { Form, Formik, Field } from 'formik';
import { Box, Grid, Card, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PasswordField from 'shared/components/forms/fields/PasswordField';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/* ID & Password tab stub */
export const IDPasswordLabel = () => {
  const { t } = useTranslation();
  const idPasswordLabel = t('ID_Password_Label');
  return <span>{idPasswordLabel}</span>;
};

const IDPassword = () => {
  const dispatch = useDispatch();

  const [updateDevice, { isLoading: isUpdating }] = useUpdateDeviceMutation();
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const selectedDevice = useSelector(getSelectedDevice);
  const sitePublicId = useParams().id ?? '';

  const { t } = useTranslation();

  const digitString = t('Digit');
  const lowerCaseString = t('LowerCase');
  const missingItemError = t('Password_Error_MissingItem');
  const userIdTitle = t('AdvancedSettings_UserId_Title');
  const userPassTitle = t('AdvancedSettings_UserPass_Title');
  const adminIdTitle = t('AdvancedSettings_AdminId_Title');
  const adminPassTitle = t('AdvancedSettings_AdminPass_Title');
  const passWordMatchError = t('Password_Error_Confirm_Match');
  const onvifIdTitle = t('AdvancedSettings_OnvifId_Title');
  const onvifPassTitle = t('AdvancedSettings_OnvifPass_Title');
  const rtspIdTitle = t('AdvancedSettings_RtspId_Title');
  const rtspPassTitle = t('AdvancedSettings_RtspPass_Title');
  const specialChar = t('Password_Error_SpecialCharacter');
  const idSpecialChar = t('Id_Error_Special_Characters');

  const errorUpdateDevice = t('AdvancedSettings_Error_UpdateDevice');
  const successUpdateDevice = t('AdvancedSettings_Success_UpdateDevice');
  const unauthorizedUser = t('AdvancedSettings_Unauthorized_User');

  const buttonSaveChanges = t('Button_SaveChanges');
  const buttonReset = t('Button_Reset');

  const formDevice = {
    userId: selectedDevice.basicInfo.userId,
    userPass: selectedDevice.basicInfo.userPass,
    userPassConfirm: selectedDevice.basicInfo.userPass,
    adminId: selectedDevice.basicInfo.adminId,
    adminPass: selectedDevice.basicInfo.adminPass,
    adminPassConfirm: selectedDevice.basicInfo.adminPass,
    onvifId: selectedDevice.basicInfo.onvifId,
    onvifPass: selectedDevice.basicInfo.onvifPass,
    onvifPassConfirm: selectedDevice.basicInfo.onvifPass,
    rtspId: selectedDevice.basicInfo.rtspId,
    rtspPass: selectedDevice.basicInfo.rtspPass,
    rtspPassConfirm: selectedDevice.basicInfo.rtspPass
  };

  const getValidationSchema = () => {
    const basicInfoSchema: any = {};

    if (formDevice.userId !== undefined) {
      const userIdMaxLen = 15;

      basicInfoSchema.userId = Yup.string().max(
        userIdMaxLen,
        StringUtils.format(getString('Field_Error_MaxLen'), userIdTitle, userIdMaxLen)
      );
    }

    basicInfoSchema.userId = Yup.string().matches(/^[a-zA-Z0-9]*$/, StringUtils.format(idSpecialChar));

    if (formDevice.userPass !== undefined) {
      const userPassMaxLen = 15;

      basicInfoSchema.userPassConfirm = Yup.string()
        .max(userPassMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), userPassTitle, userPassMaxLen))
        .matches(/[0-9]/, StringUtils.format(missingItemError, digitString))
        .matches(/[a-z]/, StringUtils.format(missingItemError, lowerCaseString))
        .matches(/^[a-zA-Z0-9]*$/, StringUtils.format(specialChar))
        .oneOf([Yup.ref('userPass')], passWordMatchError);
    }

    if (formDevice.adminId !== undefined) {
      const adminIdMaxLen = 15;
      basicInfoSchema.adminId = Yup.string().max(
        adminIdMaxLen,
        StringUtils.format(getString('Field_Error_MaxLen'), adminIdTitle, adminIdMaxLen)
      );
    }

    basicInfoSchema.adminId = Yup.string().matches(/^[a-zA-Z0-9]*$/, StringUtils.format(idSpecialChar));

    if (formDevice.adminPass !== undefined) {
      const adminPassMaxLen = 15;

      basicInfoSchema.adminPassConfirm = Yup.string()
        .max(adminPassMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), adminPassTitle, adminPassMaxLen))
        .matches(/[0-9]/, StringUtils.format(missingItemError, digitString))
        .matches(/[a-z]/, StringUtils.format(missingItemError, lowerCaseString))
        .matches(/^[a-zA-Z0-9]*$/, StringUtils.format(specialChar))
        .oneOf([Yup.ref('adminPass')], passWordMatchError);
    }

    if (formDevice.onvifId !== undefined) {
      const onvifIdMaxLen = 15;
      basicInfoSchema.onvifId = Yup.string()
        .max(onvifIdMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), onvifIdTitle, onvifIdMaxLen))
        .matches(/^[a-zA-Z0-9]*$/, StringUtils.format(idSpecialChar));
    }

    if (formDevice.onvifPass !== undefined) {
      const onvifPassMaxLen = 15;
      basicInfoSchema.onvifPass = Yup.string()
        .max(onvifPassMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), onvifPassTitle, onvifPassMaxLen))
        .matches(/[0-9]/, StringUtils.format(missingItemError, digitString))
        .matches(/[a-z]/, StringUtils.format(missingItemError, lowerCaseString))
        .matches(/^[a-zA-Z0-9]*$/, StringUtils.format(specialChar))
        .oneOf([Yup.ref('onvifPassConfirm')], passWordMatchError);
      basicInfoSchema.onvifPassConfirm = Yup.string().oneOf([Yup.ref('onvifPass')], passWordMatchError);
    }

    if (formDevice.rtspId !== undefined) {
      const rtspIdMaxLen = 15;
      basicInfoSchema.rtspId = Yup.string().max(
        rtspIdMaxLen,
        StringUtils.format(getString('Field_Error_MaxLen'), rtspIdTitle, rtspIdMaxLen)
      );
    }

    basicInfoSchema.rtspId = Yup.string().matches(/^[a-zA-Z0-9]*$/, StringUtils.format(idSpecialChar));

    if (formDevice.rtspPass !== undefined) {
      const rtspPassMaxLen = 15;

      basicInfoSchema.rtspPassConfirm = Yup.string()

        .max(rtspPassMaxLen, StringUtils.format(getString('Field_Error_MaxLen'), rtspPassTitle, rtspPassMaxLen))
        .matches(/[0-9]/, StringUtils.format(missingItemError, digitString))
        .matches(/[a-z]/, StringUtils.format(missingItemError, lowerCaseString))
        .matches(/^[a-zA-Z0-9]*$/, StringUtils.format(specialChar))
        .oneOf([Yup.ref('rtspPass')], passWordMatchError);
    }

    return Yup.object(basicInfoSchema);
  };

  const onSubmit = async (values: any, actions: any) => {
    const params = {
      device: {
        publicId: selectedDevice.publicId,
        basicInfo: values
      }
    };

    const newDevice = JSON.parse(JSON.stringify(selectedDevice));
    newDevice.basicInfo = {
      ...newDevice.basicInfo,
      ...values
    };
    updateDevice(params)
      .then((response) => {
        if ('error' in response) {
          throw response.error;
        } else {
          dispatch(updateSelectedDevice({ device: newDevice }));
          fetchDevices({ sitePublicId, qty: -1, page: 0 });
          setShowAlert(true);
          actions.resetForm({
            values: values
          });
        }
      })
      .catch((error: any) => {
        const err = JSON.parse(error.data);
        if (err.errorDetails.includes('Unauthorized user Id')) {
          setErrorMessage(unauthorizedUser);
        } else {
          setErrorMessage(errorUpdateDevice);
        }
      });
  };

  return (
    <>
      <Box sx={containerStyle.mainWrapper}>
        <SnackbarAlert
          type="error"
          time={10000}
          text={`${errorMessage}`}
          isOpen={!!errorMessage}
          onClose={() => setErrorMessage(null)}
        />
        <SnackbarAlert
          type="success"
          time={3000}
          text={successUpdateDevice}
          isOpen={showAlert}
          onClose={() => setShowAlert(false)}
        />
        <Formik initialValues={formDevice} onSubmit={onSubmit} validationSchema={getValidationSchema()}>
          {({ dirty, touched, errors, isSubmitting }) => (
            <Form style={containerStyle.form}>
              <Box sx={containerStyle.controlPanelWrapper}>
                <LoadingButton variant="outlined" type="reset" disabled={!dirty || isSubmitting || isUpdating}>
                  {buttonReset}
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  loading={isSubmitting}
                  type="submit"
                  disabled={!dirty || isSubmitting || isUpdating}
                >
                  {buttonSaveChanges}
                </LoadingButton>
              </Box>
              <Card sx={containerStyle.settingsWrapper}>
                <Box sx={containerStyle.gridContainer}>
                  {formDevice.userId !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{userIdTitle}</Box>
                        <Box>{getString('AdvancedSettings_UserId_Desc')}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            label={userIdTitle}
                            name="userId"
                            size="small"
                            style={containerStyle.textField}
                            helperText={touched?.userId && errors?.userId}
                            error={touched?.userId && errors?.userId}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.userPass !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{userPassTitle}</Box>
                        <Box>{getString('AdvancedSettings_UserPass_Desc')}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Box>
                            <Field
                              as={PasswordField}
                              label={userPassTitle}
                              name="userPass"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.userPass && errors.userPass}
                              error={touched.userPass && errors.userPass}
                            />
                          </Box>
                          <Box>
                            <Field
                              as={PasswordField}
                              label={getString('Confirm_Password_Title')}
                              name="userPassConfirm"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.userPassConfirm && errors.userPassConfirm}
                              error={touched.userPassConfirm && errors.userPassConfirm}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.adminId !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{adminIdTitle}</Box>
                        <Box>{getString('AdvancedSettings_AdminId_Desc')}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            label={adminIdTitle}
                            name="adminId"
                            size="small"
                            style={containerStyle.textField}
                            helperText={touched.adminId && errors.adminId}
                            error={touched.adminId && errors.adminId}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.adminPass !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{adminPassTitle}</Box>
                        <Box>{getString('AdvancedSettings_AdminPass_Desc')}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Box>
                            <Field
                              as={PasswordField}
                              label={adminPassTitle}
                              name="adminPass"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.adminPass && errors.adminPass}
                              error={touched.adminPass && errors.adminPass}
                            />
                          </Box>
                          <Box>
                            <Field
                              as={PasswordField}
                              label={getString('Confirm_Password_Title')}
                              name="adminPassConfirm"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.adminPassConfirm && errors.adminPassConfirm}
                              error={touched.adminPassConfirm && errors.adminPassConfirm}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.onvifId !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{onvifIdTitle}</Box>
                        <Box>{getString('AdvancedSettings_OnvifId_Desc')}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            label={onvifIdTitle}
                            name="onvifId"
                            size="small"
                            style={containerStyle.textField}
                            helperText={touched.onvifId && errors.onvifId}
                            error={touched.onvifId && errors.onvifId}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.onvifPass !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{onvifPassTitle}</Box>
                        <Box>{getString('AdvancedSettings_OnvifPass_Desc')}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Box>
                            <Field
                              as={PasswordField}
                              label={onvifPassTitle}
                              name="onvifPass"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.onvifPass && errors.onvifPass}
                              error={touched.onvifPass && errors.onvifPass}
                            />
                          </Box>
                          <Box>
                            <Field
                              as={PasswordField}
                              label={getString('Confirm_Password_Title')}
                              name="onvifPassConfirm"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.onvifPassConfirm && errors.onvifPassConfirm}
                              error={touched.onvifPassConfirm && errors.onvifPassConfirm}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.rtspId !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{rtspIdTitle}</Box>
                        <Box>{getString('AdvancedSettings_RtspId_Desc')}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Field
                            as={TextField}
                            label={rtspIdTitle}
                            name="rtspId"
                            size="small"
                            style={containerStyle.textField}
                            helperText={touched.rtspId && errors.rtspId}
                            error={touched.rtspId && errors.rtspId}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                  {formDevice.rtspPass !== undefined ? (
                    <Grid container direction="row" justifyContent="space-evenly" style={containerStyle.itemContainer}>
                      <Grid item xs={5} lg={7}>
                        <Box sx={containerStyle.itemTitle}>{rtspPassTitle}</Box>
                        <Box>{getString('AdvancedSettings_RtspPass_Desc')}</Box>
                      </Grid>
                      <Grid item xs={6} lg={4} justifyContent="flex-end">
                        <Box sx={containerStyle.fieldContainer}>
                          <Box>
                            <Field
                              as={PasswordField}
                              label={rtspPassTitle}
                              name="rtspPass"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.rtspPass && errors.rtspPass}
                              error={touched.rtspPass && errors.rtspPass}
                            />
                          </Box>
                          <Box>
                            <Field
                              as={PasswordField}
                              label={getString('Confirm_Password_Title')}
                              name="rtspPassConfirm"
                              size="small"
                              style={containerStyle.textField}
                              helperText={touched.rtspPassConfirm && errors.rtspPassConfirm}
                              error={touched.rtspPassConfirm && errors.rtspPassConfirm}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default IDPassword;
