/**
 * Phone Settings _ has only 1 tab for now.
 */
import TabsContainer from 'shared/components/containers/TabsContainer';
import { DeviceTabType } from 'features/RemoteManagement/DeviceDashboard/DeviceTabs';
import { useSelector } from 'react-redux';
import { DeviceCategory } from 'features/RemoteManagement/Types';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { Box } from '@mui/material';
import { getDeviceCategoryFromDeviceTypeModel } from 'shared/utils/helperFunctions';

const PhoneSettings = () => {
  const selectedDevice = useSelector(getSelectedDevice)?.basicInfo;
  if (!selectedDevice) return <Box>Error Loading Device</Box>;
  const deviceCategory = getDeviceCategoryFromDeviceTypeModel(selectedDevice?.deviceType, selectedDevice?.deviceModel);
  // Tabs available on all Pages.
  let phoneSettingsTabs: DeviceTabType[] = [];

  switch (deviceCategory) {
    case DeviceCategory.GatewayAdaptor:
      phoneSettingsTabs = [DeviceTabType.Phone_PhoneSettings];
      break;
  }

  return <TabsContainer tabTypes={phoneSettingsTabs} />;
};

export default PhoneSettings;
