import { LoadingButton } from '@mui/lab';
import { FormikErrors } from 'formik';
import { analyticsService } from 'services/googleAnalyticsService';

interface QuikspecSubmitButtonProps {
  editing: boolean;
  errors: FormikErrors<any>;
  submitting?: boolean;
  C2ApiFailed?: boolean;
}

const styles = {
  submitButton: {
    mt: 2,
    mb: 4
  }
};

const QuikspecSubmitButton = ({ errors, editing, submitting, C2ApiFailed }: QuikspecSubmitButtonProps) => {
  const buttonLabel = editing ? 'Save Changes' : 'QuikSpec it!';

  const handleClick = () => {
    analyticsService.trackButtonClick('QuikSpec', buttonLabel);
  };

  return (
    <LoadingButton
      sx={styles.submitButton}
      type="submit"
      variant="contained"
      color="primary"
      loading={submitting}
      disabled={Object.keys(errors).length > 0 || C2ApiFailed}
      onClick={handleClick}
    >
      {buttonLabel}
    </LoadingButton>
  );
};

export default QuikspecSubmitButton;
