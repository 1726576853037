/**
 * This file defines a Redux slice responsible for managing the gateway data.
 * A Redux slice  is a collection of reducer logic and actions for a single feature in the app.
 */
import { createSlice } from '@reduxjs/toolkit';
import { aiphoneCloudApi } from 'services/aiphoneCloud';
// import { RootState } from 'store';

export type registerGatewayResponse = {
  gwMacAddress: string;
  gwId: string;
  gwPassword: string;
  ip_address: string;
  ip_subnet: string;
  ip_gateway: string;
  dns_primary: string;
};
export type stationInfo = {
  ip_ver: string;
  ip_addr: string;
  ip_subnet: string;
  ip_gateway: string;
  conn_port: string;
  device_type: string;
  station_name: string;
  station_number: string;
  location: string;
  fw_ver: string;
  sub_cpu_ver: string;
};
export type stationSearchResponse = {
  command_id: string;
  site_id: string;
  transaction_id: string;
  mac_addr: string;
  statusCode: string;
  payload: {
    [station_mac_addr: string]: {
      station_info: stationInfo;
    };
  };
};
export type sortStationPayload = [
  {
    station_info: stationInfo;
  }
];
export type associatePayload = {
  mac_addr: string;
  statusCode: string;
};
export type associateResponse = {
  command_id: string;
  site_id: string;
  transaction_id: string;
  mac_addr: string;
  statusCode: string;
  payload: [associatePayload];
};

interface GatewayState {
  awsPropertyId: string | null;
  isAwsPropertyIdReuse: boolean;
  gateway: [stationInfo] | undefined | null;
  stations: [stationInfo] | undefined | null;
  loading: boolean;
  error: string | null;
}

const initialState: GatewayState = {
  awsPropertyId: null,
  isAwsPropertyIdReuse: false,
  gateway: null,
  stations: null,
  loading: false,
  error: null
};

const findGateway = (payload: sortStationPayload) => {
  return payload.filter((station: { station_info: stationInfo }) => {
    if (station.device_type === 'IXGW-GW') {
      return station;
    }
  });
};

const findStations = (payload: sortStationPayload) => {
  return payload.filter((station: { station_info: stationInfo }) => {
    if (station.device_type !== 'IXGW-GW') {
      return station;
    }
  });
};

const gatewaySlice = createSlice({
  name: 'gateway',
  initialState,
  reducers: {
    addStationsFromGw: (state, action) => {
      state.gateway = action.payload;
    },
    resetGatewayState: (state) => {
      state.awsPropertyId = null;
      state.gateway = null;
      state.stations = null;
      state.loading = false;
    },
    addGatewayInfo: (state, action) => {
      const gwInfo = {
        gwMacAddress: action.payload.gwMacAddress,
        gwId: action.payload.gwId,
        gwPassword: action.payload.gwPassword
      };

      state.gateway = [gwInfo];

      if (action.payload.awsPropertyId) {
        state.awsPropertyId = action.payload.awsPropertyId;
      }
    },
    updateGWId: (state, action) => {
      // TODO: if user is reusing the AWS property ID, set the flag to true
      // stored the GW ID and Password
      state.awsPropertyId = action.payload;
    }
  },

  //The commands are the response commands from the gateway
  // https://github.com/AiphoneCorporation/ixgw_gw_remote_management/blob/main/doc/99_MQTT_COMMUNICATION_EXAMPLES.md#commands

  extraReducers: (builder) => {
    builder.addMatcher(aiphoneCloudApi.endpoints.handleGatewayCommand.matchFulfilled, (state, action) => {
      state.loading = false;

      /** Payload from checking gateway connections */
      if ('gateway_is_connected' in action.payload) {
        state.gateway = action.payload.gateway_is_connected;
        return;
      }

      // condition to check the type of payload
      if (typeof action.payload.body === 'string') {
        return;
      } else {
        // The gateway did not return any data for synced stations.
        if (action.payload.statusCode === 404) {
          state.error = 'Error: No payload found. Please try again.';
          return;
        }

        // return Error when the payload is empty
        if (Object.keys(action.payload).length === 0) {
          state.error = 'Error: Empty payload';
          return;
        }

        /** Add 402 Invalid Credential */
        if (action.payload.statusCode.includes('402')) {
          state.error = 'Error: Invalid Credential';
          return;
        }

        /** Add 420 statusCode: Occurs when gateway is unregister but still using the previous ID & Password */
        if (action.payload.statusCode.includes('420')) {
          state.error = 'Error: Invalid Credential';
          return;
        }

        // TODO: check the command_id and make helper function to sort the payload
        const awsPropertyId = action.payload.pk;
        const gwPayload = action.payload.payload;
        const commandId = action.payload.command_id;
        const isSuccess = action.payload.statusCode.includes('200');
        // TODO: if error message, set error message in Redux
        if (!isSuccess) {
          state.error = action.payload.statusCode;
          return;
        }
        switch (commandId) {
          // UN-REGISTER GATEWAY RESPONSE
          case '01FE':
            {
              const unRegisterGatewayResponse = gwPayload[0];
              state.awsPropertyId = awsPropertyId;
              state.gateway = [unRegisterGatewayResponse];
            }
            break;
          // REGISTER GATEWAY RESPONSE
          case '01FF':
            {
              state.awsPropertyId = awsPropertyId;
              state.gateway = [gwPayload[0]];
            }
            break;
          // SEARCH STATIONS RESPONSE
          case '0101':
            {
              const gatewayList = findGateway(gwPayload);
              const stationList = findStations(gwPayload);
              state.gateway = gatewayList;
              state.stations = stationList;
            }
            break;
          // Associate STATION RESPONSE
          case '0116':
            {
              const associationFailDevices = gwPayload.filter(
                (device: associatePayload) => !device.statusCode?.includes('200')
              );
              if (associationFailDevices.length > 0) {
                state.error = 'Error associating stations';
              }
            }
            break;
          // Sync STATION RESPONSE
          case '010A': {
            const syncFailDevices = gwPayload.filter((device: associatePayload) => !device.statusCode?.includes('200'));
            if (syncFailDevices.length > 0) {
              state.error = 'Error syncing stations';
            }
            break;
          }
          default:
            break;
        }
      }
    });
    builder.addMatcher(aiphoneCloudApi.endpoints.handleGatewayCommand.matchPending, (state) => {
      state.loading = true;
    });
    builder.addMatcher(aiphoneCloudApi.endpoints.handleGatewayCommand.matchRejected, (state) => {
      state.loading = false;
    });
  }
});

export const { addStationsFromGw, resetGatewayState, addGatewayInfo, updateGWId } = gatewaySlice.actions;

export const gatewayReducer = gatewaySlice.reducer;
