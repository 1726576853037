import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';

const useTosValueValidationSchema = (fieldName: string): Yup.StringSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const fieldErrorRequired = t('Field_Error_Required');
  const fieldErrorInvalid = t('Field_Error_Invalid');
  const formattedFieldRequired: string = StringUtils.format(fieldErrorRequired, fieldName);
  const formattedFieldInvalid: string = StringUtils.format(fieldErrorInvalid, fieldName);
  const tosRegexValidate = /^[0-9a-fA-F]{2}$/;

  return Yup.string().required(formattedFieldRequired).matches(tosRegexValidate, formattedFieldInvalid);
};

export default useTosValueValidationSchema;
