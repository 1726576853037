import { Alert, Box, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AuthContext } from 'context/AuthContext';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { BaseLoginStyles } from 'styles/form-input';
import '../../../../styles/frontshine.css';
import { LoadingButton } from '@mui/lab';
import { verifyMfaCode } from '../../../../shared/api/Aws/authApi';
import { useNavigate } from 'react-router-dom';
import MFAResendComponent from './MfaResendComponent';
import { EnumList, fetchLocalEnumList } from '../../../../shared/utils/EnumUtils';
import useAuthValidationSchemas from 'shared/utils/ValidationSchema/features/Auth/Auth';

type AlertPayload = {
  message: string;
  type: 'error' | 'success' | 'warning' | 'info';
  isOpen: boolean;
};

const MfaVerificationForm: React.FC = () => {
  const { setErrorMessage, errorMessage, session, mfaTypeId, username } = useContext(AuthContext);

  const enumList: EnumList = fetchLocalEnumList();

  const [alert, setAlert] = useState<AlertPayload>({ message: '', type: 'success', isOpen: false });
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setErrorMessage('');
  }, [setErrorMessage]);

  const { mfaValidation } = useAuthValidationSchemas();
  type FormValues = yup.InferType<typeof mfaValidation>;

  const handleVerificationCode = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    try {
      if (!mfaTypeId) return setAlert({ message: t('mfaTypeId is undefined'), type: 'error', isOpen: true });

      const payload = {
        userData: {
          email: username,
          mfaTypeId: mfaTypeId,
          mfaCode: values.verificationCode,
          session: session
        }
      };

      const response = await verifyMfaCode(payload);

      localStorage.setItem('refreshToken', response?.data.refreshToken);
      localStorage.setItem('token', response?.data.token);
      localStorage.setItem('userId', response?.data.userId);
      navigate('/');

      setSubmitting(false);
    } catch (error: { code: string }) {
      if (error.code === 'MFANotAuthorizedException') {
        setAlert({ message: t('MFA_not_authorized_error'), type: 'error', isOpen: true });
      } else if (error.code === 'MFACodeMismatchException' || error.code === 'MFAInvalidParameterException') {
        setAlert({ message: t('MFA_mismatch_code_error'), type: 'error', isOpen: true });
      } else if (error.code === 'MFALimitExceededException') {
        setAlert({ message: t('MFA_limit_exceeded_error'), type: 'error', isOpen: true });
      } else {
        setAlert({ message: 'Something went wrong. Please try again.', type: 'error', isOpen: true });
      }
    }
  };

  const getSubtitleText = () => {
    const selectedMfaType = enumList.mfaType[mfaTypeId]?.value;

    if (selectedMfaType === 'email') return t('MFA_Email_Code_Sent');
    else if (selectedMfaType === 'sms') return t('MFA_Phone_Code_Sent');
    else return t('MFA_Global_Code_Sent');
  };

  return (
    <Box>
      <SnackbarAlert
        type={alert.type}
        time={6000}
        text={alert.message}
        isOpen={alert.isOpen}
        onClose={() => setAlert({ ...alert, isOpen: false })}
      />
      <Formik initialValues={{ verificationCode: '' }} validationSchema={null} onSubmit={handleVerificationCode}>
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box sx={styles.titleWrapper}>
                  <Typography variant="h4" gutterBottom>
                    Multi-factor Authentication
                  </Typography>
                  <Typography>{t('MFA_Verification_Form_Title')}</Typography>
                  <Typography>{t('MFA_Verification_Form_Subtitle')}</Typography>
                </Box>
                <Field
                  name="verificationCode"
                  as={TextField}
                  sx={BaseLoginStyles.inputField}
                  label={t('MFA_Verification_Code')}
                  helperText={touched.verificationCode && errors.verificationCode}
                  error={Boolean(touched.verificationCode && errors.verificationCode)}
                />
                <Typography variant="caption" gutterBottom sx={{ margin: '0 10px 0 0' }}>
                  {getSubtitleText()}
                </Typography>
                <MFAResendComponent />
              </Grid>
              {errorMessage && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorMessage}</Alert>
                </Grid>
              )}
              <Grid item xs={12} sx={BaseLoginStyles.loginButtonContainer}>
                <Box sx={BaseLoginStyles.centerContent}>
                  <LoadingButton
                    type="submit"
                    sx={BaseLoginStyles.loginButton}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    variant="aiphoneOrange"
                    loadingIndicator={<CircularProgress size="20px" color="white" />}
                  >
                    {t('Button_Submit')}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const styles = {
  titleWrapper: {
    margin: '0 0 40px 0'
  }
};

export default MfaVerificationForm;
