import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import { RegexIpV6OrDomain } from 'features/RemoteManagement/Types';
import { ipV6AddressBlacklist } from './IpV6Address';

const useNtpIpV6AddressValidationSchema = (fieldName: string): Yup.StringSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldInvalid: string = StringUtils.format(t('Field_Error_Invalid'), fieldName);

  return Yup.string()
    .notOneOf(ipV6AddressBlacklist, formattedFieldInvalid)
    .matches(RegexIpV6OrDomain, formattedFieldInvalid);
};

export default useNtpIpV6AddressValidationSchema;
