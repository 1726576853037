/**
 * ToggleVisibilityPasswordField component
 *
 * This component is a drop-in replacement for the PasswordField component.
 * The major improvement is the onClick event handler, which sets to default.
 * Also, the tabIndex is set to -1 to prevent the focus from moving to the button.
 * It also retains the cursor position when toggling the password visibility.
 */
import React, { useRef, useState } from 'react';
import { IconButton, TextField, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Dots } from 'values/HTMLEntities';
import { FieldProps } from 'formik';

const ToggleVisibilityPasswordField: React.FC<FieldProps> = ({ field, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClickShowPassword = () => {
    if (inputRef.current) {
      const cursorPosition = inputRef.current.selectionStart;
      setShowPassword(!showPassword);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      {...field}
      {...props}
      inputRef={inputRef}
      placeholder={`${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}${Dots.medium}`}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              tabIndex={-1}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
      type={showPassword ? 'text' : 'password'}
    />
  );
};

export default ToggleVisibilityPasswordField;
