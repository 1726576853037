/* eslint-disable unused-imports/no-unused-vars */
/**
 * Sync functionality, Firmware functionality for the site dashboard.
 */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Alert, Box, Grid, Paper, Typography } from '@mui/material';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { Status } from 'shared/utils/Status';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import {
  useGetSiteWithPublicIdQuery,
  useHandleGatewayCommandMutation,
  useUpdateSyncedDateMutation,
  useLazyGetDeviceListWithSitePublicIdQuery
} from 'services/aiphoneCloud';
import { getAllSoundsBySiteId } from 'shared/api/Aws/RemoteManagementApi';
import { IRingtone } from 'features/RemoteManagement/DeviceDashboard/callSettings/outboundCallSetting/settings/RingBackTone';
import { useTranslation } from 'react-i18next';
import LoginDialog from './Dialogs/LoginDialog';
import SyncDialog from './Dialogs/SyncDialog';
import GWFirmwareDialog from './Dialogs/GWFirmwareDialog';
import ToolbarComponent from './Toolbar/ToolbarComponent';
import { RowElem, SiteGrid } from './Grid/SiteGrid';
import { computeCredentials, latestFirmwareUrl, parseS3Url } from './Utils/SiteUtil';
import IXGAppConfigurationCard from './CardComponents/IXGAppConfigurationCard';
import GatewayConfigurationCard from './CardComponents/GatewayConfigurationCard';
import GatewayFirmwareCard from './CardComponents/GatewayFirmwareCard';
import GatewayStatusCard from './CardComponents/GatewayStatusCard';
import { useSiteContext } from './SiteContext/SiteContext';
import { useParams, Link } from 'react-router-dom';
import { IDevice } from 'store/slices/devicesSlice';
import { CloudUser } from 'store/slices/usersSlice';
import { ISiteInfo } from 'store/slices/siteSlice';

interface ISyncS3Location {
  siteUUID: string;
  gatewayUUID: string;
  deviceUUID: string;
  deviceType: string;
  configFilePath: string;
  currentUser: string | undefined;
}

interface IGatewayInfo {
  awsPropertyId: string;
  gwMacAddress: string;
  gwId: string;
  gwPassword: string;
  gwIpAddress: string;
}

interface IDeviceInfo {
  deviceConfigFileUrl: string;
  deviceFileName: string;
  deviceId: string;
  deviceIpAddress: string;
  deviceMacAddress: string;
  devicePassword: string;
  deviceS3Url: string;
  deviceStationNumber: string;
  deviceType: string;
}

interface IGatewayInfo {
  awsPropertyId: string;
  gwId: string;
  gwIpAddress: string;
  gwMacAddress: string;
  gwPassword: string;
}

interface IGwCredential {
  id: string;
  password: string;
  isSuccess?: boolean;
}

interface ISite {
  siteInfo: ISiteInfo;
  Error: string | undefined;
  Loading: boolean;
  StatusCode: number;
  applicationType: string;
}

const Site = () => {
  const {
    rows,
    setRows,
    gwOnlineStatus,
    hasEditPermission,
    isGWRegistered,
    setIsGatewayRegistered,
    setIsGatewayUnregistered,
    setLatestFirmwareList,
    errorMessage,
    setErrorMessage,
    successMessage,
    showAlert,
    setShowAlert,
    showIXGSync,
    setShowIXGSync,
    setIsLoginDialogOpen,
    setIsSyncDialogOpen,
    buildDeviceRow,
    gwCredential
  } = useSiteContext();

  const DEFAULT_CREDENTIAL = 'admin';
  const { t } = useTranslation();

  const sitePublicId = useParams().id ?? '';

  const site = useSelector((state: RootState) => state.site);
  const users = useSelector((state: RootState) => state.users);
  const currentUser = users.currentUser;
  const { DeviceList: devices, DeviceListByType } = useSelector((state: RootState) => state.devices);

  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();
  const [updateSyncedDate] = useUpdateSyncedDateMutation();
  useGetSiteWithPublicIdQuery(sitePublicId, { skip: !sitePublicId });

  //Sync sound files variables
  const [ringtoneList, setRingtoneList] = useState<IRingtone[]>([]);

  const handleClose = () => {
    setIsLoginDialogOpen(false);
    setIsSyncDialogOpen(false);
  };

  const [handleGatewayCommand, { isLoading: isGwProcessingCommand }] = useHandleGatewayCommandMutation();
  const [deviceCredential, setDeviceCredential] = useState<Set<string>>(new Set());
  const [additionalHelperText, setAdditionalHelperText] = useState('');
  const [gateway, setGateway] = useState<IDevice | undefined>(undefined);

  useEffect(() => {
    let gatewayPublicId;
    gatewayPublicId = site?.siteInfo?.registeredGatewayPublicId;
    const gatewayAdapter = DeviceListByType['GatewayAdaptor'];

    if (!gatewayPublicId && gatewayAdapter) {
      gatewayPublicId = gatewayAdapter[0];
    }

    const gatewayInfo = devices[gatewayPublicId];
    setGateway(gatewayInfo);
  }, [DeviceListByType]);

  useEffect(() => {
    (async () => {
      const checkLatestFirmware = async () => {
        const firmwareVersionCheck = await fetch(latestFirmwareUrl);
        const latestVersion = await firmwareVersionCheck.json();
        setLatestFirmwareList(latestVersion);
      };
      await checkLatestFirmware();
    })();
  }, [gwCredential]);

  useEffect(() => {
    (async () => {
      const deviceRows = buildDeviceRow(Object.values(devices));
      const sortedRows = deviceRows.sort((a, b) => {
        if (a.ModelNumber === 'IXGW-GW' && b.ModelNumber !== 'IXGW-GW') {
          return -1;
        } else if (a.ModelNumber !== 'IXGW-GW' && b.ModelNumber === 'IXGW-GW') {
          return 1;
        } else {
          return 0;
        }
      });

      setRows(sortedRows);

      if (Object.keys(devices).length === 0) {
        // Set the gateway registration state to false because there are no devices.
        setIsGatewayRegistered(false);
      } else if (Object.keys(devices).length > 0) {
        setIsGatewayRegistered(gateway !== undefined);
        setIsGatewayUnregistered(true);
      }
    })();
  }, [devices, gateway, site]);

  useEffect(() => {
    /** Retrieving all audio files to generate the link for sending to the gateway. */
    if (site.siteInfo?.publicId && users.currentUser?.publicId) {
      const getAllSoundsPayload = {
        siteId: site.siteInfo.publicId,
        userId: users.currentUser?.publicId
      };
      (async () => {
        const allSounds = (await getAllSoundsBySiteId(getAllSoundsPayload)) as IRingtone[];
        if (allSounds?.length > 0) {
          setRingtoneList(allSounds);
        }
      })();
    }
  }, []);

  /****************************************   SYNC   ****************************************/

  // Helper functions
  const validateInitialConditions = (device: RowElem) => {
    if (device.ConfigFileUrl === '-') throw new Error(t('Config_file_is_Missing'));
    if (!gateway) throw new Error(t('Gateway_not_found'));
  };

  const prepareS3Location = (device: RowElem, site: ISite, gateway: IDevice, user: CloudUser) => {
    return {
      siteUUID: site?.siteInfo?.publicId,
      gatewayUUID: gateway?.publicId,
      deviceUUID: devices[device.id].publicId,
      deviceType: device.ModelNumber || '',
      configFilePath: device.ConfigFileUrl,
      currentUser: user?.publicId
    };
  };

  const prepareGatewayInfo = (site: ISite, gateway: IDevice, gwCredential: IGwCredential) => {
    return {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: gwCredential.id,
      gwPassword: gwCredential.password,
      gwIpAddress: gateway?.networkSettings?.ipV4Address || ''
    };
  };

  const prepareDeviceInfo = (selectedDevice: IDevice, device: RowElem, configFilePath: string) => {
    const { id, password } = computeCredentials(selectedDevice);

    return {
      deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address || '',
      deviceMacAddress: device.MacAddress,
      deviceStationNumber: device.StationNumber,
      deviceId: id,
      devicePassword: password,
      deviceConfigFileUrl: device.ConfigFileUrl,
      deviceType: device.ModelNumber || '',
      deviceFileName: `config-${device.id}.txt`,
      deviceS3Url: configFilePath
    };
  };

  const retryWithAlternateCredentials = async (
    gatewayInfo: IGatewayInfo,
    deviceInfo: IDeviceInfo,
    s3Location: ISyncS3Location,
    selectedDevice: IDevice,
    usedCredentials: Set<string>
  ) => {
    const updatedUsedCredentials = new Set(usedCredentials);

    // Prepare the list of credentials to be used for retry
    const credentials = [
      { id: selectedDevice?.basicInfo.memoId, password: selectedDevice?.basicInfo.memoPass },
      { id: selectedDevice?.basicInfo.adminId, password: selectedDevice?.basicInfo.adminPass },
      { id: DEFAULT_CREDENTIAL, password: DEFAULT_CREDENTIAL }
    ];

    // find the rejected credential
    if (!deviceInfo.deviceId || !deviceInfo.devicePassword) {
      console.error('Device ID or password is missing');
      return;
    }

    const rejectedCredential = `${deviceInfo.deviceId}:${deviceInfo.devicePassword}`;
    updatedUsedCredentials.add(rejectedCredential);
    setDeviceCredential((prev) => prev.add(rejectedCredential));

    const filteredCredentials = Array.from(credentials).filter(
      (cred) => !updatedUsedCredentials.has(`${cred.id}:${cred.password}`)
    );

    if (filteredCredentials.length === 0) {
      console.error('All credentials have been attempted and failed. No more retries possible.');
      throw new Error(t('Gateway_Error_message.RM-GW-004'));
    }

    // Iterate through the remaining credentials
    for (const { id, password } of filteredCredentials) {
      // Update deviceInfo with the current credential
      const updatedDeviceInfo = {
        ...deviceInfo,
        deviceId: id,
        devicePassword: password
      };

      try {
        // Attempt the sync operation with the updated credentials
        await executeSyncCommand(gatewayInfo, updatedDeviceInfo, s3Location, selectedDevice, updatedUsedCredentials);
        return true;
      } catch (error) {
        console.error('Credential failed:', { id, password }, error);
      }
    }
    return false;
  };

  const handleResponse = async (
    response: any,
    gatewayInfo: IGatewayInfo,
    deviceInfo: IDeviceInfo,
    s3Location: ISyncS3Location,
    selectedDevice: IDevice,
    updatedUsedCredentials: Set<string>
  ) => {
    if (response.statusCode.includes('202')) {
      // This is for the latest firmware statuscode to show command is accepted by the gateway, so we can ignore it and continue polling
      return;
    }

    const statusCodeFromPayload = response?.payload[0].statusCode.substring(0, 3);

    switch (statusCodeFromPayload) {
      case '200':
        return Status.Synced;

      // 401 - Config file key is invalid
      case '401':
        throw new Error(t('Gateway_Error_message.RM-GW-006'));

      case '402': {
        const retrySuccessful = await retryWithAlternateCredentials(
          gatewayInfo,
          deviceInfo,
          s3Location,
          selectedDevice,
          updatedUsedCredentials
        );
        if (!retrySuccessful) {
          throw new Error(t('Gateway_Error_message.RM-GW-004'));
        } else {
          return Status.Synced;
        }
      }

      case '413':
        throw new Error(t('Gateway_Error_message.RM-GW-010'));

      case '404':
        return Status.Waiting;
      // throw new Error(t('Gateway_Error_message.RM-GW-025'));

      default:
        setErrorMessage(t('Gateway_Error_message.RM-GW-003'));
        break;
    }
  };

  const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const executeSyncCommand = async (
    gatewayInfo: IGatewayInfo,
    deviceInfo: IDeviceInfo,
    s3Location: ISyncS3Location,
    selectedDevice: IDevice,
    updatedUsedCredentials: Set<string>
  ) => {
    const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
    if (!ioTPayload || (typeof ioTPayload.payload === 'string' && ioTPayload.payload === 'Missing information'))
      throw new Error('Payload preparation failed');

    const payloadWithConfig = { ...ioTPayload, configFileLocation: s3Location };

    try {
      payloadWithConfig.configFileLocation = s3Location;

      await handleGatewayCommand(ioTPayload).unwrap();

      // Wait for an initial delay of 10 seconds before starting the fetch loop
      await wait(20000);

      const maxDuration = 3 * 60 * 1000; // 3 minutes
      const fetchInterval = 15000; // 15 seconds
      const startTime = Date.now();

      let response: Status | undefined;

      while (Date.now() - startTime < maxDuration) {
        // Fetch result payload
        const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.SYNC, gatewayInfo, deviceInfo, null);
        const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

        // Handle the response and if the sync was successful, break out of the loop

        response = await handleResponse(
          fetchResponse,
          gatewayInfo,
          deviceInfo,
          s3Location,
          selectedDevice,
          updatedUsedCredentials
        );
        if (response === Status.Synced) {
          return;
        } else {
          // Wait for the next interval
          await wait(fetchInterval);
        }
      }

      if (response !== Status.Synced) {
        throw new Error(t('Gateway_Error_message.RM-GW-002'));
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error(String(error));
      }
    }
  };

  const handleConfigFileUpload = async (device: RowElem) => {
    const selectedDevice = devices[device.id];

    try {
      validateInitialConditions(device);

      const configFilePath = parseS3Url(device.ConfigFileUrl);

      if (!gateway) {
        throw new Error('gateway not available');
      }
      if (!currentUser) {
        throw new Error('current user not available');
      }

      const s3Location = prepareS3Location(device, site, gateway, currentUser);
      const gatewayInfo = prepareGatewayInfo(site, gateway, gwCredential[0]);
      let deviceInfo;

      if (configFilePath) {
        deviceInfo = prepareDeviceInfo(selectedDevice, device, configFilePath);

        // if the device type is IXG-GW, add the gateway credentials to the device info that is working
        if (deviceInfo.deviceType === 'IXGW-GW') {
          deviceInfo = { ...deviceInfo, deviceId: gwCredential[0].id, devicePassword: gwCredential[0].password };
        }
      } else {
        throw new Error('Config file path is null');
      }

      if (gwOnlineStatus !== Status.Online) {
        setErrorMessage(t('Gateway_offline'));
        return;
      }

      if (!isGwProcessingCommand && gwOnlineStatus === Status.Online) {
        const response = await executeSyncCommand(
          gatewayInfo,
          deviceInfo,
          s3Location,
          selectedDevice,
          new Set<string>()
        );

        // Update the lastSyncedOn date for the device
        const params = {
          device: {
            publicId: selectedDevice.publicId
          }
        };
        await updateSyncedDate(params);

        //Fetch devices to update the state
        await fetchDevices({ sitePublicId, qty: -1, page: 0 });
        return Status.Synced;
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        if (error.message.includes('RM-GW-004')) {
          setAdditionalHelperText('RM-GW-004');
        }
      } else {
        setErrorMessage(t('Failed_to_sync_with_IXG_Cloud'));
      }
      return Status.Error;
    }
  };

  return (
    <>
      <Paper elevation={3} sx={styles.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Box sx={styles.title}>{t('Gateway_Action_Center')}</Box>
              {!hasEditPermission && <Box sx={styles.viewPermission}>{t('View_only_Permission')}</Box>}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <GatewayStatusCard />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <GatewayFirmwareCard />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  {gateway && (
                    <GatewayConfigurationCard gateway={gateway} handleConfigFileUpload={handleConfigFileUpload} />
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <IXGAppConfigurationCard />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          {additionalHelperText && (
            <Grid item xs={12}>
              <Box sx={{ p: 2 }}>
                <Alert severity="warning">
                  <Typography>{t('Gateway_Work_Around')}</Typography>
                  <Link to="/support" style={{ color: 'inherit', textDecoration: 'underline' }}>
                    {t('Tech_Support')}
                  </Link>
                </Alert>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box sx={styles.title}>{t('Station_Manager')}</Box>
                  {!isGWRegistered && (
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    <Alert severity="warning" onClose={() => {}}>
                      {t('Gateway_not_registered_warning')}
                    </Alert>
                  )}
                  {gateway && <ToolbarComponent gateway={gateway} handleConfigFileUpload={handleConfigFileUpload} />}
                  <SiteGrid handleConfigFileUpload={handleConfigFileUpload} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <SyncDialog handleClose={handleClose} />
      <LoginDialog handleClose={handleClose} />
      <GWFirmwareDialog />
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={successMessage ?? ''}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
      <SnackbarAlert
        type="success"
        time={3000}
        text={t('Success_Synced_Device')}
        isOpen={showIXGSync}
        onClose={() => setShowIXGSync(false)}
      />
    </>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  paper: {
    padding: '5px',
    height: '100%'
  },
  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    marginBottom: '20px'
  },
  dataGrid: {
    '& .custom-column': {
      backgroundColor: '#f5f5f5',
      border: '1px solid #ccc'
    }
  },
  icon: {
    fontSize: 24
  },
  avatars: {
    width: 40,
    height: 30,
    marginRight: '5px',
    backgroundColor: 'transparent'
  },
  gwContainer: {
    display: 'left',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'left',
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '1rem'
  },
  toolboxContainer: {
    display: 'left',
    justifyContent: 'end'
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  viewPermission: {
    display: 'flex',
    fontWeight: 'bold',
    padding: '5px',
    margin: '10px',
    backgroundColor: '#f5f5f5'
  }
};

export default Site;
