import React from 'react';
import { Card, CardContent, CardHeader, CardMedia, Grid } from '@mui/material';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import ProductPopover from 'features/QuikSpec/Components/UiParts/ProductPopover';
import { Item } from 'features/QuikSpec/Types/QuikSpecTypes';
import { useQuikSpecViewDetail } from 'features/QuikSpec/Hooks/quikSpecDevices';

interface Props {
  item: Item;
  index: number;
  isSelected: boolean;
}

const ProductCardRadioButton = ({ item, index, isSelected }: Props) => {
  const { deviceName } = item;
  const { description } = useQuikSpecViewDetail();

  const isValidDescriptionItemKey = React.useCallback(
    (key: any): key is keyof typeof description.item => {
      return key in description.item;
    },
    [description.item]
  );
  const deviceKey: string = deviceName.replace(/-/g, '').replace(/\//g, '_');
  return (
    <Grid item key={index}>
      <Card sx={isSelected ? styles.selectedCard : styles.card}>
        <CardHeader
          title={deviceName}
          sx={styles.header}
          titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
        />
        <CardContent sx={styles.cardContent}>
          <CardMedia
            component="img"
            image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/${deviceName}.jpg`}
            sx={styles.deviceImage}
          />
          {isValidDescriptionItemKey(deviceKey) && <ProductPopover description={description.item[deviceKey]} />}
        </CardContent>
      </Card>
    </Grid>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  card: {
    margin: '20px',
    width: '185px',
    height: 'auto',
    boxShadow: '3',
    position: 'relative'
  },
  header: {
    textAlign: 'center',
    background: 'linear-gradient(to right, #1e3c72, #2a5298)',
    color: '#fff',
    padding: '4px',
    boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
    fontSize: '1.1rem',
    fontWeight: '200'
  },
  cardContent: {
    position: 'relative',
    textAlign: 'center'
  },
  deviceImage: {
    objectFit: 'contain',
    width: '120px',
    height: '120px',
    margin: 'auto',
    display: 'block'
  },
  selectedCard: {
    margin: '20px',
    width: '185px',
    height: 'auto',
    boxShadow: '3',
    position: 'relative',
    border: '5px solid #1976d2'
  }
};

export default ProductCardRadioButton;
