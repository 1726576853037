/********************* Remote management ************************** */

import {
  AssignUsertoSiteParams,
  CreateSiteParams,
  DataFormatSendToGWParams,
  FetchPreSignUrlParams,
  GetMyOrgSitesParams,
  GetMySitesParams,
  GetOrgByIdParams,
  GetSiteByIdParams,
  GetUserByIdParams,
  OrgCreateParams,
  SendInvitationEmailParams,
  UpdateSiteParams,
  UserCreateParams,
  remoteManagementEmailParams,
  accountDeletionEmailParams,
  ICreateDevicesParams
} from './apiParamsTypes';
import axios from 'axios';
import CONFIG from '../../../config';
import axiosApiInstance from '../axiosConfig';
import { ApiError, classifyAndThrowError, ErrorType } from '../ApiError';

/*
 * Step #1: Register IXG-GW
 * input param example:
 * {
 *   "command_id": "string",
 *   "site_id": "string",
 *   "transaction_id": "uuid string",
 *   "mac_addr": "string",
 *   "payload": { **EMPTY** }
 *   "payload": { "timeout": "5" } <---- OMLY for timeout with search command - 0001
 *  }
 */
export const sendGatewayCommand = async (params: DataFormatSendToGWParams) => {
  try {
    const response = await axiosApiInstance.post(CONFIG.apiEndpoint + '/v1/gateway', params);
    if (response?.data.length === 0) {
      throw new Error('Data not found, Please try it again');
    }
    switch (response.status) {
      case 200: {
        if (response?.data.length === 0) {
          throw new Error('Data not found');
        }
        if (response?.data === null) {
          throw new Error('Sync failed');
        }
        return response?.data;
        break;
      }
      case 204: {
        return response;
      }
      default:
        return response.data;
    }
  } catch (error) {
    classifyAndThrowError(error);
  }
};

/** Fetch the presignURL that allows user to upload setting files*/
export const fetchPreSignUrl = (params: FetchPreSignUrlParams) => {
  return axios.get(CONFIG.apiEndpoint + '/fileUpload', {
    headers: {
      Authorization: JSON.stringify({ token: localStorage.getItem('token') })
    },
    params
  });
};

// send email to user
export const remoteManagementEmail = async (params: remoteManagementEmailParams) => {
  try {
    const response = await axiosApiInstance.put(
      CONFIG.apiEndpoint + '/remoteManagementEmail?action=emailPromotedUser',
      params
    );
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const accountDeletedEmail = async (params: accountDeletionEmailParams) => {
  try {
    const response = await axiosApiInstance.put(
      CONFIG.apiEndpoint + '/remoteManagementEmail?action=accountDeleted',
      params
    );
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

/** Process Site */
export const createSite = async (params: CreateSiteParams) => {
  return await axiosApiInstance.post(CONFIG.apiEndpoint + '/site', params);
};
export const updateSite = async (params: UpdateSiteParams) => {
  try {
    const response = await axiosApiInstance.put(CONFIG.apiEndpoint + '/site?action=updateSite', params);
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getSiteById = async (params: GetSiteByIdParams) => {
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/site', { params });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getMySites = async (params: GetMySitesParams) => {
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/sites/mySites', {
      params: params
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const createDevices = async (params: ICreateDevicesParams) => {
  try {
    const response = await axiosApiInstance.post(CONFIG.apiEndpoint + '/device?action=createDevice', params);
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

/** Process Cloud User */
export const createUser = async (params: UserCreateParams) => {
  try {
    return await axios.post(CONFIG.openApiEndpoint + '/registeruser', params);
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getUserById = async ({ userSub }: GetUserByIdParams) => {
  const params = {
    action: 'getUserById',
    callingUserPublicId: userSub,
    targetUserPublicId: userSub
  };
  try {
    const response = await axiosApiInstance({
      method: 'get',
      url: CONFIG.apiEndpoint + '/user',
      params
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getAllUsers = async () => {
  const params = {
    action: 'getAllUsers'
  };
  try {
    const response = await axiosApiInstance({
      method: 'get',
      url: CONFIG.apiEndpoint + 'user',
      params
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const sendInvitationEmail = async (params: SendInvitationEmailParams) => {
  try {
    const response = await axiosApiInstance.post(CONFIG.apiEndpoint + '/users/sendEmail', params);
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const assignUsertoSite = async (params: AssignUsertoSiteParams) => {
  return await axiosApiInstance.post(CONFIG.apiEndpoint + '/users/assignuser', params);
};

/** Process Organization */
export const createOrganization = async (organizationInfo: OrgCreateParams) => {
  try {
    const response = await axiosApiInstance({
      method: 'post',
      url: CONFIG.apiEndpoint + '/organization',
      params: {
        action: 'createOrganization'
      },
      data: organizationInfo
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getOrganizationById = async ({ userId, orgId }: GetOrgByIdParams) => {
  const params = {
    action: 'getOrganizationById',
    callingUserPublicId: userId,
    organizationPublicId: orgId
  };
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/organization', { params });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getMyOrganizationSites = async (params: GetMyOrgSitesParams) => {
  try {
    const response = await axiosApiInstance.get(CONFIG.apiEndpoint + '/sites/myOrganizationSites', {
      params: params
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
export const getEnumFullList = async () => {
  try {
    const response = await axiosApiInstance.get(CONFIG.openApiEndpoint + '/enum?action=getFullList');
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const getAllSoundsBySiteId = async (payload: { siteId: string; userId: string }) => {
  const params = {
    action: 'getAllSoundsBySiteId',
    sitePublicId: payload.siteId,
    userPublicId: payload.userId
  };
  try {
    const response = await axiosApiInstance({
      method: 'get',
      url: CONFIG.apiEndpoint + '/soundFunctions',
      params
    });
    switch (response.status) {
      case 200:
        return response.data;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

/********************* END Remote management ************************** */
