import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import { RegexIpV6 } from 'features/RemoteManagement/Types';

export const ipV6AddressBlacklist: string[] = ['::'];

const useIpV6AddressValidationSchema = (fieldName: string): Yup.StringSchema => {
  const { t } = useTranslation(); // Initialize the translation hook
  const formattedFieldInvalid: string = StringUtils.format(t('Field_Error_Invalid'), fieldName);

  return Yup.string().notOneOf(ipV6AddressBlacklist, formattedFieldInvalid).matches(RegexIpV6, formattedFieldInvalid);
};

export default useIpV6AddressValidationSchema;
