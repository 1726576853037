import { LoadingButton } from '@mui/lab';
import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBatchUpdateDevicesMutation, useLazyGetDeviceListWithSitePublicIdQuery } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { ICodeListEntry } from 'store/slices/devicesSlice';
import { IEntrancePanelPayload } from '../../QRAccessCodes';

interface IQRAccessControlPanelProps {
  needsSave: boolean;
  setNeedsSave: (value: boolean) => void;
  setSharedAccessCode: (value: string) => void;
  entrancePanelsToUpdate: IEntrancePanelPayload;
}

interface IDevicePayload {
  sitePublicId: string | undefined;
  publicId: string;
  entrancePanelSettings: {
    displayUnlockCodeList?: ICodeListEntry[];
    qrCodeEnabled?: boolean;
  };
}

const strings = {
  title: 'Access Code Settings',
  description: 'Enter an access code to be typed in at the entrance panel to unlock the door.'
};

const QRAccessControlPanel = ({
  needsSave,
  setNeedsSave,
  setSharedAccessCode,
  entrancePanelsToUpdate
}: IQRAccessControlPanelProps) => {
  const [batchUpdateDevices] = useBatchUpdateDevicesMutation();
  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [tempAccessCode, setTempAccessCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const sitePublicId = useParams().id;

  useEffect(() => {
    if (Object.keys(entrancePanelsToUpdate).length > 0) {
      setNeedsSave(true);
    }
  }, [entrancePanelsToUpdate]);

  const buildPayload = (entrancePanelsToUpdate: IEntrancePanelPayload) => {
    const payload = Object.entries(entrancePanelsToUpdate).map(([key, value]) => {
      const payload: IDevicePayload = {
        sitePublicId: sitePublicId,
        publicId: key,
        entrancePanelSettings: {
          ...(value.displayUnlockCodeList && { displayUnlockCodeList: value.displayUnlockCodeList }),
          ...(value.qrCodeEnabled !== undefined && { displayQrCodeScan: value.qrCodeEnabled })
        }
      };

      return payload;
    });

    return payload;
  };

  const handleSave = () => {
    const params = buildPayload(entrancePanelsToUpdate);
    setIsSaving(true);
    batchUpdateDevices({ devices: params })
      .unwrap()
      .then(() => {
        setNeedsSave(false);
        setIsSaving(false);
        setSuccessMessage('Device settings saved successfully');
        fetchDevices({ sitePublicId: sitePublicId, qty: -1, page: 0 });
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setIsSaving(false);
      });
  };

  const handleApplyToAll = () => {
    setSharedAccessCode(tempAccessCode);
    setNeedsSave(true);
  };

  return (
    <>
      <SnackbarAlert
        type="success"
        time={7000}
        text={`${successMessage}`}
        isOpen={!!successMessage}
        onClose={() => setSuccessMessage('')}
      />
      <SnackbarAlert
        type="error"
        time={7000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage('')}
      />
      <Box style={styles.QRAccessControlPanelWrapper}>
        <Box sx={styles.descriptionWrapper}>
          <Box sx={styles.title}>{strings.title}</Box>
          <Box sx={styles.description}> {strings.description}</Box>
        </Box>
        <Box sx={styles.accessCodeWrapper}>
          <TextField
            label="Shared Access Code"
            variant="outlined"
            size="small"
            value={tempAccessCode}
            onChange={(event) => setTempAccessCode(event.target.value)}
            helperText="This code will be applied to all entrance panels"
          />
        </Box>
        <Box sx={styles.applyAllWrapper}>
          <Button variant="contained" onClick={handleApplyToAll}>
            Apply to all
          </Button>
        </Box>
        <Box style={styles.buttonsWrapper}>
          <LoadingButton variant="outlined" disabled={!needsSave} onClick={handleSave} loading={isSaving}>
            Save changes
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

const styles = {
  QRAccessControlPanelWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  descriptionWrapper: {
    width: '50%'
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  description: {},
  buttonsWrapper: {
    display: 'flex',
    width: '30%',
    justifyContent: 'end'
  },
  applyAllWrapper: {
    display: 'flex',
    width: '30%',
    justifyContent: 'center'
  },
  accessCodeWrapper: {
    marginTop: '20px',
    display: 'flex',
    width: '70%',
    justifyContent: 'end'
  }
};

export default QRAccessControlPanel;
