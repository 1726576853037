import { FormValues } from '../Types/QuikspecFormTypes';
import { updateFormValues } from './QuikspecUpdateFormValues';

export const updateFormValuesLEF = (values: FormValues) => {
  values = updateFormValues(values);
  updateItemQuantityWithAccessoriesLEF(values);
  updateWiresLEF(values);
  updateNotesLEF(values);
  return values;
};

const updateItemQuantityWithAccessoriesLEF = (values: FormValues) => {
  const items = Object.values(values.items);

  const PS1225ULIndex = items.findIndex((item) => item.itemName === 'PS-1225UL');
  const PS1208ULIndex = items.findIndex((item) => item.itemName === 'PS-1208UL');

  // Power Supply
  if (
    items.filter((item) => item.itemName === 'BG-10C')?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    items[PS1225ULIndex].quantity = 1;
  } else {
    items[PS1208ULIndex].quantity = 1;
  }
};

const updateNotesLEF = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'RY-PA')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push('One RY-PA is required for each door where release is required.');
    values.notes.push(
      'The RY-PA provides a normally open dry contact. Door release mechanisms and power for them are not included in this quote.'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-AC/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push(
      "One RY-AC is required for each door that will activate an external device.If not all door stations require external signaling, subtract that number of RY-AC's."
    );
    values.notes.push(
      'The RY-AC provides a momentary dry contact when the door calls in. An external device with its own power supply may be connected to this contact. (Not supplied by Aiphone.)'
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'BG-10C')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push('A music source with a line level output is required. Not supplied by Aiphone.');
    if (
      values.items
        .filter((item) => item.category === 'Door Stations')
        ?.reduce((total: number, item) => total + item.quantity, 0) > 3
    ) {
      values.notes.push(
        'Maximum 3 door stations can activate a chime tone using the LEF system. If more than three doors must activate the chime, please contact Aiphone Technical Support for more information.'
      );
    }
  }

  if (
    values.items
      .filter((item) => item.itemName === 'NP-25V')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.notes.push('One NP-25V capacitor is required for each sub station receiving music and/or all call.');
    if (
      values.items
        .filter((item) => item.category === 'Door Stations')
        ?.reduce((total: number, item) => total + item.quantity, 0) > 3
    ) {
      values.notes.push(
        'If door stations will be recieving the All Call/BackGround Music, add one NP-25V for each door.'
      );
    }
  }
};

const updateWiresLEF = (values: FormValues) => {
  if (
    values.items
      .filter((item) => item.itemName === 'LEF-3')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2
  ) {
    values.wires.push({
      system: 'Between Masters',
      wire: '82221050C',
      description: "10 conductor, 22AWG, Shielded Wire, 500'",
      maxDistance: "650'",
      jacketNomOD: 'PVC .250"',
      capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
    });
  } else if (
    values.items
      .filter((item) => item.itemName === 'LEF-3C')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2 ||
    values.items
      .filter((item) => item.itemName === 'LEF-3L')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2
  ) {
    values.wires.push({
      system: 'Between Masters',
      wire: '82221250C',
      description: "12 conductor, 22AWG, Shielded Wire, 500'",
      maxDistance: "650'",
      jacketNomOD: 'PVC .265"',
      capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
    });
  } else if (
    values.items
      .filter((item) => item.itemName === 'LEF-5')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2
  ) {
    values.wires.push(
      {
        system: 'Between Masters without door release',
        wire: '82221250C',
        description: "12 conductor, 22AWG, Shielded Wire, 500'",
        maxDistance: "650'",
        jacketNomOD: 'PVC .265"',
        capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
      },
      {
        system: 'Between Masters with door release',
        wire: '82222050C',
        description: "20 conductor, 22AWG, Shielded Wire, 500'",
        maxDistance: "650'",
        jacketNomOD: 'PVC .341"',
        capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
      }
    );
  } else if (
    values.items
      .filter((item) => item.itemName === 'LEF-5C')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2
  ) {
    values.wires.push(
      {
        system: 'Between Masters without door release',
        wire: '82221550C',
        description: "15 conductor, 22AWG, Shielded Wire, 500'",
        maxDistance: "650'",
        jacketNomOD: 'PVC .310"',
        capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
      },
      {
        system: 'Between Masters with door release',
        wire: '82222050C',
        description: "20 conductor, 22AWG, Shielded Wire, 500'",
        maxDistance: "650'",
        jacketNomOD: 'PVC .341"',
        capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
      }
    );
  } else if (
    values.items
      .filter((item) => item.itemName === 'LEF-10')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2 ||
    values.items
      .filter((item) => item.itemName === 'LEF-10C')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2 ||
    values.items
      .filter((item) => item.itemName === 'LEF-10S')
      ?.reduce((total: number, item) => total + item.quantity, 0) >= 2
  ) {
    values.wires.push(
      {
        system: 'Between Masters',
        wire: '82222050C',
        description: "20 conductor, 22AWG, Shielded Wire, 500'",
        maxDistance: "650'",
        jacketNomOD: 'PVC .341"',
        capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
      },
      {
        system: 'Plus when door release',
        wire: '82221050C',
        description: "10 conductor, 22AWG, Shielded Wire, 500'",
        maxDistance: "650'",
        jacketNomOD: 'PVC .250"',
        capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
      }
    );
  }

  if (
    values.items
      .filter((item) => item.category === 'Sub Stations')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'From Sub to Master',
      wire: '82220350C',
      description: "3 conductor, 22AWG, Shielded Wire, 500'",
      maxDistance: "650'",
      jacketNomOD: 'PVC .250"',
      capacitanceLoopRes: '48.2 pf/ft, 16.6 Ohms'
    });
  }

  if (
    values.items
      .filter((item) => item.category === 'Door Stations')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push(
      {
        system: 'From Door to Master',
        wire: '82220350C',
        description: "3 conductor, 22AWG, Shielded Wire, 500'",
        maxDistance: "650'",
        jacketNomOD: 'PVC .250"',
        capacitanceLoopRes: '48.2 pf/ft, 16.6 Ohms'
      },
      {
        system: 'Or',
        wire: '82180310C',
        description: "3 conductor, 18AWG, Shielded Wire, 1000'",
        maxDistance: "1600'",
        jacketNomOD: 'PVC .188"',
        capacitanceLoopRes: '55 pf/ft, 6.45 Ohms'
      }
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-PA')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'From RY-PA to Master',
      wire: '82220210C',
      description: "2 conductor, 22AWG, Shielded Wire, 1000'",
      maxDistance: 'N/A',
      jacketNomOD: 'PVC .140"',
      capacitanceLoopRes: '29.6 pf/ft, 14.8 Ohms'
    });
  }

  if (
    values.items
      .filter((item) => item.itemName === 'RY-AC/A')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push(
      {
        system: 'From RY-AC to Master',
        wire: '82220350C',
        description: "3 conductor, 22AWG, Shielded Wire, 500'",
        maxDistance: "650'",
        jacketNomOD: 'PVC .250"',
        capacitanceLoopRes: '48.2 pf/ft, 16.6 Ohms'
      },
      {
        system: 'From RY-AC to external device',
        wire: '82220210C',
        description: "2 conductor, 22AWG, Shielded Wire, 1000'",
        maxDistance: 'N/A',
        jacketNomOD: 'PVC .140"',
        capacitanceLoopRes: '29.6 pf/ft, 14.8 Ohms'
      }
    );
  }

  if (
    values.items
      .filter((item) => item.itemName === 'BG-10C')
      ?.reduce((total: number, item) => total + item.quantity, 0) > 0
  ) {
    values.wires.push({
      system: 'From BG-10C to Master',
      wire: '82222050C',
      description: "20 conductor, 22AWG, Shielded Wire, 500'",
      maxDistance: "650'",
      jacketNomOD: 'PVC .341"',
      capacitanceLoopRes: '48.2 pf/ft, 14.8 Ohms'
    });
  }
};
