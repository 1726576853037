import React from 'react';
import { Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import { AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT } from 'shared/constantAwsApi';
import ProductPopover from 'features/QuikSpec/Components/UiParts/ProductPopover';
import QuantityInputForm from 'features/QuikSpec/Components/UiParts/QuantityInputForm';
import { Item } from 'features/QuikSpec/Types/QuikSpecTypes';
import { useQuikSpecViewDetail } from 'features/QuikSpec/Hooks/quikSpecDevices';

interface ProductCardProps {
  item: Item;
  index: number;
  isSelected?: boolean;
}

const ProductCard = ({ item, index, isSelected }: ProductCardProps) => {
  const {
    deviceName,
    licenseDescription,
    multipleAllowed,
    maxQuantityAllowedPerItem,
    accessories,
    imageExtension,
    readOnly
  } = item;

  const { description } = useQuikSpecViewDetail();

  const isValidDescriptionItemKey = React.useCallback(
    (key: any): key is keyof typeof description.item => {
      return key in description.item;
    },
    [description.item]
  );

  const deviceKey: string = deviceName.replace(/-/g, '').replace(/\//g, '_');
  return (
    <Grid item key={index}>
      <Card sx={{ ...styles.card, ...(isSelected ? styles.selectedCard : {}) }}>
        <CardHeader
          title={deviceName}
          sx={styles.header}
          titleTypographyProps={{ fontSize: '1.3rem', fontWeight: '500' }}
        />
        <CardContent sx={styles.cardContent}>
          <CardMedia
            component="img"
            image={`${AIPHONE_CLOUD_AWS_S3_IMAGE_ENDPOINT}/${deviceName.replace('/', '')}.${imageExtension || 'jpg'}`}
            sx={styles.deviceImage}
          />
          {licenseDescription && <Typography> {licenseDescription} </Typography>}
          {isValidDescriptionItemKey(deviceKey) && <ProductPopover description={description.item[deviceKey]} />}
        </CardContent>
        <CardActions sx={styles.cardFooter}>
          <QuantityInputForm
            itemName={deviceName}
            multipleAllowed={!!multipleAllowed}
            maxQuantityPerItem={maxQuantityAllowedPerItem}
            accessories={accessories}
            isReadOnly={readOnly}
            isSelected={isSelected}
          />
        </CardActions>
      </Card>
    </Grid>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  card: {
    margin: '20px',
    width: '185px',
    height: 'auto',
    boxShadow: '3',
    position: 'relative'
  },
  selectedCard: {
    margin: '20px',
    width: '200px',
    height: 'auto',
    boxShadow: '3',
    position: 'relative',
    border: '5px solid #1976d2'
  },
  header: {
    textAlign: 'center',
    background: 'linear-gradient(to right, #1e3c72, #2a5298)',
    color: '#fff',
    padding: '4px',
    boxShadow: 'inset 0 -1px 0 0 #e0e0e0',
    fontSize: '1.1rem',
    fontWeight: '200'
  },
  cardContent: {
    position: 'relative',
    textAlign: 'center',
    padding: 1
  },
  deviceImage: {
    objectFit: 'contain',
    width: '120px',
    height: '120px',
    margin: 'auto',
    display: 'block'
  },
  infoPopover: {
    marginTop: '5px',
    marginBottom: '5px'
  },
  cardFooter: {
    background: 'rgba(0,0,0,0.03)',
    border: '1px solid rgba(211,211,211,0.6)'
  }
};

export default ProductCard;
