/**
 * Sound Settings has 3 tabs (Custom Sound Registry, Volume, Communication)
 * user can navigate through each tab setting for each device
 */
import TabsContainer from 'shared/components/containers/TabsContainer';
import { DeviceTabType } from '../DeviceTabs';

const SoundSettings = () => {
  const SoundSettingsTabs: DeviceTabType[] = [
    //Hiding Custom Sounds, non-mvp: DeviceTabType.SoundSettings_CustomSoundRegistry,
    DeviceTabType.SoundSettings_Volume
    //Hiding Communication, non-mvp: DeviceTabType.SoundSettings_Communication
  ];

  return <TabsContainer tabTypes={SoundSettingsTabs} />;
};

export default SoundSettings;
