import { Card } from '@mui/material';
import QuikspecHome from './Children/QuikspecHome';

const Quikspec = () => {
  return (
    <Card>
      <QuikspecHome></QuikspecHome>
    </Card>
  );
};

export default Quikspec;
