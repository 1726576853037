import React from 'react';
import { Typography } from '@mui/material';
import BasicFrame from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/other/BasicFrame';
import LiftControlTabs, {
  TabView
} from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/other/LiftControlTabs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLazyGetDeviceListWithSitePublicIdQuery, useUpdateDeviceMutation } from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import PickupFloorLC from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/datagrids/PickupFloorLC';
import { GridColumnHeaderParams, GridRowId, GridRowModel } from '@mui/x-data-grid';
import ArrivalLC from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/datagrids/ArrivalLC';
import BuildingExitLC from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/datagrids/BuildingExitLC';
import { ParentAlert } from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/common';
import { IDeviceContactOutput } from 'store/slices/devicesSlice';
import EditedChangesDialog from 'features/RemoteManagement/DeviceDashboard/liftControl/components/datagrid/other/EditedChangesDialog';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const LiftControlDataGridPage: React.FC = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const hasUnsavedChanges = React.useRef<boolean>(false);
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = React.useState<boolean>(false);

  const nextTabIndexRef = React.useRef<number>(0);
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const [snackbarState, setSnackbarState] = React.useState<ParentAlert>({
    type: 'success',
    duration: 2000,
    text: '',
    isOpen: false
  });

  const [fetchDevices] = useLazyGetDeviceListWithSitePublicIdQuery();
  const [updateDevice] = useUpdateDeviceMutation();

  // Get from the URL params
  const selectedDevicePublicId = useParams().deviceid ?? '';
  const sitePublicId = useParams().id ?? '';

  const deviceList = useSelector((state: RootState) => state.devices.DeviceList);
  const selectedDevice = deviceList[selectedDevicePublicId ?? ''];
  const buildingPublicId = selectedDevice?.buildingPublicId ?? '';

  /**
   * Callback function to update lift control device settings.
   *
   * This function uses the provided payload to update the lift control device data.
   * It handles asynchronous operations to update the device and fetch the updated
   * list of devices.
   * Additionally, it manages the UI state by setting the submission
   * status and displaying appropriate success or error messages via a snackbar.
   *
   * @param {Object} payload - Object containing key-value pairs representing the device data to be updated.
   * @throws {Error} If the update operation fails, it displays an error message parsed from the server response.
   */
  const updateLiftControlDevice = React.useCallback(
    async (payload: { [key: string]: any }) => {
      try {
        setIsSubmitting(true);
        // Update the device data for the payload
        await updateDevice(payload).unwrap();

        await fetchDevices({ sitePublicId, qty: -1, page: 0 });

        setSnackbarState({
          text: t('Successfully_updated_lift_control_settings'),
          type: 'success',
          isOpen: true,
          duration: 2000
        });
      } catch (_) {
        setSnackbarState({
          text: t('Error_Updating_Lift_Control_Settings'),
          type: 'error',
          isOpen: true,
          duration: 5000
        });
      } finally {
        setIsSubmitting(false);
        hasUnsavedChanges.current = false;
      }
    },
    [sitePublicId, updateDevice, t, fetchDevices, setIsSubmitting, setSnackbarState]
  );

  /**
   * Updates a specific field of a row identified by its ID within a grid data structure.
   *
   * This function modifies the state of rows by updating the specified field of the row
   * that matches the provided ID. It creates a new array of rows reflecting the updated values,
   * ensuring immutability, and sets the state with the updated array.
   *
   * @param {GridRowId} id - The unique identifier of the row to be updated.
   * @param {string} field - The name of the field within the row to be updated.
   * @param {any} newValue - The new value to be assigned to the specified field.
   * @param {function} setRows - A function to update the state of the rows. It accepts either
   *                             a new array of rows or a callback function that takes the previous
   *                             state of rows and returns the updated rows.
   */
  const handleUpdateRowField = (
    id: GridRowId,
    field: string,
    newValue: any,
    setRows: (
      value: ((prevState: readonly GridRowModel[]) => readonly GridRowModel[]) | readonly GridRowModel[]
    ) => void
  ) => {
    setRows((currentRows) => {
      return currentRows.map((row) => {
        if (row.id === id) {
          // Create a new row object with the updated field
          const updatedRow: GridRowModel = {
            ...row,
            [field]: newValue
          };
          return updatedRow;
        } else {
          return row;
        }
      });
    });
    hasUnsavedChanges.current = true;
  };

  /**
   * Updates the relay delay for a specific relay in the provided list of device contacts.
   *
   * This function modifies the `timer` property of the relay at the specified index
   * in the `relaysRef` object based on the `params` and the new delay value (`newRelayMS`).
   * The `hasUnsavedChanges` flag is updated to indicate that the changes have not been saved.
   *
   * @param {React.MutableRefObject<IDeviceContactOutput[]>} relaysRef - A reference to the list of device contact output objects.
   * @param {GridColumnHeaderParams} params - Parameters containing the field information to determine the relay index to update.
   * @param {number} newRelayMS - The new relay delay value to set in milliseconds.
   */
  const handleUpdateRelayDelay = (
    relaysRef: React.MutableRefObject<IDeviceContactOutput[]>,
    params: GridColumnHeaderParams,
    newRelayMS: number
  ) => {
    const relayIndex = parseInt(params.field.replace('contactOutput', '').replace('enabled', '')) - 1;
    if (relayIndex >= 0 && relayIndex < relaysRef.current.length) {
      // Create a shallow copy of the previous list
      const updatedList = [...relaysRef.current];
      // Update the timer property for the specific relayIndex in the copied list
      updatedList[relayIndex] = { ...updatedList[relayIndex], timer: newRelayMS };

      // Update relaysRef with updatedList
      relaysRef.current = updatedList;
      hasUnsavedChanges.current = true;
    }
  };

  // These are the three tabs we can see in the top bar: Arrival, Pick Up, and Building Exit
  const tabs: TabView[] = React.useMemo(() => {
    const _tabs = [
      {
        label: t('Arrival_Floor'),
        ChildTabView: (
          <ArrivalLC
            buildingPublicId={buildingPublicId}
            handleUpdateRelayDelay={handleUpdateRelayDelay}
            isSubmitting={isSubmitting}
            updateLiftControlDevice={updateLiftControlDevice}
            selectedDevicePublicId={selectedDevicePublicId}
            handleUpdateRowField={handleUpdateRowField}
          />
        )
      },
      {
        label: t('Pick_Up_Floor'),
        ChildTabView: (
          <PickupFloorLC
            buildingPublicId={buildingPublicId}
            handleUpdateRelayDelay={handleUpdateRelayDelay}
            isSubmitting={isSubmitting}
            updateLiftControlDevice={updateLiftControlDevice}
            selectedDevicePublicId={selectedDevicePublicId}
            handleUpdateRowField={handleUpdateRowField}
          />
        )
      }
    ];
    // Iterate all devices in the list to see if there are any with a deviceType of 14.
    // This means there is a 2C7

    const has2c7 = Object.values(deviceList).some((device) => device.basicInfo.deviceType === 14);

    if (has2c7) {
      _tabs.push({
        label: t('Building_Exit'),
        ChildTabView: (
          <BuildingExitLC
            setSnackbarState={setSnackbarState}
            buildingPublicId={buildingPublicId}
            handleUpdateRelayDelay={handleUpdateRelayDelay}
            isSubmitting={isSubmitting}
            updateLiftControlDevice={updateLiftControlDevice}
            selectedDevicePublicId={selectedDevicePublicId}
            handleUpdateRowField={handleUpdateRowField}
          />
        )
      });
    }

    return _tabs;
  }, [
    t,
    deviceList,
    buildingPublicId,
    isSubmitting,
    updateLiftControlDevice,
    selectedDevicePublicId,
    handleUpdateRowField,
    handleUpdateRelayDelay
  ]);

  const onChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    if (hasUnsavedChanges.current) {
      setShowUnsavedChangesDialog(true);
      nextTabIndexRef.current = newValue;
    } else {
      setTabIndex(newValue);
    }
  };

  return (
    <>
      <BasicFrame>
        <Typography variant={'h6'} sx={{ textAlign: 'left', fontWeight: 600, mt: '20px' }}>
          {t('Relay_Output_Management')}
        </Typography>
        <LiftControlTabs tabs={tabs} tabIndex={tabIndex} handleTabChange={onChangeTab} />
      </BasicFrame>
      <SnackbarAlert
        type={snackbarState.type}
        time={snackbarState.duration}
        text={snackbarState.text}
        isOpen={snackbarState.isOpen}
        onClose={() => {
          setSnackbarState({
            ...snackbarState,
            isOpen: false,
            text: ''
          });
        }}
      />
      <EditedChangesDialog
        showUnsavedChangesDialog={showUnsavedChangesDialog}
        setTabIndex={setTabIndex}
        nextTabIndexRef={nextTabIndexRef}
        setShowUnsavedChangesDialog={setShowUnsavedChangesDialog}
      />
    </>
  );
};

export default LiftControlDataGridPage;
