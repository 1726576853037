import { LoadingButton } from '@mui/lab';
import { Box, Container, Grid, Typography, TextField, Paper, Button } from '@mui/material';
import useConfigureWizardCommUnits from 'features/RemoteManagement/Hooks/useConfigureWizardCommUnits';
import useConfigureWizardMultiUnits from 'features/RemoteManagement/Hooks/useConfigureWizardMultiUnits';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useCreateBuildingMutation,
  useCreateUnitsWizardMutation,
  useLazyGetBuildingsQuery
} from 'services/aiphoneCloud';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { RootState } from 'store';
import { selectBuildings } from 'store/slices/buildingsSlice';
import { selectDeviceTypes } from 'store/slices/devicesSlice';

interface Props {
  numberOfBuildings: number;
  setNumberOfBuildings: React.Dispatch<React.SetStateAction<number>>;
  handlePreviousStep: () => void;
  handleNextStep: () => void;
}

const MAX_BUILDINGS = 99;

const strings = {
  title: 'Add Buildings',
  body1: 'Select the number of buildings in your site. You can add more buildings later.',
  backButtonText: 'Back',
  continueButtonText: 'Continue'
};

const AddBuildings = ({ numberOfBuildings, setNumberOfBuildings, handlePreviousStep, handleNextStep }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [errorMessageBuilding, setErrorMessageBuilding] = useState<string>('');
  const unitList = useSelector((state: RootState) => state.units.UnitList);
  const sitePublicId = useSelector((state: RootState) => state.site.siteInfo.publicId);
  const siteType = useSelector((state: RootState) => state.site.siteInfo.typeId);
  const devices = useSelector(selectDeviceTypes);
  const buildingList = useSelector(selectBuildings);
  const buildingPublicId = Object.keys(buildingList)[0];
  const [createUnitsWizard] = useCreateUnitsWizardMutation();
  const [createBuildings] = useCreateBuildingMutation();
  const [fetchBuildings] = useLazyGetBuildingsQuery();

  const configureWizardCommUnitParams = useConfigureWizardCommUnits(); // Adjust based on actual usage
  const configureWizardMultiUnit = useConfigureWizardMultiUnits(devices, buildingPublicId);

  const existingNoOfBuildings = useMemo(() => {
    return Object.keys(buildingList).length;
  }, [buildingList]);

  const handleNumberOfBuildingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let enteredValue = parseInt(event.target.value);

    // Reset if enteredValue is NaN
    if (isNaN(enteredValue)) {
      setNumberOfBuildings(existingNoOfBuildings);
      setErrorMessageBuilding('');
      return;
    }

    // If the entered value exceeds the max allowed buildings
    if (enteredValue > MAX_BUILDINGS) {
      setErrorMessageBuilding(`The number of buildings cannot exceed ${MAX_BUILDINGS}.`);
    } else {
      setErrorMessageBuilding('');
    }
    if (enteredValue < existingNoOfBuildings) {
      setSnackbarMessage('Cannot reduce the number of buildings');
      enteredValue = existingNoOfBuildings;
    } else {
      setNumberOfBuildings(enteredValue);
    }
  };

  const handleAddUnits = async () => {
    const configureSystem = async () => {
      if (Object.keys(unitList).length === 0) {
        const params = siteType === 1 ? configureWizardCommUnitParams : configureWizardMultiUnit;
        await createUnitsWizard(params).unwrap();
      }
    };
    await configureSystem();
  };

  const handleAddBuildings = async () => {
    if (numberOfBuildings <= 0 || isNaN(numberOfBuildings)) {
      setSnackbarMessage('Invalid number of buildings');
      setShowAlert(true);
      return;
    }

    setIsLoading(true);
    try {
      if (numberOfBuildings > 1 && existingNoOfBuildings !== numberOfBuildings) {
        for (let i = existingNoOfBuildings; i < numberOfBuildings; i++) {
          const buildingNumber = (i + 1).toString().padStart(4, '0');
          const params = {
            buildingData: {
              buildingNumber: buildingNumber,
              buildingName: `Building ${buildingNumber}`
            },
            sitePublicId: sitePublicId
          };
          await createBuildings(params).unwrap();
          await fetchBuildings(sitePublicId).unwrap();
        }
      }
      await handleAddUnits();
      setIsLoading(false);
      handleNextStep();
    } catch (error) {
      setIsLoading(false);
      setSnackbarMessage('Error adding buildings or units');
      setShowAlert(true);
      return;
    }
  };

  return (
    <Container maxWidth="md">
      <Box className="my-4">
        <Box sx={styles.centerContent}>
          <Typography variant="h4" className="mb-2" sx={{ color: 'primary.main', fontWeight: 'bold' }}>
            Add Buildings
          </Typography>
        </Box>
      </Box>
      <Paper sx={styles.paper}>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box component="img" src="/Buildings_500x300.jpg" alt="apartment building" sx={styles.imageContainer} />
            </Grid>
            <Grid item xs={4} sm container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body1">{strings.body1}</Typography>
                </Box>
                <Box width={'100%'}>
                  <TextField
                    label="Number of Buildings"
                    type="number"
                    value={numberOfBuildings}
                    onChange={handleNumberOfBuildingsChange}
                    fullWidth
                    error={!!errorMessageBuilding}
                    helperText={errorMessageBuilding}
                    InputProps={{
                      inputProps: {
                        min: existingNoOfBuildings,
                        max: MAX_BUILDINGS
                      }
                    }}
                  />
                  {existingNoOfBuildings > 0 && (
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      This site currently has {existingNoOfBuildings} building. An additional{' '}
                      {numberOfBuildings - existingNoOfBuildings} buildings will be added.
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box sx={styles.dualButtonContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousStep} disabled={isLoading}>
          {strings.backButtonText}
        </Button>

        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="primary"
          type="submit"
          onClick={handleAddBuildings}
          disabled={!!errorMessageBuilding || numberOfBuildings <= 0 || isLoading}
        >
          {strings.continueButtonText}
        </LoadingButton>
      </Box>
      <SnackbarAlert
        type="error"
        time={7000}
        text={snackbarMessage}
        isOpen={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </Container>
  );
};

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  dualButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    flexDirection: 'row',
    marginTop: '1rem'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    p: 3,
    px: 5,
    mb: 4,
    width: '100%',
    height: '23rem'
  },
  imageContainer: {
    width: '100%',
    borderRadius: 1
  }
};

export default AddBuildings;
