import { QuikspecCategory, SystemConstraint } from 'features/QuikSpec/Types/QuikSpecTypes';

/**
 * GT Series QuikSpec Categories and items
 *
 */
export const gtEntranceAndGuard: QuikspecCategory[] = [
  {
    id: 1,
    title: '3-in-1 Entrance Panels',
    description: 'Please select up to 16 Entrance Panels',
    items: [
      {
        deviceName: 'GT-DMB-N',
        accessories: [
          { accessoryName: 'Flush Mount', deviceName: 'GF-3B' },
          { accessoryName: 'Surface Mount', deviceName: 'SBX-GTDMB' }
        ]
      },
      {
        deviceName: 'NUMBER_OF_MODULE_VIDEO_ENTRANCE',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount' }]
      },
      {
        deviceName: 'NUMBER_OF_MODULE_AUDIO_ENTRANCE',
        accessories: [{ accessoryName: 'Flush Mount' }, { accessoryName: 'Surface Mount' }]
      }
    ],
    maxQuantityAllowedPerCategory: 16
  },
  {
    id: 2,
    title: 'Module Video Entrance Panels',
    description: 'Please select up to 16 Entrance Panels',
    items: [
      { deviceName: 'MODULE_VIDEO_ENTRANCE_KEYPAD' },
      { deviceName: 'MODULE_VIDEO_ENTRANCE_NFC' },
      { deviceName: 'MODULE_VIDEO_ENTRANCE_RAINHOOD' }
    ]
  },
  {
    id: 3,
    title: 'Module Audio Entrance Panels',
    description: 'Please select up to 16 Entrance Panels',
    items: [
      { deviceName: 'MODULE_AUDIO_ENTRANCE_KEYPAD' },
      { deviceName: 'MODULE_AUDIO_ENTRANCE_NFC' },
      { deviceName: 'MODULE_AUDIO_ENTRANCE_RAINHOOD' }
    ]
  },
  {
    id: 4,
    title: 'Guard Stations',
    description: 'Please select up to 4 Guard Stations',
    items: [{ deviceName: 'GT-MKB-N', maxQuantityAllowedPerItem: 4 }],
    maxQuantityAllowedPerCategory: 4
  }
];

export const gtTenantStations: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Audio Video Tenant Stations',
    description:
      'Audio Video Tenant Stations. Up to 500 stations are allowed in total between Audio Video and Audio Only Tenant Stations.',
    items: [{ deviceName: 'GT-1C7' }, { deviceName: 'GT-1C7W' }, { deviceName: 'GT-2C' }, { deviceName: 'GT-1M3' }]
  },
  {
    id: 2,
    title: 'Audio Only Tenant Stations',
    description:
      'Audio Only Tenant Stations. Up to 500 stations are allowed in total between Audio Video and Audio Only Tenant Stations.',
    items: [{ deviceName: 'GT-1A' }, { deviceName: 'GT-1D' }]
  }
  /* 
  // GT-TLI-IP was discontined in late 2024. The product and the dedicated tab needed to be deleted.
  {
    id: 3,
    title: 'Mobile App Only System',
    description: 'Up to 3 Mobile Applications per GT-TLI-IP.',
    items: [{ deviceName: 'GT-TLI-IP', discontinued: true }]
  }
  */
];

export const gtOptionalAccessories: QuikspecCategory[] = [
  {
    id: 1,
    title: 'Optional Accessories',
    description: 'Optional Accessories',
    items: [
      { deviceName: 'GT-MCX', maxQuantityAllowedPerItem: 1 },
      { deviceName: 'GT-RY' },
      { deviceName: 'GTW-LC', maxQuantityAllowedPerItem: 1 },
      { deviceName: 'GTW-VOA', discontinued: true }
    ]
  }
];

export const gtHidden: QuikspecCategory[] = [
  {
    id: 1,
    title: '',
    description: '',
    items: [
      { deviceName: 'GT-DB' },
      { deviceName: 'GT-DB-VN' },
      { deviceName: 'GT-DBP' },
      { deviceName: 'GT-VB' },
      { deviceName: 'GT-VP' },
      { deviceName: 'GT-NSB' },
      { deviceName: 'GT-NSP-L' },
      { deviceName: 'GT-10K' },
      { deviceName: 'GF-10KP' },
      { deviceName: 'GT-AD' },
      { deviceName: 'GT-AP' },
      { deviceName: 'GF-BP' },
      { deviceName: 'GT-AC' },
      { deviceName: 'GT-HID' },
      { deviceName: 'GT-SW' },
      { deviceName: 'GF-1P' },
      { deviceName: 'GF-2P' },
      { deviceName: 'GF-3P' },
      { deviceName: 'GF-4P' },
      { deviceName: 'GF-2F' },
      { deviceName: 'GF-2B' },
      { deviceName: 'GT-102H' },
      { deviceName: 'GT-102HB' },
      { deviceName: 'GT-202H' },
      { deviceName: 'GT-202HB' },
      { deviceName: 'GF-3F' },
      { deviceName: 'GF-3B' },
      { deviceName: 'GT-103H' },
      { deviceName: 'GT-103HB' },
      { deviceName: 'GT-203H' },
      { deviceName: 'GT-203HB' },
      { deviceName: 'GT-303HB' },
      { deviceName: 'GT-4F' },
      { deviceName: 'GT-4B' },
      { deviceName: 'GT-104H' },
      { deviceName: 'GT-104HB' },
      { deviceName: 'SBX-GTDMB' },
      { deviceName: 'GT-BC' },
      { deviceName: 'GT-VBC' },
      { deviceName: 'GT-BCXB-N' },
      { deviceName: 'GT-VBX' },
      { deviceName: 'GTW-DP' },
      { deviceName: 'GT-4Z' },
      { deviceName: 'GT-4ZP' },
      { deviceName: 'W-DIN11' },
      { deviceName: 'PS-2420UL' },
      { deviceName: 'NUMBER_OF_TENANT' }
    ]
  }
];

export const gtSystemConstraints: SystemConstraint[] = [
  { description: 'Entance Panels allowed', max: 16 },
  { description: 'Guard Stations allowed', max: 4 },
  { description: 'Tenant Stations allowed', max: 500 }
];
