export default {
  day: {
    '0': {
      value: 'individual'
    },
    '1': {
      value: 'sunday'
    },
    '2': {
      value: 'monday'
    },
    '3': {
      value: 'tuesday'
    },
    '4': {
      value: 'wednesday'
    },
    '5': {
      value: 'thursday'
    },
    '6': {
      value: 'friday'
    },
    '7': {
      value: 'saturday'
    }
  },
  state: {
    '1': {
      value: 'Hokkaido',
      isoCode: '01',
      countryId: 1,
      defaultLanguageName: '北海道',
      fullPronunciationName: 'Hokkaidô'
    },
    '2': {
      value: 'Aomori',
      isoCode: '02',
      countryId: 1,
      defaultLanguageName: '青森',
      fullPronunciationName: null
    },
    '3': {
      value: 'Iwate',
      isoCode: '03',
      countryId: 1,
      defaultLanguageName: '岩手',
      fullPronunciationName: null
    },
    '4': {
      value: 'Miyagi',
      isoCode: '04',
      countryId: 1,
      defaultLanguageName: '宮城',
      fullPronunciationName: null
    },
    '5': {
      value: 'Akita',
      isoCode: '05',
      countryId: 1,
      defaultLanguageName: '秋田',
      fullPronunciationName: null
    },
    '6': {
      value: 'Yamagata',
      isoCode: '06',
      countryId: 1,
      defaultLanguageName: '山形',
      fullPronunciationName: null
    },
    '7': {
      value: 'Fukushima',
      isoCode: '07',
      countryId: 1,
      defaultLanguageName: '福島',
      fullPronunciationName: 'Hukusima'
    },
    '8': {
      value: 'Ibaraki',
      isoCode: '08',
      countryId: 1,
      defaultLanguageName: '茨城',
      fullPronunciationName: null
    },
    '9': {
      value: 'Tochigi',
      isoCode: '09',
      countryId: 1,
      defaultLanguageName: '栃木',
      fullPronunciationName: 'Totigi'
    },
    '10': {
      value: 'Gunma',
      isoCode: '10',
      countryId: 1,
      defaultLanguageName: '群馬',
      fullPronunciationName: null
    },
    '11': {
      value: 'Saitama',
      isoCode: '11',
      countryId: 1,
      defaultLanguageName: '埼玉',
      fullPronunciationName: null
    },
    '12': {
      value: 'Chiba',
      isoCode: '12',
      countryId: 1,
      defaultLanguageName: '千葉',
      fullPronunciationName: 'Tiba'
    },
    '13': {
      value: 'Tokyo',
      isoCode: '13',
      countryId: 1,
      defaultLanguageName: '東京',
      fullPronunciationName: 'Tôkyô'
    },
    '14': {
      value: 'Kanagawa',
      isoCode: '14',
      countryId: 1,
      defaultLanguageName: '神奈川',
      fullPronunciationName: null
    },
    '15': {
      value: 'Niigata',
      isoCode: '15',
      countryId: 1,
      defaultLanguageName: '新潟',
      fullPronunciationName: null
    },
    '16': {
      value: 'Toyama',
      isoCode: '16',
      countryId: 1,
      defaultLanguageName: '富山',
      fullPronunciationName: null
    },
    '17': {
      value: 'Ishikawa',
      isoCode: '17',
      countryId: 1,
      defaultLanguageName: '石川',
      fullPronunciationName: 'Isikawa'
    },
    '18': {
      value: 'Fukui',
      isoCode: '18',
      countryId: 1,
      defaultLanguageName: '福井',
      fullPronunciationName: 'Hukui'
    },
    '19': {
      value: 'Yamanashi',
      isoCode: '19',
      countryId: 1,
      defaultLanguageName: '山梨',
      fullPronunciationName: 'Yamanasi'
    },
    '20': {
      value: 'Nagano',
      isoCode: '20',
      countryId: 1,
      defaultLanguageName: '長野',
      fullPronunciationName: null
    },
    '21': {
      value: 'Gifu',
      isoCode: '21',
      countryId: 1,
      defaultLanguageName: '岐阜',
      fullPronunciationName: 'Gihu'
    },
    '22': {
      value: 'Shizuoka',
      isoCode: '22',
      countryId: 1,
      defaultLanguageName: '静岡',
      fullPronunciationName: 'Sizuoka'
    },
    '23': {
      value: 'Aichi',
      isoCode: '23',
      countryId: 1,
      defaultLanguageName: '愛知',
      fullPronunciationName: 'Aiti'
    },
    '24': {
      value: 'Mie',
      isoCode: '24',
      countryId: 1,
      defaultLanguageName: '三重',
      fullPronunciationName: null
    },
    '25': {
      value: 'Shiga',
      isoCode: '25',
      countryId: 1,
      defaultLanguageName: '滋賀',
      fullPronunciationName: 'Siga'
    },
    '26': {
      value: 'Kyoto',
      isoCode: '26',
      countryId: 1,
      defaultLanguageName: '京都',
      fullPronunciationName: 'Kyôto'
    },
    '27': {
      value: 'Osaka',
      isoCode: '27',
      countryId: 1,
      defaultLanguageName: '大阪',
      fullPronunciationName: 'Ôsaka'
    },
    '28': {
      value: 'Hyogo',
      isoCode: '28',
      countryId: 1,
      defaultLanguageName: '兵庫',
      fullPronunciationName: 'Hyôgo'
    },
    '29': {
      value: 'Nara',
      isoCode: '29',
      countryId: 1,
      defaultLanguageName: '奈良',
      fullPronunciationName: null
    },
    '30': {
      value: 'Wakayama',
      isoCode: '30',
      countryId: 1,
      defaultLanguageName: '和歌山',
      fullPronunciationName: null
    },
    '31': {
      value: 'Tottori',
      isoCode: '31',
      countryId: 1,
      defaultLanguageName: '鳥取',
      fullPronunciationName: null
    },
    '32': {
      value: 'Shimane',
      isoCode: '32',
      countryId: 1,
      defaultLanguageName: '島根',
      fullPronunciationName: 'Simane'
    },
    '33': {
      value: 'Okayama',
      isoCode: '33',
      countryId: 1,
      defaultLanguageName: '岡山',
      fullPronunciationName: null
    },
    '34': {
      value: 'Hiroshima',
      isoCode: '34',
      countryId: 1,
      defaultLanguageName: '広島',
      fullPronunciationName: 'Hirosima'
    },
    '35': {
      value: 'Yamaguchi',
      isoCode: '35',
      countryId: 1,
      defaultLanguageName: '山口',
      fullPronunciationName: 'Yamaguti'
    },
    '36': {
      value: 'Tokushima',
      isoCode: '36',
      countryId: 1,
      defaultLanguageName: '徳島',
      fullPronunciationName: 'Tokusima'
    },
    '37': {
      value: 'Kagawa',
      isoCode: '37',
      countryId: 1,
      defaultLanguageName: '香川',
      fullPronunciationName: null
    },
    '38': {
      value: 'Ehime',
      isoCode: '38',
      countryId: 1,
      defaultLanguageName: '愛媛',
      fullPronunciationName: null
    },
    '39': {
      value: 'Kochi',
      isoCode: '39',
      countryId: 1,
      defaultLanguageName: '高知',
      fullPronunciationName: 'Kôti'
    },
    '40': {
      value: 'Fukuoka',
      isoCode: '40',
      countryId: 1,
      defaultLanguageName: '福岡',
      fullPronunciationName: 'Hukuoka'
    },
    '41': {
      value: 'Saga',
      isoCode: '41',
      countryId: 1,
      defaultLanguageName: '佐賀',
      fullPronunciationName: null
    },
    '42': {
      value: 'Nagasaki',
      isoCode: '42',
      countryId: 1,
      defaultLanguageName: '長崎',
      fullPronunciationName: null
    },
    '43': {
      value: 'Kumamoto',
      isoCode: '43',
      countryId: 1,
      defaultLanguageName: '熊本',
      fullPronunciationName: null
    },
    '44': {
      value: 'Oita',
      isoCode: '44',
      countryId: 1,
      defaultLanguageName: '大分',
      fullPronunciationName: 'Ôita'
    },
    '45': {
      value: 'Miyazaki',
      isoCode: '45',
      countryId: 1,
      defaultLanguageName: '宮崎',
      fullPronunciationName: null
    },
    '46': {
      value: 'Kagoshima',
      isoCode: '46',
      countryId: 1,
      defaultLanguageName: '鹿児島',
      fullPronunciationName: 'Kagosima'
    },
    '47': {
      value: 'Okinawa',
      isoCode: '47',
      countryId: 1,
      defaultLanguageName: '沖縄',
      fullPronunciationName: null
    },
    '48': {
      value: 'Alabama',
      isoCode: 'AL',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '49': {
      value: 'Alaska',
      isoCode: 'AK',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '50': {
      value: 'Arizona',
      isoCode: 'AZ',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '51': {
      value: 'Arkansas',
      isoCode: 'AR',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '52': {
      value: 'California',
      isoCode: 'CA',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '53': {
      value: 'Colorado',
      isoCode: 'CO',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '54': {
      value: 'Connecticut',
      isoCode: 'CT',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '55': {
      value: 'Delaware',
      isoCode: 'DE',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '56': {
      value: 'Florida',
      isoCode: 'FL',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '57': {
      value: 'Georgia',
      isoCode: 'GA',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '58': {
      value: 'Hawaii',
      isoCode: 'HI',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '59': {
      value: 'Idaho',
      isoCode: 'ID',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '60': {
      value: 'Illinois',
      isoCode: 'IL',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '61': {
      value: 'Indiana',
      isoCode: 'IN',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '62': {
      value: 'Iowa',
      isoCode: 'IA',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '63': {
      value: 'Kansas',
      isoCode: 'KS',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '64': {
      value: 'Kentucky',
      isoCode: 'KY',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '65': {
      value: 'Louisiana',
      isoCode: 'LA',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '66': {
      value: 'Maine',
      isoCode: 'ME',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '67': {
      value: 'Maryland',
      isoCode: 'MD',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '68': {
      value: 'Massachusetts',
      isoCode: 'MA',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '69': {
      value: 'Michigan',
      isoCode: 'MI',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '70': {
      value: 'Minnesota',
      isoCode: 'MN',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '71': {
      value: 'Mississippi',
      isoCode: 'MS',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '72': {
      value: 'Missouri',
      isoCode: 'MO',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '73': {
      value: 'Montana',
      isoCode: 'MT',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '74': {
      value: 'Nebraska',
      isoCode: 'NE',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '75': {
      value: 'Nevada',
      isoCode: 'NV',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '76': {
      value: 'New Hampshire',
      isoCode: 'NH',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '77': {
      value: 'New Jersey',
      isoCode: 'NJ',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '78': {
      value: 'New Mexico',
      isoCode: 'NM',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '79': {
      value: 'New York',
      isoCode: 'NY',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '80': {
      value: 'North Carolina',
      isoCode: 'NC',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '81': {
      value: 'North Dakota',
      isoCode: 'ND',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '82': {
      value: 'Ohio',
      isoCode: 'OH',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '83': {
      value: 'Oklahoma',
      isoCode: 'OK',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '84': {
      value: 'Oregon',
      isoCode: 'OR',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '85': {
      value: 'Pennsylvania',
      isoCode: 'PA',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '86': {
      value: 'Rhode Island',
      isoCode: 'RI',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '87': {
      value: 'South Carolina',
      isoCode: 'SC',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '88': {
      value: 'South Dakota',
      isoCode: 'SD',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '89': {
      value: 'Tennessee',
      isoCode: 'TN',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '90': {
      value: 'Texas',
      isoCode: 'TX',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '91': {
      value: 'Utah',
      isoCode: 'UT',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '92': {
      value: 'Vermont',
      isoCode: 'VT',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '93': {
      value: 'Virginia',
      isoCode: 'VA',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '94': {
      value: 'Washington',
      isoCode: 'WA',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '95': {
      value: 'West Virginia',
      isoCode: 'WV',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '96': {
      value: 'Wisconsin',
      isoCode: 'WI',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '97': {
      value: 'Wyoming',
      isoCode: 'WY',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '98': {
      value: 'District of Columbia',
      isoCode: 'DC',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '99': {
      value: 'American Samoa',
      isoCode: 'AS',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '100': {
      value: 'Guam',
      isoCode: 'GU',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '101': {
      value: 'Northern Mariana Islands',
      isoCode: 'MP',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '102': {
      value: 'Puerto Rico',
      isoCode: 'PR',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '103': {
      value: 'United States Minor Outlying Islands',
      isoCode: 'UM',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '104': {
      value: 'Virgin Islands, U.S.',
      isoCode: 'VI',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '105': {
      value: 'Alberta',
      isoCode: 'AB',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '106': {
      value: 'British Columbia',
      isoCode: 'BC',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '107': {
      value: 'Manitoba',
      isoCode: 'MB',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '108': {
      value: 'New Brunswick',
      isoCode: 'NB',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '109': {
      value: 'Newfoundland and Labrador',
      isoCode: 'NL',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '110': {
      value: 'Northwest Territories',
      isoCode: 'NT',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '111': {
      value: 'Nova Scotia',
      isoCode: 'NS',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '112': {
      value: 'Nunavut',
      isoCode: 'NU',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '113': {
      value: 'Ontario',
      isoCode: 'ON',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '114': {
      value: 'Prince Edward Island',
      isoCode: 'PE',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '115': {
      value: 'Quebec',
      isoCode: 'QC',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '116': {
      value: 'Saskatchewan',
      isoCode: 'SK',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '117': {
      value: 'Yukon',
      isoCode: 'YT',
      countryId: 3,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '119': {
      value: 'Unknown',
      isoCode: 'ZZ',
      countryId: 2,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '120': {
      value: 'Aguascalientes',
      isoCode: 'AGU',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '121': {
      value: 'Baja California',
      isoCode: 'BCN',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '122': {
      value: 'Baja California Sur',
      isoCode: 'BCS',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '123': {
      value: 'Campeche',
      isoCode: 'CAM',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '124': {
      value: 'Ciudad de México',
      isoCode: 'CMX',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '125': {
      value: 'Coahuila',
      isoCode: 'COA',
      countryId: 5,
      defaultLanguageName: 'Coahuila de Zaragoza',
      fullPronunciationName: null
    },
    '126': {
      value: 'Colima',
      isoCode: 'COL',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '127': {
      value: 'Chiapas',
      isoCode: 'CHP',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '128': {
      value: 'Chihuahua',
      isoCode: 'CHH',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '129': {
      value: 'Durango',
      isoCode: 'DUR',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '130': {
      value: 'Guanajuato',
      isoCode: 'GUA',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '131': {
      value: 'Guerrero',
      isoCode: 'GRO',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '132': {
      value: 'Hidalgo',
      isoCode: 'HID',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '133': {
      value: 'Jalisco',
      isoCode: 'JAL',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '134': {
      value: 'México',
      isoCode: 'MEX',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '135': {
      value: 'Michoacán',
      isoCode: 'MIC',
      countryId: 5,
      defaultLanguageName: 'Michoacán de Ocampo',
      fullPronunciationName: null
    },
    '136': {
      value: 'Morelos',
      isoCode: 'MOR',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '137': {
      value: 'Nayarit',
      isoCode: 'NAY',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '138': {
      value: 'Nuevo León',
      isoCode: 'NLE',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '139': {
      value: 'Oaxaca',
      isoCode: 'OAX',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '140': {
      value: 'Puebla',
      isoCode: 'PUE',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '141': {
      value: 'Querétaro',
      isoCode: 'QUE',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '142': {
      value: 'Quintana Roo',
      isoCode: 'ROO',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '143': {
      value: 'San Luis Potosí',
      isoCode: 'SLP',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '144': {
      value: 'Sinaloa',
      isoCode: 'SIN',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '145': {
      value: 'Sonora',
      isoCode: 'SON',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '146': {
      value: 'Tabasco',
      isoCode: 'TAB',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '147': {
      value: 'Tamaulipas',
      isoCode: 'TAM',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '148': {
      value: 'Tlaxcala',
      isoCode: 'TLA',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '149': {
      value: 'Veracruz',
      isoCode: 'VER',
      countryId: 5,
      defaultLanguageName: 'Veracruz de Ignacio de la Llave',
      fullPronunciationName: null
    },
    '150': {
      value: 'Yucatán',
      isoCode: 'YUC',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '151': {
      value: 'Zacatecas',
      isoCode: 'ZAC',
      countryId: 5,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '152': {
      value: 'Auvergne-Rhone-Alps',
      isoCode: 'ARA',
      countryId: 4,
      defaultLanguageName: 'Auvergne-Rhône-Alpes',
      fullPronunciationName: null
    },
    '153': {
      value: 'Burgundy-Free County',
      isoCode: 'BFC',
      countryId: 4,
      defaultLanguageName: 'Bourgogne-Franche-Comté',
      fullPronunciationName: null
    },
    '154': {
      value: 'Bretagne\tBrittany',
      isoCode: 'BRE',
      countryId: 4,
      defaultLanguageName: null,
      fullPronunciationName: null
    },
    '155': {
      value: 'Center-Loire Valley',
      isoCode: 'CVL',
      countryId: 4,
      defaultLanguageName: 'Centre-Val de Loire',
      fullPronunciationName: null
    },
    '156': {
      value: 'Corsica',
      isoCode: '20R',
      countryId: 4,
      defaultLanguageName: 'Corse',
      fullPronunciationName: null
    },
    '157': {
      value: 'Great East',
      isoCode: 'GES',
      countryId: 4,
      defaultLanguageName: 'Grand Est',
      fullPronunciationName: null
    },
    '158': {
      value: 'Upper France',
      isoCode: 'HDF',
      countryId: 4,
      defaultLanguageName: 'Hauts-de-France',
      fullPronunciationName: null
    },
    '159': {
      value: 'Paris',
      isoCode: 'IDF',
      countryId: 4,
      defaultLanguageName: 'Île-de-France',
      fullPronunciationName: null
    },
    '160': {
      value: 'Normandy',
      isoCode: 'NOR',
      countryId: 4,
      defaultLanguageName: 'Normandie',
      fullPronunciationName: null
    },
    '161': {
      value: 'New Aquitania',
      isoCode: 'NAQ',
      countryId: 4,
      defaultLanguageName: 'Nouvelle-Aquitaine',
      fullPronunciationName: null
    },
    '162': {
      value: 'Occitania',
      isoCode: 'OCC',
      countryId: 4,
      defaultLanguageName: 'Occitanie',
      fullPronunciationName: null
    },
    '163': {
      value: 'Loire Country',
      isoCode: 'PDL',
      countryId: 4,
      defaultLanguageName: 'Pays-de-la-Loire',
      fullPronunciationName: null
    },
    '164': {
      value: 'Provence-Alps-French Riviera',
      isoCode: 'PAC',
      countryId: 4,
      defaultLanguageName: 'Provence-Alpes-Côte-d’Azur',
      fullPronunciationName: null
    }
  },
  period: {
    '0': {
      value: 'OFF'
    },
    '1': {
      value: '15 seconds'
    },
    '2': {
      value: '20 minutes'
    },
    '3': {
      value: '30 minutes'
    },
    '4': {
      value: '60 minutes'
    },
    '5': {
      value: '1 day'
    },
    '6': {
      value: '1 week'
    }
  },
  context: {
    '1': {
      value: 'Global',
      companyTypeId: null
    },
    '2': {
      value: 'Branch',
      companyTypeId: null
    },
    '3': {
      value: 'Site',
      companyTypeId: null
    },
    '4': {
      value: 'Dealer',
      companyTypeId: 1
    },
    '5': {
      value: 'Property Manager',
      companyTypeId: 2
    }
  },
  country: {
    '1': {
      value: 'Japan',
      alpha2Code: 'JP',
      alpha3Code: 'JPN',
      numericCode: '392',
      defaultLanguageId: 1,
      defaultLanguageName: '日本国'
    },
    '2': {
      value: 'United States of America',
      alpha2Code: 'US',
      alpha3Code: 'USA',
      numericCode: '840',
      defaultLanguageId: 2,
      defaultLanguageName: null
    },
    '3': {
      value: 'Canada',
      alpha2Code: 'CA',
      alpha3Code: 'CAN',
      numericCode: '124',
      defaultLanguageId: 2,
      defaultLanguageName: null
    },
    '4': {
      value: 'France',
      alpha2Code: 'FR',
      alpha3Code: 'FRA',
      numericCode: '250',
      defaultLanguageId: 3,
      defaultLanguageName: null
    },
    '5': {
      value: 'Mexico',
      alpha2Code: 'MX',
      alpha3Code: 'MEX',
      numericCode: '484',
      defaultLanguageId: 4,
      defaultLanguageName: 'México'
    }
  },
  mfaType: {
    '1': {
      value: 'sms'
    },
    '2': {
      value: 'email'
    },
    '3': {
      value: 'app'
    }
  },
  callMode: {
    '1': {
      value: 'Normal Mode'
    },
    '2': {
      value: 'Timer-linked Switching Mode'
    },
    '3': {
      value: 'Schedule-linked Switching Mode'
    }
  },
  language: {
    '1': {
      value: 'Japanese'
    },
    '2': {
      value: 'English'
    },
    '3': {
      value: 'French'
    },
    '4': {
      value: 'Spanish'
    },
    '5': {
      value: 'Dutch'
    },
    '6': {
      value: 'Chinese (Traditional)'
    },
    '7': {
      value: 'Chinese (Simplified)'
    },
    '8': {
      value: 'German'
    },
    '9': {
      value: 'Italian'
    },
    '10': {
      value: 'Norwegian'
    },
    '11': {
      value: 'Finnish'
    },
    '12': {
      value: 'Turkish'
    }
  },
  mccOrMnc: {
    '1': {
      value: 'Automatic'
    },
    '2': {
      value: 'Specified'
    }
  },
  priority: {
    '1': {
      value: 'Normal'
    },
    '2': {
      value: 'Priority'
    },
    '3': {
      value: 'Urgent'
    }
  },
  protocol: {
    '1': {
      value: 'Unicast'
    },
    '2': {
      value: 'Multicast'
    }
  },
  siteType: {
    '1': {
      value: 'Commercial'
    },
    '2': {
      value: 'Multi-tenant'
    }
  },
  timezone: {
    '1': {
      value: '(GMT-12:00) Enewetak'
    },
    '2': {
      value: '(GMT-11:00) Midway Island'
    },
    '3': {
      value: '(GMT-11:00) Amerikansk Samoa'
    },
    '4': {
      value: '(GMT-10:00) Hawaii'
    },
    '5': {
      value: '(GMT-10:00) Alaska (Adak)'
    },
    '6': {
      value: '(GMT-09:00) Alaska'
    },
    '7': {
      value: '(GMT-08:00) Pacific Standard Time (USA), Tijuana'
    },
    '8': {
      value: '(GMT-08:00) Pacific Standard Time (Canada)'
    },
    '9': {
      value: '(GMT-07:00) Arizona'
    },
    '10': {
      value: '(GMT-07:00) Mountain Standard Time (US, Canada)'
    },
    '11': {
      value: '(GMT-06:00) Saskatchewan'
    },
    '12': {
      value: '(GMT-06:00) Mexico City'
    },
    '13': {
      value: '(GMT-06:00) Sentral-Amerika'
    },
    '14': {
      value: '(GMT-06:00) Central Standard Time (USA, Canada)'
    },
    '15': {
      value: '(GMT-05:00) Eastern Indiana'
    },
    '16': {
      value: '(GMT-05:00) Bogota'
    },
    '17': {
      value: '(GMT-05:00) Lima'
    },
    '18': {
      value: '(GMT-05:00) Quito'
    },
    '19': {
      value: '(GMT-05:00) Eastern Standard Time (USA, Canada)'
    },
    '20': {
      value: '(GMT-04:30) Caracas'
    },
    '21': {
      value: '(GMT-04:00) La Paz'
    },
    '22': {
      value: '(GMT-04:00) San Diego'
    },
    '23': {
      value: '(GMT-04:00) Atlantic Standard Time (Canada)'
    },
    '24': {
      value: '(GMT-04:00) Georgetown'
    },
    '25': {
      value: '(GMT-03:30) Newfoundland'
    },
    '26': {
      value: '(GMT-03:00) Grønland'
    },
    '27': {
      value: '(GMT-03:00) Buenos Aires'
    },
    '28': {
      value: '(GMT-03:00) Brasilia'
    },
    '29': {
      value: '(GMT-02:00) Central Atlantic'
    },
    '30': {
      value: '(GMT-01:00) Azorene'
    },
    '31': {
      value: '(GMT-01:00) Kapp Verde-øyene'
    },
    '32': {
      value: '(GMT 00:00) Casablanca'
    },
    '33': {
      value: '(GMT 00:00) Monrovia'
    },
    '34': {
      value: '(GMT 00:00) Greenwich Mean Time'
    },
    '35': {
      value: '(GMT 00:00) Dublin, Edinburgh, Lisboa, London'
    },
    '36': {
      value: '(GMT+01:00) Amsterdam, Berlin, Bern, Oslo, Roma, Stockholm'
    },
    '37': {
      value: '(GMT+01:00) Sarajevo, Skopje, Warszawa, Zagreb, Beograd, Bratislava, Budapest, Ljubljana, Praha'
    },
    '38': {
      value: '(GMT+01:00) Brussel, Madrid, København, Paris'
    },
    '39': {
      value: '(GMT+01:00) Vest- og Sentral-Afrika'
    },
    '40': {
      value: '(GMT+02:00) Sofia, Vilnius'
    },
    '41': {
      value: '(GMT+02:00) Athen'
    },
    '42': {
      value: '(GMT+02:00) Jerusalem'
    },
    '43': {
      value: '(GMT+02:00) Kairo'
    },
    '44': {
      value: '(GMT+02:00) Harare, Pretoria'
    },
    '45': {
      value: '(GMT+02:00) Bucuresti'
    },
    '46': {
      value: '(GMT+02:00) Helsinki, Riga, Tallinn'
    },
    '47': {
      value: '(GMT+03:00) Minsk, Istanbul'
    },
    '48': {
      value: '(GMT+03:00) Kuwait, Riyadh'
    },
    '49': {
      value: '(GMT+03:00) Nairobi'
    },
    '50': {
      value: '(GMT+03:00) Bagdad'
    },
    '51': {
      value: '(GMT+03:00) St. Petersburg'
    },
    '52': {
      value: '(GMT+03:30) Teheran'
    },
    '53': {
      value: '(GMT+04:00) Moskva, Volgograd'
    },
    '54': {
      value: '(GMT+04:00) Abu Dhabi, Muscat'
    },
    '55': {
      value: '(GMT+04:00) Bagh, Tbilisi'
    },
    '56': {
      value: '(GMT+04:00) Jerevan'
    },
    '57': {
      value: '(GMT+04:30) Kabul'
    },
    '58': {
      value: '(GMT+05:00) Islamabad, Karachi'
    },
    '59': {
      value: '(GMT+05:00) Tasjkent'
    },
    '60': {
      value: '(GMT+05:30) Calcutta, Chennai, Mumbai, New Delhi'
    },
    '61': {
      value: '(GMT+05:30) Sri Jayawardenepura Kotte'
    },
    '62': {
      value: '(GMT+05:45) Kathmandu'
    },
    '63': {
      value: '(GMT+06:00) Jekaterinburg'
    },
    '64': {
      value: '(GMT+06:00) Almaty, Astana'
    },
    '65': {
      value: '(GMT+06:00) Dhaka'
    },
    '66': {
      value: '(GMT+06:30) Yangon (Rangoon)'
    },
    '67': {
      value: '(GMT+07:00) Novosibirsk'
    },
    '68': {
      value: '(GMT+07:00) Bangkok, Hanoi'
    },
    '69': {
      value: '(GMT+07:00) Jakarta'
    },
    '70': {
      value: '(GMT+08:00) Krasnojarsk'
    },
    '71': {
      value: '(GMT+08:00) Ulan Bator'
    },
    '72': {
      value: '(GMT+08:00) Kuala Lumpur, Singapore'
    },
    '73': {
      value: '(GMT+08:00) Perth'
    },
    '74': {
      value: '(GMT+08:00) Taipei'
    },
    '75': {
      value: '(GMT+08:00) Beijing, Chongqing, Hongkong, Urumqi'
    },
    '76': {
      value: '(GMT+09:00) Irkutsk'
    },
    '77': {
      value: '(GMT+09:00) Seoul'
    },
    '78': {
      value: '(GMT+09:00) Osaka, Sapporo, Tokyo'
    },
    '79': {
      value: '(GMT+09:30) Adelaide'
    },
    '80': {
      value: '(GMT+09:30) Darwin'
    },
    '81': {
      value: '(GMT+10:00) Jakutsk'
    },
    '82': {
      value: '(GMT+10:00) Canberra, Melbourne'
    },
    '83': {
      value: '(GMT+10:00) Sydney'
    },
    '84': {
      value: '(GMT+10:00) Guam'
    },
    '85': {
      value: '(GMT+10:00) Port Moresby'
    },
    '86': {
      value: '(GMT+10:00) Brisbane'
    },
    '87': {
      value: '(GMT+10:00) Hobart'
    },
    '88': {
      value: '(GMT+11:00) Vladivostok'
    },
    '89': {
      value: '(GMT+11:00) Salomonøyene'
    },
    '90': {
      value: '(GMT+11:00) Ny-Caledonia'
    },
    '91': {
      value: '(GMT+12:00) Magadan'
    },
    '92': {
      value: '(GMT+12:00) Auckland, Wellington'
    },
    '93': {
      value: '(GMT+12:00) Fiji'
    },
    '94': {
      value: '(GMT+12:00) Kamtsjatka'
    },
    '95': {
      value: '(GMT+12:00) Marshalløyene, Kwajalein'
    },
    '96': {
      value: '(GMT+13:00) Tonga (Nukualofa), Kiribati (Phoenix Islands)'
    },
    '97': {
      value: '(GMT+13:00) Samoa'
    },
    '98': {
      value: '(GMT+13:00) Tokelau'
    },
    '99': {
      value: '(GMT+14:00) Kiribati (Line Islands)'
    }
  },
  unitType: {
    '1': {
      value: 'Guard'
    },
    '2': {
      value: 'Entrance'
    },
    '3': {
      value: 'InsideArea'
    },
    '4': {
      value: 'Residential'
    },
    '5': {
      value: 'Commercial'
    },
    '6': {
      value: 'OutsideArea'
    }
  },
  eapMethod: {
    '1': {
      value: 'TLS'
    },
    '2': {
      value: 'PEAP'
    }
  },
  fontColor: {
    '1': {
      value: 'Primary Colors'
    },
    '2': {
      value: 'Secondary Colors'
    }
  },
  ipVersion: {
    '1': {
      value: 'IPV4'
    },
    '2': {
      value: 'IPV6'
    }
  },
  operation: {
    '0': {
      value: 'Register'
    },
    '1': {
      value: 'Delete'
    }
  },
  simStatus: {
    '1': {
      value: 'ACTIVATION_READY'
    },
    '2': {
      value: 'REPLACED'
    },
    '3': {
      value: 'GLOBAL_INTRANSIT'
    },
    '4': {
      value: 'GLOBAL_TRANSFERRED'
    },
    '5': {
      value: 'TRIAL'
    },
    '6': {
      value: 'ACTIVATED'
    },
    '7': {
      value: 'DEACTIVATED'
    },
    '8': {
      value: 'INVENTORY'
    },
    '9': {
      value: 'PURGED'
    },
    '10': {
      value: 'RETIRED'
    },
    '11': {
      value: 'TEST_READY'
    }
  },
  vigikType: {
    '0': {
      value: 'Disabled'
    },
    '1': {
      value: 'Aiphone'
    },
    '2': {
      value: 'Hexact'
    }
  },
  capability: {
    '1': {
      value: 'global:doAnything'
    },
    '2': {
      value: 'app:create'
    },
    '3': {
      value: 'app:delete'
    },
    '4': {
      value: 'app:edit'
    },
    '5': {
      value: 'app:view'
    },
    '6': {
      value: 'branch:create'
    },
    '7': {
      value: 'branch:delete'
    },
    '8': {
      value: 'branch:edit'
    },
    '9': {
      value: 'branch:view'
    },
    '10': {
      value: 'building:create'
    },
    '11': {
      value: 'building:delete'
    },
    '12': {
      value: 'building:edit'
    },
    '13': {
      value: 'building:view'
    },
    '14': {
      value: 'company:create'
    },
    '15': {
      value: 'company:delete'
    },
    '16': {
      value: 'company:edit'
    },
    '17': {
      value: 'company:view'
    },
    '18': {
      value: 'device:create'
    },
    '19': {
      value: 'device:delete'
    },
    '20': {
      value: 'device:edit'
    },
    '21': {
      value: 'device:view'
    },
    '22': {
      value: 'deviceBasicInfo:edit'
    },
    '23': {
      value: 'deviceCallSettings:edit'
    },
    '24': {
      value: 'deviceContactInput:edit'
    },
    '25': {
      value: 'deviceContactOutput:edit'
    },
    '26': {
      value: 'deviceDeviceSettings:edit'
    },
    '27': {
      value: 'deviceEntrancePanelSettings:edit'
    },
    '28': {
      value: 'deviceFunctionSettings:edit'
    },
    '29': {
      value: 'deviceGatewaySettings:edit'
    },
    '30': {
      value: 'deviceLiftControlSettings:edit'
    },
    '31': {
      value: 'deviceNetworkSettings:edit'
    },
    '32': {
      value: 'devicePagingSettings:edit'
    },
    '33': {
      value: 'deviceSyslogSettings:edit'
    },
    '34': {
      value: 'deviceSystemInfo:edit'
    },
    '35': {
      value: 'deviceTransferSettings:edit'
    },
    '36': {
      value: 'gwa:create'
    },
    '37': {
      value: 'ipPhone:create'
    },
    '38': {
      value: 'ipPhone:delete'
    },
    '39': {
      value: 'ipPhone:edit'
    },
    '40': {
      value: 'ipPhone:view'
    },
    '41': {
      value: 'networkCamera:create'
    },
    '42': {
      value: 'networkCamera:delete'
    },
    '43': {
      value: 'networkCamera:edit'
    },
    '44': {
      value: 'networkCamera:view'
    },
    '45': {
      value: 'quikspec'
    },
    '46': {
      value: 'quikspec:delete'
    },
    '47': {
      value: 'quikspec:edit'
    },
    '48': {
      value: 'remoteManagement'
    },
    '49': {
      value: 'role:assign'
    },
    '50': {
      value: 'simBilling'
    },
    '51': {
      value: 'site:create'
    },
    '52': {
      value: 'site:delete'
    },
    '53': {
      value: 'site:edit'
    },
    '54': {
      value: 'site:view'
    },
    '55': {
      value: 'siteList:view'
    },
    '56': {
      value: 'siteSoundSetting:view'
    },
    '57': {
      value: 'tenant:create'
    },
    '58': {
      value: 'tenant:delete'
    },
    '59': {
      value: 'tenant:edit'
    },
    '60': {
      value: 'tenant:view'
    },
    '61': {
      value: 'unit:create'
    },
    '62': {
      value: 'unit:delete'
    },
    '63': {
      value: 'unit:edit'
    },
    '64': {
      value: 'unit:view'
    },
    '65': {
      value: 'user:create'
    },
    '66': {
      value: 'user:delete'
    },
    '67': {
      value: 'user:update'
    },
    '68': {
      value: 'user:view'
    },
    '69': {
      value: 'usersTab:view'
    },
    '70': {
      value: 'organizationsTab:view'
    },
    '71': {
      value: 'company:merge'
    },
    '72': {
      value: 'branch:merge'
    }
  },
  deviceType: {
    '1': {
      value: 'IX-DA'
    },
    '2': {
      value: 'IX-BA'
    },
    '3': {
      value: 'IX-MV'
    },
    '4': {
      value: 'IX-MV7'
    },
    '5': {
      value: 'IX-RS'
    },
    '6': {
      value: 'IXW-MA'
    },
    '7': {
      value: 'IXSPMIC'
    },
    '8': {
      value: 'IX-DV'
    },
    '9': {
      value: 'IX-SSA'
    },
    '10': {
      value: 'IX-SS-2G'
    },
    '11': {
      value: 'IX-EA'
    },
    '12': {
      value: 'IX-FA'
    },
    '13': {
      value: 'IP-PHONE'
    },
    '14': {
      value: 'IXG-2C7'
    },
    '15': {
      value: 'IXG-DM7'
    },
    '16': {
      value: 'IXG-MK'
    },
    '17': {
      value: 'IXGW-LC'
    },
    '18': {
      value: 'IXGW-GW'
    },
    '19': {
      value: 'APP'
    },
    '20': {
      value: 'IX-DVM'
    }
  },
  fontWeight: {
    '1': {
      value: 'Normal'
    },
    '2': {
      value: 'Bold'
    }
  },
  ipCallRate: {
    '1': {
      value: 'Receive -12db/Send +12db'
    },
    '2': {
      value: 'Receive -6db/Send +6db'
    },
    '3': {
      value: 'Receive 0db/Send 0db'
    },
    '4': {
      value: 'Receive +6db/Send -6db'
    },
    '5': {
      value: 'Receive + 12db/Transmit -12db'
    }
  },
  pagingType: {
    '1': {
      value: 'All/Simultaneous'
    },
    '2': {
      value: 'Group'
    }
  },
  permission: {
    '1': {
      value: 'Allow'
    },
    '2': {
      value: 'Prevent'
    },
    '3': {
      value: 'Prohibit'
    }
  },
  apnProtocol: {
    '1': {
      value: 'IPv4'
    },
    '2': {
      value: 'Ipv6'
    },
    '3': {
      value: 'IPv4v6'
    }
  },
  companyType: {
    '1': {
      value: 'Dealer'
    },
    '2': {
      value: 'Property Manager'
    }
  },
  deviceModel: {
    '1': {
      value: 'IX-DA',
      deviceType: 1,
      modelNumber: 1
    },
    '2': {
      value: 'IX-BA',
      deviceType: 2,
      modelNumber: 1
    },
    '3': {
      value: 'IX-MV',
      deviceType: 3,
      modelNumber: 1
    },
    '4': {
      value: 'IX-MV7-HB-L',
      deviceType: 4,
      modelNumber: 1
    },
    '5': {
      value: 'IX-MV7-B',
      deviceType: 4,
      modelNumber: 2
    },
    '6': {
      value: 'IX-MV7-W',
      deviceType: 4,
      modelNumber: 3
    },
    '7': {
      value: 'IX-MV7-HB',
      deviceType: 4,
      modelNumber: 4
    },
    '8': {
      value: 'IX-MV7-HW-JP',
      deviceType: 4,
      modelNumber: 5
    },
    '9': {
      value: 'IX-SOFT',
      deviceType: 4,
      modelNumber: 6
    },
    '10': {
      value: 'IX-RS-B',
      deviceType: 5,
      modelNumber: 1
    },
    '11': {
      value: 'IX-RS-W',
      deviceType: 5,
      modelNumber: 2
    },
    '12': {
      value: 'IXW-MA',
      deviceType: 6,
      modelNumber: 1
    },
    '13': {
      value: 'IXW-MA-SOFT',
      deviceType: 6,
      modelNumber: 2
    },
    '14': {
      value: 'IXW-MA-SOFT-5',
      deviceType: 6,
      modelNumber: 3
    },
    '15': {
      value: 'IXW-MA-SOFT-10',
      deviceType: 6,
      modelNumber: 4
    },
    '16': {
      value: 'IX-DV',
      deviceType: 8,
      modelNumber: 1
    },
    '17': {
      value: 'IX-DVF',
      deviceType: 8,
      modelNumber: 2
    },
    '18': {
      value: 'IX-DVF-4A',
      deviceType: 8,
      modelNumber: 3
    },
    '19': {
      value: 'IX-DVF-6',
      deviceType: 8,
      modelNumber: 4
    },
    '20': {
      value: 'IX-DVF-HW',
      deviceType: 8,
      modelNumber: 5
    },
    '21': {
      value: 'IX-DVF-L',
      deviceType: 8,
      modelNumber: 6
    },
    '22': {
      value: 'IX-DVF-PR',
      deviceType: 8,
      modelNumber: 7
    },
    '23': {
      value: 'IX-DVF-RA',
      deviceType: 8,
      modelNumber: 8
    },
    '24': {
      value: 'IX-DVF-2RA',
      deviceType: 8,
      modelNumber: 9
    },
    '25': {
      value: 'IX-SSA',
      deviceType: 9,
      modelNumber: 1
    },
    '26': {
      value: 'IX-SSA-RA',
      deviceType: 9,
      modelNumber: 2
    },
    '27': {
      value: 'IX-SSA-2RA',
      deviceType: 9,
      modelNumber: 3
    },
    '28': {
      value: 'IX-SS-2G',
      deviceType: 10,
      modelNumber: 1
    },
    '29': {
      value: 'IX-EA',
      deviceType: 11,
      modelNumber: 1
    },
    '30': {
      value: 'IX-FA',
      deviceType: 12,
      modelNumber: 1
    },
    '31': {
      value: 'IP-PHONE',
      deviceType: 13,
      modelNumber: 1
    },
    '32': {
      value: 'IXG-2C7',
      deviceType: 14,
      modelNumber: 1
    },
    '33': {
      value: 'IXG-2C7-L',
      deviceType: 14,
      modelNumber: 2
    },
    '34': {
      value: 'IXG-DM7-HID',
      deviceType: 15,
      modelNumber: 1
    },
    '35': {
      value: 'IXG-MK',
      deviceType: 16,
      modelNumber: 1
    },
    '36': {
      value: 'IXGW-LC',
      deviceType: 17,
      modelNumber: 1
    },
    '37': {
      value: 'IXGW-LC-RY20',
      deviceType: 17,
      modelNumber: 2
    },
    '38': {
      value: 'IXGW-GW',
      deviceType: 18,
      modelNumber: 1
    },
    '39': {
      value: 'IXGW-GW-10',
      deviceType: 18,
      modelNumber: 2
    },
    '40': {
      value: 'IXGW-GW-25',
      deviceType: 18,
      modelNumber: 3
    },
    '41': {
      value: 'IXGW-GW-50',
      deviceType: 18,
      modelNumber: 4
    },
    '42': {
      value: 'IXGW-GW-75',
      deviceType: 18,
      modelNumber: 5
    },
    '43': {
      value: 'IXGW-GW-100',
      deviceType: 18,
      modelNumber: 6
    },
    '44': {
      value: 'IXGW-GW-150',
      deviceType: 18,
      modelNumber: 7
    },
    '45': {
      value: 'IXGW-GW-200',
      deviceType: 18,
      modelNumber: 8
    },
    '46': {
      value: 'IXGW-GW-250',
      deviceType: 18,
      modelNumber: 9
    },
    '47': {
      value: 'IXGW-GW-300',
      deviceType: 18,
      modelNumber: 10
    },
    '48': {
      value: 'IXGW-GW-400',
      deviceType: 18,
      modelNumber: 11
    },
    '49': {
      value: 'IXGW-GW-450',
      deviceType: 18,
      modelNumber: 12
    },
    '50': {
      value: 'IXGW-GW-500',
      deviceType: 18,
      modelNumber: 13
    },
    '51': {
      value: 'APP',
      deviceType: 19,
      modelNumber: 1
    },
    '52': {
      value: 'IX-DVM',
      deviceType: 20,
      modelNumber: 1
    },
    '59': {
      value: 'IXGW-TGW',
      deviceType: 18,
      modelNumber: 14
    },
    '60': {
      value: 'IXW-MAA',
      deviceType: 6,
      modelNumber: 5
    },
    '61': {
      value: 'IXW-MAA-SOFT',
      deviceType: 6,
      modelNumber: 6
    },
    '62': {
      value: 'IXW-MAA-SOFT-5',
      deviceType: 6,
      modelNumber: 7
    },
    '63': {
      value: 'IXW-MAA-SOFT-10',
      deviceType: 6,
      modelNumber: 8
    },
    '64': {
      value: 'IX-NVP',
      deviceType: 10,
      modelNumber: 2
    },
    '65': {
      value: 'IX-DVF-10KP',
      deviceType: 8,
      modelNumber: 10
    }
  },
  displayMode: {
    '1': {
      value: 'Normal Mode'
    },
    '2': {
      value: 'Reception Mode'
    }
  },
  pinJackType: {
    '1': {
      value: 'Microphone'
    },
    '2': {
      value: 'Headset'
    }
  },
  refreshRate: {
    '1': {
      value: '50Hz'
    },
    '2': {
      value: '60Hz'
    }
  },
  shockSensor: {
    '1': {
      value: 'Low sensitive'
    },
    '2': {
      value: 'Medium sensitive'
    },
    '3': {
      value: 'High sensitive'
    }
  },
  audioEncoder: {
    '1': {
      value: 'G.711(μ-law)'
    },
    '2': {
      value: 'G.711(A-law)'
    }
  },
  cameraAction: null,
  deviceTarget: {
    '1': {
      value: 'Self'
    },
    '2': {
      value: 'Other Station'
    }
  },
  sifEventType: {
    '1': {
      value: 'callStart'
    },
    '2': {
      value: 'communicationStart'
    },
    '3': {
      value: 'doorRelease'
    },
    '4': {
      value: 'communicationEnd'
    },
    '5': {
      value: 'contactChange'
    },
    '6': {
      value: 'communicationError'
    },
    '7': {
      value: 'pagingStart'
    },
    '8': {
      value: 'pagingEnd'
    },
    '9': {
      value: 'transferStart'
    },
    '10': {
      value: 'transferEnd'
    },
    '11': {
      value: 'periodicSend'
    },
    '12': {
      value: 'onInitialization'
    },
    '13': {
      value: 'callEnd'
    },
    '14': {
      value: 'callReceivedStart'
    },
    '15': {
      value: 'callReceivedEnd'
    },
    '16': {
      value: 'callRecovery'
    },
    '17': {
      value: 'callChange'
    },
    '18': {
      value: 'callFailed'
    },
    '19': {
      value: 'receiveTransfer'
    },
    '20': {
      value: 'onHoldStart'
    },
    '21': {
      value: 'onHoldEnd'
    },
    '22': {
      value: 'incomingPageStart'
    },
    '23': {
      value: 'incomingPageEnd'
    },
    '24': {
      value: 'pagingFailure'
    },
    '25': {
      value: 'monitoringStart'
    },
    '26': {
      value: 'monitoringEnd'
    },
    '27': {
      value: 'monitoringFailure'
    },
    '28': {
      value: 'monitoringReceiveStart'
    },
    '29': {
      value: 'monitoringReceiveEnd'
    },
    '30': {
      value: 'replyOperation'
    },
    '31': {
      value: 'privacyStart'
    },
    '32': {
      value: 'privacyEnd'
    },
    '33': {
      value: 'keypadInput'
    },
    '34': {
      value: 'assignedButton'
    },
    '35': {
      value: 'recordingStart'
    },
    '36': {
      value: 'recordingEnd'
    },
    '37': {
      value: 'recordingFull'
    },
    '38': {
      value: 'lineSupervisionNormal'
    },
    '39': {
      value: 'lineSupervisionAbnormal'
    },
    '40': {
      value: 'troubleSupervisionNormal'
    },
    '41': {
      value: 'troubleSupervisionAbnormal'
    },
    '42': {
      value: 'sdError'
    },
    '43': {
      value: 'sipRegisterError'
    },
    '44': {
      value: 'networkCameraEvent'
    }
  },
  smtpAuthMode: {
    '1': {
      value: 'LOGIN'
    },
    '2': {
      value: 'CRAM-MD5'
    }
  },
  videoBitrate: {
    '1': {
      value: '32'
    },
    '2': {
      value: '64'
    },
    '3': {
      value: '128'
    },
    '4': {
      value: '256'
    },
    '5': {
      value: '384'
    },
    '6': {
      value: '512'
    },
    '7': {
      value: '768'
    },
    '8': {
      value: '1024'
    },
    '9': {
      value: '2048'
    },
    '10': {
      value: '4096'
    },
    '11': {
      value: '8192'
    }
  },
  videoDisplay: {
    '1': {
      value: 'wide'
    },
    '2': {
      value: 'normal'
    }
  },
  videoVmsType: {
    '1': {
      value: 'Standard Mode'
    },
    '2': {
      value: 'Genetec Mode'
    }
  },
  callSensitive: {
    '1': {
      value: '5cm/2inch'
    },
    '2': {
      value: '7cm/2.8inch'
    },
    '3': {
      value: '10cm/4inch'
    },
    '4': {
      value: '12cm/4.7inch'
    },
    '5': {
      value: '15cm/6inch'
    }
  },
  displayLayout: {
    '1': {
      value: 'Layout 1'
    },
    '2': {
      value: 'Layout 2'
    },
    '3': {
      value: 'Layout 3'
    }
  },
  recordingMode: {
    '1': {
      value: 'Disabled'
    },
    '2': {
      value: 'Enabled'
    },
    '3': {
      value: 'Continuous Record'
    },
    '4': {
      value: 'Enabled'
    }
  },
  sipServerType: {
    '1': {
      value: 'Standard Mode'
    },
    '2': {
      value: 'Genetec Mode'
    }
  },
  videoPriority: {
    '1': {
      value: 'IX Station'
    },
    '2': {
      value: 'Network Camera'
    }
  },
  apnNetworkType: {
    '1': {
      value: 'Auto'
    },
    '2': {
      value: 'GSM'
    },
    '3': {
      value: 'WCDMA'
    },
    '4': {
      value: 'LTE'
    }
  },
  connectionType: {
    '1': {
      value: 'Socket'
    },
    '2': {
      value: 'Http'
    }
  },
  displayModeTop: {
    '1': {
      value: 'Screen 1'
    },
    '2': {
      value: 'Screen 2'
    }
  },
  emailEventType: {
    '1': {
      value: 'normalCall'
    },
    '2': {
      value: 'normalCallReceive'
    },
    '3': {
      value: 'priorityCall'
    },
    '4': {
      value: 'priorityCallReceive'
    },
    '5': {
      value: 'urgentCall'
    },
    '6': {
      value: 'urgentCallReceive'
    },
    '7': {
      value: 'unlockState'
    },
    '8': {
      value: 'callFailure'
    },
    '9': {
      value: 'eventRecovery'
    },
    '10': {
      value: 'communicationError'
    },
    '11': {
      value: 'deviceRestart'
    },
    '12': {
      value: 'sdError'
    },
    '13': {
      value: 'storageFull'
    },
    '14': {
      value: 'lineSupervisionNormal'
    },
    '15': {
      value: 'lineSupervisionAbnormal'
    },
    '16': {
      value: 'errorSupervisionNormal'
    },
    '17': {
      value: 'errorSupervisionAbnormal'
    },
    '18': {
      value: 'cameraError'
    },
    '19': {
      value: 'sendScheduledLog'
    }
  },
  picturePicture: {
    '1': {
      value: 'IX Video'
    },
    '2': {
      value: 'Network Camera'
    }
  },
  simCarrierType: {
    '1': {
      value: 'AT&T'
    },
    '2': {
      value: 'Bell'
    }
  },
  smtpEncryption: {
    '1': {
      value: 'None'
    },
    '2': {
      value: 'TLS'
    },
    '3': {
      value: 'STARTTLS'
    }
  },
  unlockCardMode: {
    '1': {
      value: 'Always unlock'
    },
    '2': {
      value: 'Never unlock'
    },
    '3': {
      value: 'Unlock based on schedule'
    }
  },
  ipAddressMethod: {
    '1': {
      value: 'DHCPv6'
    },
    '2': {
      value: 'Static'
    },
    '3': {
      value: 'Stateless'
    }
  },
  contactInputMode: {
    '1': {
      value: 'Normal'
    },
    '2': {
      value: 'Timer-Linked'
    },
    '3': {
      value: 'Schedule-Linked'
    }
  },
  displayBaseColor: {
    '1': {
      value: 'White Tone'
    },
    '2': {
      value: 'Black Tone'
    }
  },
  displaySelection: {
    '0': {
      value: 'Numeric key unlock'
    },
    '1': {
      value: 'Numeric key selection'
    },
    '2': {
      value: 'Full key selection'
    },
    '3': {
      value: 'Name search selection'
    },
    '4': {
      value: 'Quick selection'
    },
    '5': {
      value: 'QR code authentication unlock screen'
    }
  },
  recordingAutoEnd: {
    '1': {
      value: 'Disabled'
    },
    '2': {
      value: '5 sec'
    },
    '3': {
      value: '10 sec'
    },
    '4': {
      value: '30 sec'
    }
  },
  unlockCardFormat: {
    '0': {
      value: '26bit Wiegand format (H10301)'
    },
    '1': {
      value: '37bit Wiegand format (H10302)'
    },
    '2': {
      value: '37bit Wiegand format (H10304)'
    },
    '3': {
      value: 'Corporate1000 (35bit)'
    },
    '4': {
      value: 'Corporate1000 (48bit)'
    }
  },
  deviceAllPageType: {
    '1': {
      value: 'select'
    },
    '2': {
      value: 'unicast'
    }
  },
  monitorDeviceType: null,
  pinCodeOutputMode: {
    '0': {
      value: '4 bit'
    },
    '1': {
      value: '8 bit'
    },
    '3': {
      value: 'Disabled'
    }
  },
  speakerOutputType: {
    '1': {
      value: 'Main unit speaker for both speech and paging'
    },
    '2': {
      value: 'Paging amp terminal output for both speech and paging'
    },
    '3': {
      value: 'Main unit speaker for speech and paging amp terminal output for paging'
    }
  },
  speedDialFunction: {
    '0': {
      value: 'None'
    },
    '1': {
      value: 'Group Call'
    },
    '2': {
      value: 'Individual Call'
    },
    '3': {
      value: 'Group Paging'
    },
    '4': {
      value: 'All Page'
    },
    '5': {
      value: 'Message Page'
    },
    '6': {
      value: 'All Message Page'
    },
    '7': {
      value: 'Monitor'
    },
    '8': {
      value: 'Network Camera M'
    },
    '9': {
      value: 'Scan Monitor'
    },
    '10': {
      value: 'Optional Relay'
    }
  },
  callButtonFunction: {
    '1': {
      value: 'Call'
    },
    '2': {
      value: 'Call + End Call'
    },
    '3': {
      value: 'Call + Call Answer + End Call'
    }
  },
  videoEncoderMethod: {
    '1': {
      value: 'H.264/AVC'
    },
    '2': {
      value: 'MotionJPEG'
    }
  },
  communicationMethod: {
    '1': {
      value: 'Eth0'
    },
    '2': {
      value: 'SIM'
    },
    '3': {
      value: 'Eth0 & SIM'
    }
  },
  entranceVideoSource: {
    '1': {
      value: 'Do not display'
    },
    '2': {
      value: 'Own terminal'
    },
    '3': {
      value: 'Own terminal and other'
    }
  },
  picturePictureRatio: {
    '1': {
      value: '16:9 display'
    },
    '2': {
      value: '4:3 display'
    }
  },
  recordingDivideTime: {
    '1': {
      value: '5min'
    },
    '2': {
      value: '10min'
    },
    '3': {
      value: '20min'
    },
    '4': {
      value: '40min'
    },
    '5': {
      value: '60min'
    }
  },
  scanMonitorInterval: {
    '1': {
      value: '5sec'
    },
    '2': {
      value: '10sec'
    },
    '3': {
      value: '30sec'
    }
  },
  videoEncoderQuality: {
    '1': {
      value: 'Baseline Profile'
    },
    '2': {
      value: 'Main Profile'
    },
    '3': {
      value: 'High Profile'
    }
  },
  contactInputFunction: {
    '1': {
      value: 'No Function'
    },
    '2': {
      value: 'Call'
    },
    '3': {
      value: 'Answer Call / Page'
    },
    '4': {
      value: 'Paging'
    },
    '5': {
      value: 'Message Page'
    },
    '6': {
      value: 'External Input Page'
    },
    '7': {
      value: 'Turn LCD On'
    },
    '8': {
      value: 'Relay Latch Reset'
    },
    '9': {
      value: 'Bathroom Indicator Reset'
    },
    '10': {
      value: 'API'
    },
    '11': {
      value: 'Doorbell'
    },
    '12': {
      value: 'Door Release'
    },
    '20': {
      value: 'Guard Call'
    }
  },
  contactInputPolarity: {
    '1': {
      value: 'Make'
    },
    '2': {
      value: 'Break'
    }
  },
  contactOutputControl: null,
  contactOutputFunction: {
    '1': {
      value: 'No Function'
    },
    '2': {
      value: 'Status Output'
    },
    '3': {
      value: 'Paging Amplifier Control'
    },
    '4': {
      value: 'Door Release'
    },
    '5': {
      value: 'Latch Output'
    },
    '6': {
      value: 'Bathroom Indicator'
    },
    '7': {
      value: 'SIF'
    },
    '8': {
      value: 'Shock Detection'
    }
  },
  displayButtonFunction: {
    '1': {
      value: 'General information'
    },
    '2': {
      value: 'Select from name'
    },
    '3': {
      value: 'Select from list'
    },
    '4': {
      value: 'Number'
    },
    '5': {
      value: 'Hide'
    }
  },
  gatewayChangePriority: {
    '1': {
      value: 'Switch'
    },
    '2': {
      value: 'Do not switch'
    }
  },
  simManagementUserType: {
    '1': {
      value: 'Aiphone Admin'
    },
    '2': {
      value: 'Dealer'
    },
    '3': {
      value: 'Property Manager'
    }
  },
  tofCallDetectInterval: {
    '1': {
      value: '0.5sec'
    },
    '2': {
      value: '1.0sec'
    },
    '3': {
      value: '1.5sec'
    },
    '4': {
      value: '2sec'
    },
    '5': {
      value: '2.5sec'
    }
  },
  videoEncoderFramerate: {
    '1': {
      value: '0.5',
      supportedDeviceTypeList: [4]
    },
    '2': {
      value: '1',
      supportedDeviceTypeList: null
    },
    '3': {
      value: '2',
      supportedDeviceTypeList: null
    },
    '4': {
      value: '3',
      supportedDeviceTypeList: null
    },
    '5': {
      value: '5',
      supportedDeviceTypeList: null
    },
    '6': {
      value: '7.5',
      supportedDeviceTypeList: null
    },
    '7': {
      value: '10',
      supportedDeviceTypeList: null
    },
    '8': {
      value: '15',
      supportedDeviceTypeList: null
    },
    '9': {
      value: '20',
      supportedDeviceTypeList: null
    },
    '10': {
      value: '30',
      supportedDeviceTypeList: null
    }
  },
  videoEncoderResolution: {
    '1': {
      value: '320x240 (QVGA)'
    },
    '2': {
      value: '640x480 (VGA)'
    },
    '3': {
      value: '800x480 (WVGA)'
    },
    '4': {
      value: '1280x720 (HD)'
    }
  },
  callStartCameraSettings: {
    '1': {
      value: 'Zoom'
    },
    '2': {
      value: 'Wide'
    }
  },
  outputToExternalSpeaker: {
    '1': {
      value: 'Output ringing tone from external speaker'
    },
    '2': {
      value: 'Output warning paging tone and receiving tone from external speaker'
    },
    '3': {
      value: 'Output ringing tone, warning tone for paging and receiving tone from external speaker'
    }
  },
  supervisionSettingsType: {
    '1': {
      value: 'line'
    },
    '2': {
      value: 'error'
    }
  },
  deviceLiftControlOperation: {
    '1': {
      value: 'arrival'
    },
    '2': {
      value: 'goOut'
    },
    '3': {
      value: 'departureStation'
    },
    '4': {
      value: 'departureRoom'
    },
    '5': {
      value: 'departureSmartphone'
    }
  },
  videoEncoderResolutionVer5: {
    '2': {
      value: '640x480 (VGA)'
    },
    '4': {
      value: '1280x720 (HD)'
    }
  },
  gatewaySimAuthenticationType: {
    '1': {
      value: 'PAP'
    },
    '2': {
      value: 'CHAP'
    },
    '3': {
      value: 'NONE'
    }
  },
  masterStationVideoH264Profile: null,
  contactOutputFlashlightRecovery: {
    '1': {
      value: 'On Site'
    },
    '2': {
      value: 'End Call'
    }
  },
  vigikRoomFirstLastNameManagementSetting: {
    '1': {
      value: 'IXG'
    },
    '2': {
      value: 'VIGIK'
    }
  }
};
