import useIpV4AddressValidationSchema from '../Shared/IpV4Address';
import useIpV6AddressValidationSchema from '../Shared/IpV6Address';
import * as Yup from 'yup';
import StringUtils from 'shared/utils/StringUtils';
import { RegexDomainName, RegexSubnetMask } from 'features/RemoteManagement/Types';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';
import { useTranslation } from 'react-i18next';

export interface IIPAddressFields {
  [key: string]: any;
  networkSettings: {
    [key: string]: any;
    hostName: string | undefined;
    subnetMask: string | undefined;
    ipVersion: number | undefined;
    ipAddressMethod: number | undefined;
    ipV4Address: string | undefined;
    ipV4DefaultGateway: string | undefined;
    ipV6Address: string | undefined;
    ipV6DefaultGateway: string | undefined;
    ipV4PrimaryDns: string | undefined;
    ipV4SecondaryDns: string | undefined;
    ipV6PrimaryDns: string | undefined;
    ipV6SecondaryDns: string | undefined;
    ipV4MulticastAddress: string | undefined;
    ipV6MulticastAddress: string | undefined;
  };
  gatewaySettings?: {
    [key: string]: any;
    communicationMethod: number | undefined;
  };
}

const subnetMaskBlacklist: string[] = ['0.0.0.0'];

const useIPAddressValidationSchema = () => {
  const { t } = useTranslation();
  const fieldErrorMaxLen = t('Field_Error_MaxLen');
  const fieldErrorRequired = t('Field_Error_Required');
  const fieldErrorInvalid = t('Field_Error_Invalid');
  const hostNameTitle = t('AdvancedSettings_HostName_Title');
  const ipV4DefaultGatewayTitle = t('AdvancedSettings_IpV4DefaultGateway_Title');
  const ipV4PrimaryDnsTitle = t('AdvancedSettings_IpV4PrimaryDns_Title');
  const ipV4SecondaryDnsTitle = t('AdvancedSettings_IpV4SecondaryDns_Title');
  const ipV4MulticastAddressTitle = t('AdvancedSettings_IpV4MulticastAddress_Title');
  const ipV4AddressTitle = t('AdvancedSettings_IpV4Address_Title');
  const ipV6AddressTitle = t('AdvancedSettings_IpV6Address_Title');
  const ipV6DefaultGatewayTitle = t('AdvancedSettings_IpV6DefaultGateway_Title');
  const ipV6PrimaryDnsTitle = t('AdvancedSettings_IpV6PrimaryDns_Title');
  const ipV6SecondaryDnsTitle = t('AdvancedSettings_IpV6SecondaryDns_Title');
  const ipV6MulticastAddressTitle = t('AdvancedSettings_IpV6MulticastAddress_Title');
  const subnetMaskTitle = t('AdvancedSettings_SubnetMask_Title');
  const hostNameErrorInvalid = StringUtils.format(fieldErrorInvalid, hostNameTitle);
  const subnetMaskErrorRequired = StringUtils.format(fieldErrorRequired, subnetMaskTitle);
  const subnetMaskErrorInvalid = StringUtils.format(fieldErrorInvalid, subnetMaskTitle);
  const ipVersionTitle = t('AdvancedSettings_IpVersion_Title');
  const ipVersionRequired = StringUtils.format(fieldErrorRequired, ipVersionTitle);

  // Validations that are shared between multiple fields
  const ipV4AddressValidation = useIpV4AddressValidationSchema(ipV4AddressTitle);
  const ipV4DefaultGatewayValidation = useIpV4AddressValidationSchema(ipV4DefaultGatewayTitle);
  const ipV4PrimaryDnsValidation = useIpV4AddressValidationSchema(ipV4PrimaryDnsTitle);
  const ipV4SecondaryDnsValidation = useIpV4AddressValidationSchema(ipV4SecondaryDnsTitle);
  const ipV4MulticastAddressValidation = useIpV4AddressValidationSchema(ipV4MulticastAddressTitle);
  const ipV6AddressValidation = useIpV6AddressValidationSchema(ipV6AddressTitle);
  const ipV6DefaultGatewayValidation = useIpV6AddressValidationSchema(ipV6DefaultGatewayTitle);
  const ipV6PrimaryDnsValidation = useIpV6AddressValidationSchema(ipV6PrimaryDnsTitle);
  const ipV6SecondaryDnsValidation = useIpV6AddressValidationSchema(ipV6SecondaryDnsTitle);
  const ipV6MulticastAddressValidation = useIpV6AddressValidationSchema(ipV6MulticastAddressTitle);
  const enumList: EnumList = fetchLocalEnumList();

  const getValidationSchema = (formDevice: IIPAddressFields, currValues: IIPAddressFields): Yup.ObjectSchema<any> => {
    let formattedFieldRequired: string;
    let formattedFieldInvalid: string;
    const networkSchema: any = {};

    if (formDevice.networkSettings.hostName !== undefined) {
      const hostNameMaxLen = 64;
      networkSchema.hostName = Yup.string()
        .matches(RegexDomainName, hostNameErrorInvalid)
        .max(hostNameMaxLen, StringUtils.format(fieldErrorMaxLen, hostNameTitle, hostNameMaxLen));
    }

    if (formDevice.networkSettings.ipVersion !== undefined) {
      formattedFieldRequired = StringUtils.format(fieldErrorRequired, ipVersionRequired);
      formattedFieldInvalid = StringUtils.format(fieldErrorInvalid, ipVersionTitle);
      networkSchema.ipVersion = Yup.string()
        .required(formattedFieldRequired)
        .test('isValidMethod', formattedFieldInvalid, (value: string) => {
          return Object.keys(enumList.ipVersion).includes(value);
        });
    }

    if (currValues.networkSettings?.ipVersion) {
      if (enumList.ipVersion[currValues.networkSettings?.ipVersion].value === 'IPV4') {
        if (formDevice.networkSettings.ipV4Address !== undefined) {
          networkSchema.ipV4Address = ipV4AddressValidation;
        }

        if (formDevice.networkSettings.subnetMask !== undefined) {
          networkSchema.subnetMask = Yup.string().when('ipV4Address', {
            is: (ipV4Address: string) => ipV4Address && ipV4Address.length > 0,
            then: (schema: any) =>
              schema
                .required(subnetMaskErrorRequired)
                .notOneOf(subnetMaskBlacklist, subnetMaskErrorInvalid)
                .matches(RegexSubnetMask, subnetMaskErrorInvalid),
            otherwise: (schema: any) =>
              schema
                .notOneOf(subnetMaskBlacklist, subnetMaskErrorInvalid)
                .matches(RegexSubnetMask, subnetMaskErrorInvalid)
          });
        }

        if (formDevice.networkSettings.ipV4DefaultGateway !== undefined) {
          networkSchema.ipV4DefaultGateway = ipV4DefaultGatewayValidation;
        }

        if (formDevice.networkSettings.ipV4PrimaryDns !== undefined) {
          networkSchema.ipV4PrimaryDns = ipV4PrimaryDnsValidation;
        }

        if (formDevice.networkSettings.ipV4SecondaryDns !== undefined) {
          networkSchema.ipV4SecondaryDns = ipV4SecondaryDnsValidation;
        }

        if (formDevice.networkSettings.ipV4MulticastAddress !== undefined) {
          networkSchema.ipV4MulticastAddress = ipV4MulticastAddressValidation;
        }
      } else if (enumList.ipVersion[currValues.networkSettings?.ipVersion].value === 'IPV6') {
        if (formDevice.networkSettings.ipV6Address !== undefined) {
          networkSchema.ipV6Address = ipV6AddressValidation;
        }

        if (formDevice.networkSettings.ipV6DefaultGateway !== undefined) {
          networkSchema.ipV6DefaultGateway = ipV6DefaultGatewayValidation;
        }

        if (formDevice.networkSettings.ipV6PrimaryDns !== undefined) {
          networkSchema.ipV6PrimaryDns = ipV6PrimaryDnsValidation;
        }

        if (formDevice.networkSettings.ipV6SecondaryDns !== undefined) {
          networkSchema.ipV6SecondaryDns = ipV6SecondaryDnsValidation;
        }

        if (formDevice.networkSettings.ipV6MulticastAddress !== undefined) {
          networkSchema.ipV6MulticastAddress = ipV6MulticastAddressValidation;
        }
      }
    }

    return Yup.object({
      networkSettings: Yup.object(networkSchema)
    });
  };

  return getValidationSchema;
};

export default useIPAddressValidationSchema;
