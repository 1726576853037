import React from 'react';
import { FieldProps } from 'formik';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface AddressFieldProps extends FieldProps {
  label?: string;
}

const AddressField: React.FC<AddressFieldProps> = ({ field, form, label, ...props }) => {
  const { t } = useTranslation();
  return (
    <TextField
      {...field}
      {...props}
      type="text"
      error={Boolean(form.touched[field.name] && form.errors[field.name])}
      label={label ? label : t('Address')}
      helperText={
        form.touched[field.name] && form.errors[field.name] // Conditional rendering of helperText
          ? String(form.errors[field.name]) // Make sure the error is passed as a string
          : undefined // Ensure no content if there's no error
      }
      fullWidth
    />
  );
};

export default AddressField;
