import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
  GridRowsProp,
  GridToolbarContainer
} from '@mui/x-data-grid';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import useTranslation
import EditRegisteredUserDialog from './Dialogs/EditRegisteredUserDialog';
import { useGetCompaniesQuery } from 'services/aiphoneCloud';
import { CloudUser } from 'store/slices/usersSlice';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'store/slices/usersSlice';
import { CloudRole, UserType } from 'shared/utils/UserUtils';
import useGetUsers from 'hooks/useGetUsers';
import DeleteAccountDialog from 'shared/components/dialogs/DeleteAccountDialog';

export interface ISelectedUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface IGetUsersResponse {
  userList: { [key: string]: CloudUser };
  perPage: number;
  pageNumber: number;
  totalPages: number;
  totalUsers: number;
}

const RegisteredUsersDataGrid = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const defaultPageSize = 50;

  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [isEditRegisteredUserDialogOpen, setIsEditRegisteredUserDialogOpen] = React.useState(false);
  const [isDeleteUserDialogOpen, setIsDeleteUserDialogOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<ISelectedUser>();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [usersList, setUsersList] = React.useState<{ [key: string]: CloudUser }>({});
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [firstPageLoaded, setFirstPageLoaded] = React.useState(false);
  const [rows, setRows] = React.useState<GridRowsProp>([]);

  const currentUser = useSelector(getCurrentUser);
  const hasGlobalAdminRole = currentUser
    ? currentUser.permissions.global.roleList[0].roleName === CloudRole.AIPHONE_ADMIN
    : false;

  /*Fetch users*/
  const { data, error, refetch } = useGetUsers(currentPage, pageSize, UserType.NO_BRANCH_ROLE);
  const usersData: IGetUsersResponse = data as IGetUsersResponse;

  /*Fetch companies*/
  const { data: companies, isFetching: isCompaniesFetching } = useGetCompaniesQuery({
    qty: '1000',
    page: '0'
  });
  const companyList = companies ? companies.companyList : {};

  /*Hook components*/

  useEffect(() => {
    if (usersData?.userList) {
      setUsersList({ ...usersList, ...usersData.userList });
      if (currentPage == 0) {
        setFirstPageLoaded(true);
      }
    }
    if (usersData) {
      const nextPageAvailable: boolean = usersData.pageNumber < usersData.totalPages;
      if (nextPageAvailable) {
        setCurrentPage(currentPage + 1);
      }
    }
  }, [usersData]);

  useEffect(() => {
    setRows(computeRows(usersList));
  }, [usersList]);

  useEffect(() => {
    if ((usersData || error) && isSuccess) {
      refetch();
    }
  }, [page, pageSize, isSuccess]);

  useEffect(() => {
    if (error) {
      const errorData = JSON.parse((error as { data: string }).data);
      const errorCode = errorData.errorCode;
      if (errorCode === 'A0001') {
        setErrorMessage(t('Error_Unauthorized_Fetch_Users')); // Use translation key
      } else {
        setErrorMessage(t('Error_Fetch_Users')); // Use translation key
      }
      setIsError(true);
    }
  }, [error]);

  const computeRows = (users: { [key: string]: CloudUser }): GridRowsProp => {
    if (Object.keys(users).length == 0) {
      return [];
    }
    return Object.values(users).map((value: CloudUser) => {
      return {
        id: value.publicId,
        firstName: value.firstName,
        lastName: value.lastName,
        email: value.email
      };
    });
  };

  const computeColumns = (): GridColDef[] => {
    return [
      { field: 'firstName', type: 'string', headerName: t('First_Name'), flex: 1 }, // Use translation key
      { field: 'lastName', type: 'string', headerName: t('Last_Name'), flex: 1 }, // Use translation key
      { field: 'email', type: 'string', headerName: t('Email'), flex: 1 }, // Use translation key
      {
        field: 'actions',
        type: 'actions',
        flex: 1,
        getActions: (params) => {
          // Check if the current user matches the user in the row
          const isCurrentUser = currentUser?.publicId === params.row.id;

          // If the user is the current user, do not show actions
          if (isCurrentUser) return [];

          return [
            <GridActionsCellItem
              icon={<SettingsIcon />}
              label={t('Edit')} // Use translation key
              onClick={() => {
                setIsEditRegisteredUserDialogOpen(true);
                setSelectedUser({
                  id: params.row.id,
                  firstName: params.row.firstName,
                  lastName: params.row.lastName,
                  email: params.row.email
                });
              }}
            />
          ];
        }
      }
    ];
  };

  // The following variable was added to hide the delete option from production temporarily.
  // Once the test is completed, we will remove this limitation.
  const url = new URL(window.location.href); //TODO: will remove later
  const shouldBeVisible = !(url?.hostname === 'aiphone.cloud' || url?.hostname === 'prod.aiphone.cloud'); //TODO: will remove later

  const RegisteredUsersToolbar = () => {
    return (
      <GridToolbarContainer>
        {hasGlobalAdminRole && shouldBeVisible && (
          <Box sx={styles.w100}>
            <Button
              onClick={() => setIsDeleteUserDialogOpen(true)}
              disabled={!firstPageLoaded || selectionModel.length !== 1}
              startIcon={<DeleteForeverIcon />}
            >
              {t('Delete_User')} {/* Use translation key */}
            </Button>
          </Box>
        )}
      </GridToolbarContainer>
    );
  };

  const columns: GridColDef[] = computeColumns();

  return (
    <>
      <Box sx={styles.w100}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          loading={!firstPageLoaded}
          checkboxSelection
          disableRowSelectionOnClick
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: pageSize }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setPageSize(model.pageSize);
          }}
          slots={{ toolbar: RegisteredUsersToolbar }}
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          pagination
        />
      </Box>
      <EditRegisteredUserDialog
        isOpen={isEditRegisteredUserDialogOpen}
        setIsOpen={setIsEditRegisteredUserDialogOpen}
        selectedUser={selectedUser as ISelectedUser}
        companies={companyList}
        isFetching={isCompaniesFetching}
        setErrorMessage={setErrorMessage}
        setIsError={setIsError}
        setSuccessMessage={setSuccessMessage}
        setIsSuccess={setIsSuccess}
      />
      <DeleteAccountDialog
        isOpen={isDeleteUserDialogOpen}
        onClose={() => setIsDeleteUserDialogOpen(false)}
        selectionModel={selectionModel}
      />
      <SnackbarAlert type="error" time={6000} text={errorMessage} isOpen={isError} onClose={() => setIsError(false)} />
      <SnackbarAlert
        type="success"
        time={6000}
        text={successMessage}
        isOpen={isSuccess}
        onClose={() => {
          setIsSuccess(false);
        }}
      />
    </>
  );
};

const styles = {
  w100: {
    width: '100%'
  }
};

export default RegisteredUsersDataGrid;
