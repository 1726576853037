import React from 'react';
import { FieldProps } from 'formik';
import { TextField, InputAdornment } from '@mui/material';
import { PhoneIphone } from '@mui/icons-material';
import { formatPhoneNumber } from 'shared/utils/helperFunctions';
import { useTranslation } from 'react-i18next';

interface PhoneInputProps extends FieldProps {
  placeholder?: string; // Will default to "+1 (___) ___-____" if this is not provided
  maxDigits?: number; // Limit for phone number digits
  label?: string;
}

const PhoneField: React.FC<PhoneInputProps> = ({
  field, // Formik field (name, value, onChange)
  form, // Formik state (errors, touched, etc.)
  label,
  placeholder = '+1 (___) ___-____',
  maxDigits = 11, // Default max digits for phone numbers; Need 11 due to the country code
  ...props
}) => {
  const { t } = useTranslation();
  const handleFocus = async (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === '' || value.length === 0) {
      await form.setFieldValue(field.name, '+1');
      await form.setTouched({ ...form.touched, [field.name]: true });
    }
  };

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === '+1' || value.length === 2 || value.length === 3) {
      await form.setFieldValue(field.name, '');
      await form.setTouched({ ...form.touched, [field.name]: false });
      return;
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length === 0 || value === '+') {
      // Set to +1. Previously, there would be no way to enter digits.
      await form.setFieldValue(field.name, '+1');

      await form.setTouched({ ...form.touched, [field.name]: true });
      return;
    }

    const onlyNumbers = value.replace(/\D/g, ''); // Keep only numbers

    // Restrict input to max digits
    if (onlyNumbers.length > maxDigits) return;

    // Format the value into "pretty format".
    const formattedValue = formatPhoneNumber(onlyNumbers);

    // Manually set the field value to the formatted version
    await form.setFieldValue(field.name, formattedValue);
    await form.setTouched({ ...form.touched, [field.name]: true });
  };

  return (
    <TextField
      {...field} // Formik field props
      {...props} // Additional props (e.g., fullWidth, size)
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={field.value ?? '+1'}
      label={label && form.touched[field.name] ? label : t('Phone_Number')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PhoneIphone />
          </InputAdornment>
        )
      }}
      error={form.touched[field.name] && Boolean(form.errors[field.name])}
      helperText={
        form.touched[field.name] && form.errors[field.name] // Conditional rendering of helperText
          ? String(form.errors[field.name]) // Make sure the error is passed as a string
          : undefined // Ensure no content if there's no error
      }
    />
  );
};

export default PhoneField;
