import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { CA, US } from 'country-flag-icons/react/3x2';
import { Field, Form, Formik } from 'formik';
import { useCreateCompanyMutation } from 'services/aiphoneCloud';
import * as yup from 'yup';
import { EnumList, ICountryValue, IStateValue, fetchEnumList } from 'shared/utils/EnumUtils';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import ZipPostalCodeField from 'shared/components/forms/fields/ZipPostalCodeField';
import { ZIP_CODE_REGEX } from 'shared/constants/regex';
import StringUtils from 'shared/utils/StringUtils';
import { CompanyRecord } from 'features/DealerCompanies/Components/Datagrids/CompaniesDataGrid';

interface IAddCompanyDialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsError: (isError: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  setIsSuccess: (isSuccess: boolean) => void;
  setSuccessMessage: (successMessage: string) => void;
  setCompaniesList: React.Dispatch<
    React.SetStateAction<{
      [key: string]: CompanyRecord;
    }>
  >;
}

const AddCompanyDialog = ({
  isOpen,
  setIsOpen,
  setIsError,
  setErrorMessage,
  setIsSuccess,
  setSuccessMessage,
  setCompaniesList
}: IAddCompanyDialogProps) => {
  const [createCompany, { isLoading: isCompanyLoading }] = useCreateCompanyMutation();
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });

  const { t } = useTranslation(); // Initialize the translation hook
  const fieldErrorInvalid = t('Field_Error_Invalid');
  const fieldErrorRequired = t('Field_Error_Required');
  const postalCodeStr = t('PostalCode');
  const zipCodeStr = t('Organization_ZipCode');
  // Invalid values
  const postalCodeInvalidStr = StringUtils.format(fieldErrorInvalid, postalCodeStr);
  const zipCodeInvalidStr = StringUtils.format(fieldErrorInvalid, zipCodeStr);
  // Required values
  const postalCodeRequiredStr = StringUtils.format(fieldErrorRequired, postalCodeStr);
  const zipCodeRequiredStr = StringUtils.format(fieldErrorRequired, zipCodeStr);

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
    });
  }, []);

  const initialValues = {
    companyName: '',
    countryId: '',
    stateId: '',
    postalCode: ''
  };

  const handleCreateCompany = async (values: typeof initialValues) => {
    const companyData = {
      name: values.companyName,
      stateId: values.stateId,
      postalCode: values.postalCode,
      type: 1,
      statusId: 1,
      dealerCompanyPublicId: null
    };

    createCompany({ companyData })
      .unwrap()
      .then((response) => {
        if (response.error) {
          setIsError(true);
          setErrorMessage(response.error.message);
          return;
        }
        setIsOpen(false);
        setIsSuccess(true);
        const newlyCreatedPublicId = response[0].newly_created_company_public_id;
        setCompaniesList((prevCompaniesList) => {
          return {
            ...prevCompaniesList,
            [newlyCreatedPublicId]: {
              id: newlyCreatedPublicId,
              name: values.companyName,
              stateId: values.stateId,
              postalCode: values.postalCode,
              companyTypeId: 1,
              dealerCompanyPublicId: null
            }
          };
        });
        setSuccessMessage(t('Company_Created_Successfully')); // Localization
      })
      .catch((error) => {
        setErrorMessage(JSON.parse(error.data)?.errorDetails || t('Unexpected_Error')); // Localization
        setIsError(true);
      });
  };

  const validationSchema = yup.object().shape({
    companyName: yup.string().required(t('Validations.Company_Name_Required')),
    stateId: yup.number().optional(),
    countryId: yup.number().optional(),
    postalCode: yup.string().when('countryId', {
      is: 2,
      then: (schema) =>
        schema
          .min(5, t('Dynamic_min', { min: 5 }))
          .max(5, t('Dynamic_max', { max: 5 }))
          .matches(ZIP_CODE_REGEX.unitedStates, zipCodeInvalidStr)
          .required(zipCodeRequiredStr),
      otherwise: (schema) =>
        schema
          .min(6, t('Dynamic_min', { min: 6 }))
          .max(7, t('Dynamic_max', { max: 7 }))
          .matches(ZIP_CODE_REGEX.canada, postalCodeInvalidStr)
          .required(postalCodeRequiredStr)
    })
  });

  const getStateList = (countryId: string) => {
    const stateList = Object.keys(enumList.state)
      .map((key) => {
        const stateWalker = enumList.state[key] as IStateValue;
        if (stateWalker.countryId.toString() !== countryId || stateWalker.value === 'Unknown') {
          return null;
        }

        return (
          <MenuItem key={stateWalker.value} value={key}>
            {stateWalker.value}
          </MenuItem>
        );
      })
      .filter((val) => val !== null);

    if (stateList.length === 0) {
      stateList.push(
        <MenuItem key={t('Select_Country')} value="" disabled={true}>
          {t('Select_Country')}
        </MenuItem>
      );
    }

    return stateList;
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Grid container spacing={1}>
        <Grid item xs={11}>
          <DialogTitle>{t('Enter_CompanyDetails')}</DialogTitle> {/* Localization */}
        </Grid>
        <Grid item xs={1}>
          <IconButton color="default" aria-label="cancel" onClick={() => setIsOpen(false)}>
            <CancelIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Formik initialValues={initialValues} onSubmit={handleCreateCompany} validationSchema={validationSchema}>
        {({ handleChange, errors, isSubmitting, touched, isValid, values, setFieldValue }) => (
          <Form>
            <DialogContent>
              <Box sx={styles.my2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      label={t('Company_Name')} // Localization
                      name="companyName"
                      as={TextField}
                      error={errors.companyName && touched.companyName}
                      helperText={errors.companyName && touched.companyName ? errors.companyName : ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="country-label">{t('Select_Country')}</InputLabel> {/* Localization */}
                      <Field
                        name="countryId"
                        as={Select}
                        labelId="country-label"
                        label={t('Select_Country')} // Localization
                        onChange={async (e: any) => {
                          handleChange(e);
                          await setFieldValue('stateId', '');
                          await setFieldValue('postalCode', '');
                        }}
                        error={errors.countryId && touched.countryId}
                        helperText={errors.countryId && touched.countryId ? errors.countryId : ''}
                      >
                        {Object.keys(enumList.country).map((key) => {
                          const validCountries = ['CA', 'US'];
                          const countryWalker = enumList.country[key] as ICountryValue;

                          if (!validCountries.includes(countryWalker.alpha2Code)) {
                            return null;
                          }

                          const flagComponent: JSX.Element =
                            countryWalker.alpha2Code === 'CA' ? (
                              <CA title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                            ) : (
                              <US title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                            );

                          return (
                            <MenuItem key={key} value={key}>
                              <Grid sx={styles.field}>
                                {flagComponent}
                                {countryWalker.value}
                              </Grid>
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel id="state-label">{t('Select_State')}</InputLabel> {/* Localization */}
                      <Field
                        name="stateId"
                        as={Select}
                        labelId="state-label"
                        label={t('Select_State')} // Localization
                        disabled={!['2', '3'].includes(values.countryId)}
                        error={errors.stateId && touched.stateId}
                        helperText={errors.stateId && touched.stateId ? errors.stateId : ''}
                      >
                        {getStateList(values.countryId)}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      label={values.countryId !== '3' ? t('Organization_ZipCode') : t('PostalCode')} // Localization
                      name="postalCode"
                      disabled={!['2', '3'].includes(values.countryId)}
                      component={ZipPostalCodeField}
                      countryId={values.countryId}
                      placeholder={values.countryId !== '3' ? t('Organization_ZipCode') : t('PostalCode')}
                      helperText={errors.postalCode && touched.postalCode ? errors.postalCode : ''}
                      error={errors.postalCode && touched.postalCode}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setIsOpen(false)} color="primary">
                {t('Button_Cancel')} {/* Localization */}
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                color="primary"
                loading={isCompanyLoading}
                disabled={
                  isSubmitting ||
                  isCompanyLoading ||
                  values.companyName.length === 0 ||
                  !!errors.companyName ||
                  !!errors.stateId ||
                  !!errors.postalCode ||
                  !!errors.countryId ||
                  !isValid
                }
              >
                {t('Button_CreateCompany')} {/* Localization */}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

const styles = {
  my2: {
    marginY: 2
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '30px',
    minWidth: '30px',
    height: '20px',
    paddingRight: '10px'
  }
};

export default AddCompanyDialog;
