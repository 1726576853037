import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/utils/StringUtils';
import { EnumList, fetchLocalEnumList } from 'shared/utils/EnumUtils';

const useBitrateValidationSchema = (fieldName: string): Yup.StringSchema => {
  const enumList: EnumList = fetchLocalEnumList();
  const { t } = useTranslation(); // Initialize the translation hook
  const fieldErrorRequired = t('Field_Error_Required');
  const fieldErrorInvalid = t('Field_Error_Invalid');
  const formattedFieldRequired: string = StringUtils.format(fieldErrorRequired, fieldName);
  const formattedFieldInvalid: string = StringUtils.format(fieldErrorInvalid, fieldName);

  return Yup.string()
    .required(formattedFieldRequired)
    .test('isValidBitrate', formattedFieldInvalid, (value: string) => {
      return Object.keys(enumList.videoBitrate).includes(value);
    });
};

export default useBitrateValidationSchema;
